// S : BTOCSITE-61307 [홈 메인 팝업 게시 요청] 피싱사이트 주의 안내
.phishing-popup {
    .pc-call {
        display:block;
    }
    .mo-call {
        display:none;
    }
}

.mdevice {
    .phishing-popup {
        .pc-call {
            display:none;
        }
        .mo-call {
            display:block;
        } 
    }
}
// E : BTOCSITE-61307 [홈 메인 팝업 게시 요청] 피싱사이트 주의 안내

// alert layer popup style
.lay-wrap {
    display:none;
    position:absolute;
    width:444px;
    padding-top:48px;
    background-color:#fff;
    box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.16);
    border-radius:8px;
    z-index:9000;
    overflow:hidden;
    outline:none;
    @include mobile {
        width:87.5%;
        padding-top:60px;
    }
    .lay-header {
        position:relative; 
        width:auto;
        text-align:center;
        margin:0 24px;
        box-shadow:none;
        @include mobile {
            padding:0;
        }
        h1 {
            @include font-large-1;
            text-align:center; 
            font-weight:700;
        }
        // [BTOCSITE-77476] [웹앱 접근성 개선활동] 전시_마이페이지(seo_ui h1태그 수정)
        h4 {
            @include font-large-1;
            text-align:center; 
            font-weight:700;   
        }
    }
    .lay-conts {
        padding:0 40px 40px;
        @include font-medium;
        text-align:center;
        &:first-child {
            margin-top:0;
        }
        @include mobile {
            padding:0 24px 50px;
        }
        p {
            margin-top:12px;
            .point {
                color: $color-primary;
            }
        }
        h6 {
            font-weight:500;
            @include font-medium;
            span {
                display: block;
                margin-top: 12px;
            }
            .point {
                color: $color-primary;
            }
        }
    }
    .laypop {
        &.btn-wrap {
            padding-bottom:48px;
            font-size:0;
            text-align:center;
            @include mobile {
                padding:0;
            }
            .btn {
                margin-left:8px;
                // border-radius:0;
                &:first-child {
                    margin-left:0;
                }
                @include mobile {
                    width:50%;
                    margin-left:0;
                    border-radius: 0;
                    &:only-child {
                        width:100%;
                    }
                }
            }
        }
    }
    &.type2 {
        padding-top:48px;
        @include mobile {
            padding-top:60px;
        }
        .lay-conts {
            padding:0 24px 32px;
            @include mobile {
                padding:0 24px 40px;
            }
        }
        .laypop {
            &.btn-wrap {
                padding-bottom:48px;
                @include mobile {
                    padding:0;
                }
            }
        }
    }
}


// modal layer popup style
.ui_modal_wrap {
    height:100%;
    margin:0 30px;
    @include mobile {
        margin:0;
    }
}
// BTOCSITE-372 마이페이지-참여이벤트 리스트 노출 오류 
/* BTOCSITE-5938-251 모바일 참여 이벤트 > 당첨자 발표 팝업 스크롤 동작 오류 */
#event-modal {
    max-width:912px;
    .win-popup-wrap {
        height:100%;
        .pop-header {
            position:absolute;
            padding-left:0;
            width:calc(100% - 80px);
            @include mobile{
                width:calc(100% - 32px);
            }
            &:after {
                display:none;
            }
        }
        @include mobile{
            .pop-conts{
                max-height:100%;
                height:100%;
            }
            .tit-area{
                margin:-40px -16px 32px;
                padding:48px 16px 30px;
                height:auto;
            }
        }
    }
}
/* //BTOCSITE-5938-251 모바일 참여 이벤트 > 당첨자 발표 팝업 스크롤 동작 오류 */
.popup-wrap {
    display:none;
    position:absolute;
    width:100%;
    max-width:678px;
    overflow:hidden;
    border-radius:8px;
    background:#fff;
    &.wide {
        max-width:1146px;
        @include mobile {
            max-width:100%;
        }
    }
    &.full {
        max-width:912px;
        @include mobile {
            max-width:100%;            
        }
    }
    &.full-sm {
        max-width:444px;
        @include mobile {
            max-width:100%;
        }
    }
    /* LGECOMVIO-143 마이페이지 / 마이베스트샵 / 상담예약조회: 상품정보 팝업 */
    &.mini {
        max-width:327px;
        @include mobile {
            max-width:100%;
        }
    }
    /* // LGECOMVIO-143 마이페이지 / 마이베스트샵 / 상담예약조회: 상품정보 팝업 */
    &.small {
        max-width:444px;
        .pop-conts {
            padding-top:48px;
        }
        .pop-footer.check-type {
            padding: 20px 40px 40px; 
        }

        /* 20210512 : 모바일앱 다운로드 팝업 */
        &.appMobile-pop { 
            max-width: 300px;
            background-color: rgba(255, 255, 255, 0);
            color: #fff;
            outline: 0;
            .pop-conts {
                padding: 20px 20px 0 20px;
                font-size: 36px;
                max-height: none;
                .appMobile-pop-content {
                    display: flex;
                    justify-content: space-between;
                    padding: 0 6px;
                    .appMobile-popImg {
                        box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.3);
                        border-radius: 20px;
                        .pop-img {
                            width: 100%;
                            max-width: 87px;
                            height: 87px;
                        }
                    }
                    .text-cont {
                        text-align: left;
                        font-size: 16px;
                        align-self: center;
                    }
                }
                .btn-wrap {
                    margin: 23px 0;
                    .btn { 
                        border: 0;
                        color: #fff;
                        background: linear-gradient(130deg, #fa263e, #fb5a72 80%);
                        height: 43px;
                        font-size: 13px;
                        width: 100%;
                        &:hover {
                            background-color: transparent;
                            border: 0;
                        }
                    }
                }
            }
            .ui_modal_close {
                text-align: center;
                font-size: 13px;
                width: 100%;
                text-decoration: underline;
                color: #fff;
            }
        }
        /* //20210512 : 모바일앱 다운로드 팝업 */
        @include mobile {
            height:auto;
            width:87.5%;
            left:50% !important;
            top:50%  !important;
            transform:translateX(-50%) translateY(-50%) !important;
            border-radius:8px;
            .pop-header {
                padding:24px 0 14px;
                .tit {
                    span {
                        font-size:18px;
                        line-height:26px;
                    }
                }
            }
            .pop-conts {
                padding:32px 16px 40px;
                &.no-footer {
                    max-height:calc(100vh - 200px); // BTOCSITE-5938-233 20211217 내용이 긴 경우 팝업 뷰포트 넘어감
                }

            }
            .pop-footer.check-type {
                padding: 10px 16px;
            }
            .btn-close {
                top:21px;
                &:focus-visible {
                    overflow: hidden;
                }
            }
        }
    }
    // BTOCSITE-75396 홈메인 팝업 구현 
    &.mini-sm {
        max-width: 316px;
    }
    // S : BTOCSITE-77522 [웹앱 접근성 개선활동] 주문/청약_마이페이지_쇼핑관리
    @media (min-width:768px) and (max-height:1000px) {
        &:has(.not-cancel-footer[style*="display: block"]) {
            .pop-conts {
                max-height: calc(100vh - 420px); //전체 높이에서 헤더+전체취소불가 안내 영역 높이(67+353)를 뺀 값
            }
        }
    }
    // E : BTOCSITE-77522 [웹앱 접근성 개선활동] 주문/청약_마이페이지_쇼핑관리
    @include mobile {
        left:0 !important;
        top:0 !important;
        width:100%;
        height:100%;
        max-width:none;
        border-radius:0;
        transform:none !important;
        &.tit-line3 {
            .pop-conts {
                max-height:calc(100% - 146px);
                height:calc(100% - 146px);
            }
        }
    }
    > .btn-close {
        position:absolute;
        top:28px;
        right:40px;
        width:24px;
        height:24px;
        @include mobile {
            top:24px;
            right:16px;
            width:24px;
            height:24px;
        }
        &::before {
            content:' ';
            position:absolute;
            top:0;
            left:0;
            width:24px;
            height:24px;
            background:url('/lg5-common/images/icons/btn-close-32.svg') no-repeat;
            background-size:24px;
            // @include mobile {
            //     width:24px;
            //     height:24px;
            //     background-size:24px;
            // }
        }
    }
    .pop-header {
        padding:28px 0 14px;
        margin:0 40px;
        border-bottom:2px solid #222;
        .tit span {
            display:block;
            height:34px;
            @include font-large-1;
            font-weight:700;
            overflow:hidden;
            text-overflow:ellipsis;
            word-break:keep-all;
            br {
                display: none;
            }
        }
        @include mobile {
            margin:0 16px;
            padding:24px 48px 12px 0;
            .tit span {
                height:auto;
                font-size:16px;
                line-height:24px;
                br {
                    display: none;
                }
            }
        }
    }
    .pop-guide {
        display:flex;
        justify-content:space-between;
        align-items:center;
        width:100%;
        padding:30px 40px;
        border-radius:10px;
        background:#f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
        .text {
            color:#000;
            font-size:16px;
            line-height:26px;
        }
        @include mobile{
            padding:24px 16px;
        }
    }
    .pop-conts {
        height:auto;
        // max-height:492px;
        max-height:580px;
        padding:48px 40px 0;
        overflow-y:auto;
        /* 20210601 구매혜택 팝업 conts수정 */
        &.pop4 {
            padding:48px 40px 40px;
        }
        /* //20210601 구매혜택 팝업 conts수정 */

        /* 20210607 스펙선택 추가 */ 
        &.sibling-pop {
            padding: 0;
            .process-info {
                .info-box {
                    padding: 0;
                    border-radius: 0;
                    background-color: #fff;
                    p {
                        @include font-medium;
                    }
                    .sibling-option {
                        margin-bottom: 0px;
                        > [class*="sibling-"] {
                            display: flex;
                            //justify-content: space-between;
                            width: 100%;
                            flex-wrap: wrap;
                            &[class*="cont"] {
                                position: relative;
                                padding: 24px 40px 30px;

                                &:after {
                                    content:"";
                                    display: block;
                                    position: absolute;
                                    bottom:0;
                                    left:40px;
                                    right:40px;
                                    height:1px;
                                    background: #ddd;
                                }

                                @include mobile {
                                    margin-bottom:30px;
                                    padding: 20px 15px 30px;

                                    &:after {
                                        left:15px;
                                        right:15px;
                                    }
                                }
                                .sibling-select,
                                .sibling-color {
                                    width: 100%;
                                    margin-top: 30px;
                                    .sibling-colorHead {
                                        display: flex;
                                        width: 100%;
                                        font-size: 16px;
                                        align-items: center;
                                        margin-bottom: 20px;
                                        &.disabled {
                                            .color-text {
                                                color:$color-gray-2
                                            }
                                        }
                                        @include mobile {
                                            margin-bottom: 10px;
                                        }
                                        .text {
                                            width: auto;
                                            margin-bottom: 0;
                                            margin-right: 35px;
                                            @include mobile {
                                                margin-right: 0px;
                                            }
                                        }
                                        .color-text {
                                            color:#da0f47;
                                            font-weight: 700;
                                            @include mobile {
                                                font-size: 14px;
                                                margin-left: 10px;
                                            }
                                        }
                                    }
                                    @include pc {
                                        .text {
                                            margin-top: 0px;
                                        }
                                    }
                                    @include mobile {
                                        text-align: left;
                                    }
                                }
                                .sibling-color {
                                    position: relative;
                                    
                                }
                                .sibling-select:first-of-type {
                                    margin-top: 0;
                                }
                                .sibling-text {
                                    margin-top: 24px;
                                    .text {
                                        padding-top: 0;
                                    }
                                }
                                .text {
                                    flex: none;
                                    display: block;
                                    width: 100%;
                                    font-size: 16px;
                                    line-height: 24px;
                                    font-weight: 700;
                                    margin-bottom: 8px;
                                }
                                &:first-child {
                                    margin-top: 0;
                                }
                            }
                            
                        }
                        
                        @include mobile {
                            padding: 0 0 0px;
                            border-bottom: 0;
                            > [class*="sibling-"] {
                                flex-direction: column;
                                justify-content: left;
                                align-content: flex-start;
                                .text {
                                    text-align: left;
                                    width: auto;
                                    margin-bottom: 8px;
                                    padding-right: 0;
                                    font-size: 14px;
                                }
                            }
                        }
                        .select-option {
                            .option-list {
                                margin-top:0;
                                margin-right: 15px;
                                &:last-of-type {
                                    margin-right: 0;
                                }
                                @include mobile {
                                    margin-top:0;
                                    margin-right: 10px;
                                }
                            }
                            //2021101 시블링 레이어 컬러칩 영역 깨짐 수정
                            &.color {
                                flex: auto;
                                display: flex;
                                .option-list {
                                    margin: -8px 0 0 0;
                                    font-size: 0;
                                }
                                /* BTOCSITE-10823 시블링 컬러칩 형태 변경 요청 */
                                .chk-wrap-colorchip {
                                    width: 40px;
                                    height: 40px;
                                    margin-top:8px;
                                    @include mobile {
                                        width: 38px;
                                        height: 38px;
                                    }
                                    &:not(:last-child) {
                                        margin-right: 16px;
                                        @include mobile{
                                            margin-right:15px;
                                        }
                                    }
                                    input {
                                        & + label {
                                            @include mobile {
                                                width: 37px;
                                                height: 37px;
                                            }
                                            &:before {
                                                top: 0;
                                                left: 0;
                                                width: 24px;
                                                height: 24px;
                                                border: 0;
                                                background-size: 144px auto;
                                                transform: translate(8px, 8px) scale(1.6665);

                                                //white, snow_white, white_white 재정의
                                                @at-root {
                                                    .popup-wrap .pop-conts.sibling-pop .process-info .info-box .sibling-option .select-option.color .chk-wrap-colorchip.white input + label:before,
                                                    .popup-wrap .pop-conts.sibling-pop .process-info .info-box .sibling-option .select-option.color .chk-wrap-colorchip.snow_white input + label:before,
                                                    .popup-wrap .pop-conts.sibling-pop .process-info .info-box .sibling-option .select-option.color .chk-wrap-colorchip.white_white input + label:before {
                                                        background-size: 100% 100%;
                                                    }
                                                }

                                                @include mobile {
                                                    transform: translate(7px, 7px) scale(1.5415);
                                                }
                                            }
                                            &:after {
                                                display: none;
                                            }
                                        }
                                        &:checked {
                                            & + label {
                                                &:after {
                                                    display: block;
                                                    @include calc(top,50%, -10px);
                                                    @include calc(left,50%, 5px);
                                                    width: 20px;
                                                    height: 13px;
                                                    border: 0;
                                                    border-bottom: 2px solid #fff;
                                                    border-left: 2px solid #fff;
                                                    border-radius: 0;
                                                    transform: rotate(-45deg);
                                                    @include mobile {
                                                        top: calc( 50% - 8px);
                                                        left: calc( 50% - 8px);
                                                        width: 17px;
                                                        height: 11px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    //_colorchip.scss 소스와 싱크 필요함
                                    &.av_pearl-white,
                                    &.av_silver-white,
                                    &.av_berry-blue,
                                    &.av_bubble-black,
                                    &.av_sour-lemon,
                                    &.snow_white,
                                    &.white,
                                    &.white_white,
                                    &.water-purifiers_solid_beige,
                                    &.water-purifiers_solid_white,
                                    &.objet_cream_white,
                                    &.objet_nature_cream_white,
                                    &.objet_fabric_beige {
                                        input:checked + label:after {
                                            border-bottom-color: #000;
                                            border-left-color: #000;
                                        }
                                    }
                                }
                                /* //BTOCSITE-10823 시블링 컬러칩 형태 변경 요청 */
                                .color-text {
                                    margin-top: 8px;
                                    color:#da0f47;
                                    @include font-x-small;
                                    font-weight:700;
                                }
                            }
                            &.select {
                                .option-list {
                                    margin-right: 0;
                                    margin-bottom: 0;
                                }
                                .rdo-wrap.btn-type2 {
                                    margin-right: 8px;
                                    margin-bottom: 8px;
                                    @include mobile {
                                        margin-right: 3px;
                                        margin-bottom: 3px;
                                    }
                                    &:last-of-type {
                                        margin-right: 0;
                                    }

                                    &.disabled {
                                        input {
                                            & + label {
                                                border: 1px solid;
                                                @include font($font-small,26px);
                                                color: #8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                            }
                                            &:checked {
                                                & + label {
                                                    color: #8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                                    border-color: $color-primary;
                                                }
                                            }
                                        }
                                    }
                                    input {
                                        & + label {
                                            width: auto;
                                            min-width: 60px;
                                            padding: 6px 14px;
                                            border-radius: 40px;
                                            border: 1px solid;
                                            @include font($font-small,26px);
                                            // color: $color-gray-1;
                                            @include mobile {
                                                min-width: 55px;
                                                font-size: 13px;
                                                padding: 3px 14px;
                                            }
                                        }
                                        &:checked {
                                            & + label {
                                                color: $color-primary;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .text-option {
                            @include font-small;
                        }
                    }
                    .sibling-group {
                        padding: 0 30px 40px 30px;
                        margin-top: 30px;
                        @include mobile {
                            padding-bottom: 0;
                            padding: 0;
                        }
                        .sibling-btn {
                            display: flex;
                            justify-content: center;
                            width: 100%;
                            @include mobile {
                                position: relative;
                            }
                            .btn {
                                width: 192px;
                                @include mobile {
                                    width: 100%;
                                    border-radius: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        /* //20210607 스펙선택 추가 */
        &.no-footer {
            max-height:576px + 130px;
            padding-bottom:0;
            text-align:left;
            @include mobile {
                height:calc(100% - 62px);
                max-height:calc(100% - 62px);
            }
        }
        &.no-footer:after {
            content:'';
            display:block;
            padding-bottom:48px;
        }


        &.support {
            background-color: #fff;
        }
        @include mobile {
            padding:32px 16px 60px;
            height:calc(100% - 122px);
            max-height:calc(100% - 122px);
        }
        /* BTOCSITE-1775 add */
        .not-cancel-footer {                        
            h2 {
                font-size: 24px;
                line-height: 34px;
                font-weight: 700;
            }
            p { 
                font-size:18px;
                font-weight:600;
                margin-top:24px;
            }

            .btn-group {
                margin-top:32px;
            }

            @include mobile {
                margin-top:24px;
                h2 {
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: 700;
                }
                p {
                    margin-top:12px;
                    font-size: 14px;
                    line-height: 22px;
                }

                .btn-group {
                    margin-top:24px;
                }
            }
        }
        /* //BTOCSITE-1775 add */

        .tabs-wrap {
            &:first-child {
                @include mobile {
                    margin:0 -16px;
                }
            }
        }
        .tabs-contents {
            padding-top:32px;
        }
    }
    .pop-footer {
        padding:32px 48px 48px;
        background:transparent;
        font-size:0;
        &.center {
            text-align:center;
        }
        &.no-padding {
            padding:0 0 40px;
        }
        &.check-type {
            text-align:left;
        }
        .btn-group {
            margin:0;
            padding:0;
        }
        .btn {
            margin-left:8px;
            border-radius:26px;
            vertical-align:top;
            &:first-child {
                margin-left:0;
            }
            @include mobile {
                padding:17px 0;
                font-size:17px;
                line-height:24px;
                &:first-child:nth-last-child(2),
                &:first-child:nth-last-child(2) ~ .btn {
                    width:50%;
                }
            }
        }
        @include pc {
            &.check-type {
                padding:20px 56px 40px;
                input {
                    + label {
                        padding-left:20px;
                        font-size:12px;
                        line-height:16px;
                        &:before {
                            width:16px;
                            height:16px;
                        }
                        &:after {
                            top:5px;
                            left:4px;
                            width:8px;
                            height:4px;
                        }
                    }
                }
            }
        }
        @include mobile {
            //margin-top:20px;
            padding:0;
            &.check-type {
                padding:12px 24px 24px;
            }
            .btn-group {
                .btn {
                    margin-left:0;
                    border-radius:0;
                }
            }
        }
    }
    &.video {
        overflow: visible;
        max-width: 1284px;
        border-radius: 0;
        @include mobile {
            background-color: #000;
            .video-asset {
                position: absolute;
                top: 50%;
                left: 16px;
                right: 16px;
                transform: translateY(-50%);
                overflow: hidden;
            }
        }
        .btn-close {
            top: -87px;
            right: 0;
            width:48px;
            height:48px;
            &:before {
                width:48px;
                height:48px;
                background-image: url('/lg5-common/images/icons/btn-close-48-white.svg');
                background-size: 48px;
            }
            @include mobile {
                top:24px;
                right:16px;
                width: 24px;
                height: 24px;
                &:before {
                    width: 24px;
                    height: 24px;
                    background-size: 24px 24px;
                }
            }
        }
        .pop-conts {
            padding: 0;
        }
        .video-box {
            overflow: hidden;
            width: 100%;
            height: calc((100vw - 15px) * (9/16));
            iframe {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border: 0;
            }
        }
    }

    &.animation {
        overflow: visible;
        max-width: 1380px;
        border-radius: 0;
        background-color: transparent;
        @include mobile {
            background-color: #000;
            .video-asset {
                position: absolute;
                top: 50%;
                left: 16px;
                right: 16px;
                transform: translateY(-50%);
                overflow: hidden;
            }
        }
        .btn-close {
            top: -48px;
            right: 0;
            width:48px;
            height:48px;
            &:before {
                width:48px;
                height:48px;
                background-image: url('/lg5-common/images/icons/btn-close-48-white.svg');
            }
            @include mobile {
                top:24px;
                right:16px;
                width: 24px;
                height: 24px;
                &:before {
                    width: 24px;
                    height: 24px;
                    background-size: 24px 24px;
                }
            }
        }
        .pop-conts {
            max-height: none;
            padding: 0;
        }
        .video-box {
            position: relative;
            overflow: hidden;
            width: 100%;
            video {
                width: 100%;
                height: 100%;
            }
            .video-controller {
                .btn-video {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    z-index: 2;
                    width: 80px;
                    height: 80px;
                    background: url('/lg5-common/images/icons/btn-play-80.svg') no-repeat 0 0;
                    background-size: 100% 100%;
                    @include mobile {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
        .video-info {
            padding-top: 32px;
            color: $color-white;
            text-align: center;
            .title {
                font-weight: 700;
                @include font-large-2;
            }
            .body-copy {
                margin-top: 16px;
                @include font-medium;
            }
        }
    }

    // S : BTOCSITE-46839 [주문] 추석 연휴 배송일정 알림 팝업 노출건
    &.popup-delivery {
        border-radius: 10px;
        &.small .pop-header,
        .pop-header {
            padding: 20px 24px 10px 0;
            border-bottom: 1px solid #000;
            .tit {
                span {
                    height: 26px;
                    line-height: 26px;
                    font-size: 18px;
                }
            }
        }
        &.small .pop-conts {
            padding-top: 20px;
        }
        .pop-conts {
            padding: 24px 40px 0;
            .delivery-txt {
                font-size: 14px;
                line-height: 1.43;
                word-break: keep-all;
            }
            .delivery-schedule {
                background: #f7f7f7; // BTOCSITE-77518 [웹앱 접근성 개선활동] 주문/청약_가전구독
                padding: 24px 20px;
                font-size: 16px;
                line-height: 1.44;
                margin-top: 24px;
                border-radius: 16px;
                .schedule-tit {
                    font-weight: 700;
                }
                .sub-tit {
                    font-weight: 500;
                    margin-top: 24px;
                }
                .delivery-desc {
                    color: #666;
                    word-break: keep-all;
                }
                .bullet-list {
                    margin-top: 8px;
                    font-size: 16px;
                    li {
                        display: flex;
                        .order-date {
                            flex-shrink: 0;
                            margin-right: 4px
                        }
                    }
                }
            }
            .add-desc {
                padding-top: 16px;
                margin-top: 24px;
                border-top: 1px solid #ddd;
                font-size: 14px;
                line-height: 1.43;
                .sub-desc {
                    color: #666;
                }
            }
            &.no-footer {
                &:after {
                    padding-bottom: 32px;
                }
            }
        }
        .btn-close {
            top: 21px;
        }
        @include mobile {
            &:not(.samll) {
                .pop-conts {
                    height: calc(100% - 139px);
                }
            }
            border-radius: 0;
            // .pop-header {
            //     padding: 30px 34px 12px 0;
            // }
            &.small {
                border-radius: 8px;
            }
            .pop-conts {
                padding: 20px 16px !important;
                .delivery-txt {
                    font-size: 13px;
                    line-height: 1.54;
                }
                .delivery-schedule {
                    font-size: 13px;
                    line-height: 1.54;
                    padding: 16px 15px;
                    border-radius: 8px;
                    .sub-tit {
                        margin-top: 16px;
                    }
                    .bullet-list {
                        margin-top: 4px;
                        font-size: 13px;
                    }
                }
                .add-desc {
                    font-size: 13px;
                    line-height: 1.54;
                }
                &.no-footer {
                    height: calc(100% - 57px);
                    max-height: calc(100% - 57px);
                }
            }
        }
        
        // S : BTOCSITE-44366 마이페이지 > (1)배송현황상세/(2)배송예정일 조회
        .delivery-tracking {
            padding: 0 40px;
            // S : BTOCSITE-64122 마이페이지> 주문내역내 배송주문번호 추가
            .sects {
                margin: 0;
                @include mobile {
                    margin: 0 -16px;
                    border-top: 7px solid #F3F3F3;
                    &:first-child {
                        border-top: none;
                    }
                }
                .tit {
                    &-wrap {
                        padding-bottom: 16px;
                        border-bottom: 1px solid #666;
                        @include mobile {
                            padding-bottom: 10px;
                            border-bottom: 2px solid #0f0f0f;
                        }
                    }
                    text-align: left;
                    padding: 0;
                    font-size: 18px;
                    line-height: 26px;
                    font-weight: 500;
                    color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    @include mobile {
                        float: left;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                    }
                }
            }
            .delivery-info {
                display: flex;
                flex-flow: column wrap;
                align-items: center;
                padding: 32px 0 48px;
                @include mobile {
                    padding: 0 0 32px;
                }
                p {
                    font-size: 28px;
                    line-height: 40px;
                    text-align: center;
                    @include mobile {
                        font-size: 24px;
                        line-height: 30px;
                    }
                    &.text {
                        font-weight: 700;
                        span {
                            font-weight: 400;
                        }
                    }
                    &.date {
                        margin-top: 6px;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 26px;
                        @include mobile {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
                .progress {
                    display: flex;
                    justify-content: space-between;
                    width: 377px;
                    // margin-top: 12px;
                    @include mobile {
                        width: 301px;
                    }
                    li {
                        position: relative;
                        min-width: 64px;
                        padding-top: 72px;
                        text-align: center;
                        @include mobile {
                            padding-top: 54px;
                        }
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 64px;
                            height: 64px;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            @include mobile {
                                width: 48px;
                                height: 48px;
                            }
                        }
                        &.payment {
                            &:before {
                                background-image: url(/lg5-common/images/icons/img_payment_64_gray.svg);
                            }
                            &.on:before {
                                background-image: url(/lg5-common/images/icons/img_payment_64_white.svg);
                            }
                            &.current:before {
                                background-image: url(/lg5-common/images/icons/img_payment_64_red.svg);
                            }
                        }
                        &.ready {
                            &:before {
                                background-image: url(/lg5-common/images/icons/img_deli_ready_64_gray.svg);
                            }
                            &.on:before {
                                background-image: url(/lg5-common/images/icons/img_deli_ready_64_white.svg);
                            }
                            &.current:before {
                                background-image: url(/lg5-common/images/icons/img_deli_ready_64_red.svg);
                            }
                        }
                        &.doing {
                            &:before {
                                background-image: url(/lg5-common/images/icons/img_deli_64_gray.svg);
                            }
                            &.on:before {
                                background-image: url(/lg5-common/images/icons/img_deli_64_white.svg);
                            }
                            &.current:before {
                                background-image: url(/lg5-common/images/icons/img_deli_64_red.svg);
                            }
                        }
                        &.done {
                            &:before {
                                background-image: url(/lg5-common/images/icons/img_deli_complete_64_gray.svg);
                            }
                            &.on:before {
                                background-image: url(/lg5-common/images/icons/img_deli_complete_64_white.svg);
                            }
                            &.current:before {
                                background-image: url(/lg5-common/images/icons/img_deli_complete_64_red.svg);
                            }
                        }
                        &:not(:last-child):after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            right: -27px;
                            width: 15px;
                            height: 64px;
                            background: url(/lg5-common/images/icons/img_3dots_16.png) no-repeat center / contain;
                            @include mobile {
                                right: -15px;
                                height: 48px;
                            }
                        }
                        span {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 20px;
                            text-align: center;
                            color: #666;
                            @include mobile {
                                
                            }
                        }
                        &.current {
                            span {
                                font-weight: 700;
                                color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                            }
                        }
                    }
                    &-box {
                        margin-top: 32px;
                        @include mobile {
                            margin-top: 24px;
                        }
                    }
                }
            }
            .info-tbl-wrap {
                padding-bottom: 48px;
                @include mobile {
                    padding: 40px 16px;
                }
                .box {
                    position: relative;
                    margin: 32px 0;
                    @include mobile {
                        overflow: visible;
                        margin: 24px 0;
                        box-shadow: none;
                        .product-info .infos .name a .main-title {
                            font-size: 16px;
                        }
                    }
                }
                //BTOCSITE-64122 마이페이지> 주문내역내 배송주문번호 추가
                .row {
                    display: flex;
                    align-items: center;
                    position: relative;
                    width: 100%;
                    min-height: 178px;  //BTOCSITE-64122 마이페이지> 주문내역내 배송주문번호 추가
                    padding: 24px 40px 24px 190px;
                    border-radius: 8px;
                    background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    @include mobile {
                        display: block;
                        padding: 24px 16px 24px 111px;
                    }
                    //BTOCSITE-64122 마이페이지> 주문내역내 배송주문번호 추가
                    .col-table {
                        width: 100%;
                    }
                    .product-info {
                        @include mobile {
                            position: static;
                        }
                        .thumb {
                            position: absolute;
                            left: 40px;
                            top: 50%;
                            transform: translateY(-50%);  //BTOCSITE-64122 마이페이지> 주문내역내 배송주문번호 추가
                            width: auto;
                            padding: 0;
                            @include mobile {
                                top: 24px;
                                left: 28px;
                                transform: none;  //BTOCSITE-64122 마이페이지> 주문내역내 배송주문번호 추가
                            }
                            //BTOCSITE-75152 배송조회 레이어팝업내 제품명 하이퍼링크 확인 및 수정 요청
                            a,
                            div {
                                position: relative;
                                width: 130px;
                                height: 130px;
                                @include mobile {
                                    width: 90px;
                                    height: 90px;
                                }
                                &:before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    background-color: rgba(0,0,0,.03);
                                    z-index: 1;
                                }
                            }
                        }
                        .name {
                            @include mobile {
                                font-size: 16px;
                            }
                        }
                        .e-name {
                            font-size: 12px;
                            line-height: 15px;
                            margin-top: 2px; 
                            @include mobile {
                                font-size: 11px;
                            }
                        }
                        .more {
                            margin-top: 6px;
                            ul li {
                                @include mobile {
                                    font-size: 13px;
                                }
                            }
                        }
                        .count {
                            margin-top: 6px;
                            font-size: 14px;
                            color: #666;
                            @include mobile {
                                font-size: 13px;
                            }
                        }
                    }
                    .state-box {
                        text-align: left;
                        .desc {
                            @include mobile {
                                font-size: 15px;
                                line-height: 22px;
                                margin-top: 8px;
                            }
                        }
                    }
                }
                .dl-infolist-wrap {
                    padding-top: 20px;
                    border-top: 1px solid #ddd;
                    @include mobile {
                        padding-top: 12px;
                    }
                    .lists {
                        li {
                            & + li {
                                margin-top: 12px;
                                @include mobile {
                                    margin-top: 16px;
                                }
                            }
                            dl {
                                dt {
                                    width: 192px;
                                    font-size: 14px;
                                    line-height: 24px;
                                    color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                    @include mobile {
                                        width: 34%;
                                    }
                                }
                                dd {
                                    max-width: calc(100% - 192px);
                                    font-size: 14px;
                                    line-height: 24px;
                                    color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                    @include mobile {
                                        max-width: initial;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .delivery-detail {
                @include mobile {
                    padding: 40px 16px 0;
                }
                .tit-wrap {
                    border-bottom: 2px solid #0f0f0f;
                    @include mobile {
                        padding-bottom: 14px;
                    }
                    .call-btn {
                        position: relative;
                        float: right;
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 9px;
                            left: 12px;
                            width: 16px;
                            height: 16px;
                            background: url(/lg5-common/images/icons/img_phone_24.svg) no-repeat;
                            z-index: 1;
                        }
                        .btn {
                            padding: 6px 12px 6px 28px;
                            border-radius: 6px;
                            line-height: 20px;
                        }
                    }
                }
                .tracking {
                    &-box {
                        position: relative;
                        padding: 20px 0 22px 36px;
                        @include mobile {
                            padding: 20px 0 40px 44px;
                        }
                        // S : BTOCSITE-64608 마이페이지>주문내역내 배송현황 조회 시점 변경
                        &.htls-unable {
                            text-align: center;
                            padding: 40px 0;
                            .unable-tit {
                                display: block;
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 20px;
                            }
                            .unable-txt {
                                font-size: 14px;
                                line-height: 20px;
                                color: #666;
                                margin-top: 4px;
                            }
                            @include mobile {
                                .unable-tit {
                                    font-size: 15px;
                                    line-height: 22px;
                                }
                            }
                        }
                        // E : BTOCSITE-64608 마이페이지>주문내역내 배송현황 조회 시점 변경
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        top: 27px;
                        left: 9.5px;
                        width: 1px;
                        height: calc(100% - 56px);
                        background-color: #d9d9d9;
                        @include mobile {
                            top: 30px;
                            height: calc(100% - 102px);
                        }
                    }
                    li {
                        position: relative;
                        display: flex;
                        justify-content: space-between;
                        // padding-right: 16px;
                        @include mobile {
                            flex-flow: column;
                            padding-right: 0;
                        }
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            left: -36px;
                            width: 20px;
                            height: 20px;
                            background: url(/lg5-common/images/icons/img_circle_20_gray.svg) no-repeat;
                            @include mobile {
                                top: 2px;
                                left: -42px;
                                width: 16px;
                                height: 16px;
                                background-size: 100%;
                            }
                        }
                        &+ li {
                            margin-top: 24px;
                        }
                        span {
                            font-weight: 400;
                            line-height: 20px;
                            &.place {
                                font-size: 16px;
                                color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                @include mobile {
                                    font-size: 14px;
                                }
                                .status {
                                    display: inline-block;
                                    position: relative;
                                    padding-left: 21px;
                                    &:before {
                                        content: '';
                                        position: absolute;
                                        top: 5px;
                                        left: 10px;
                                        // transform: translateY(-50%);
                                        width: 1px;
                                        height: 12px;
                                        background-color: #ddd;
                                    }
                                }
                            }
                            &.date {
                                font-size: 14px;
                                color: #666;
                                @include mobile {
                                    margin-top: 2px;
                                    font-size: 13px;
                                }
                            }
                        }
                        &.current {
                            &:before {
                                background-image: url(/lg5-common/images/icons/img_check_20_red.svg);
                            }
                            .place {
                                font-weight: 500;
                                .status {
                                    font-weight: 500;
                                    color: #ea1917;
                                }
                            }
                        }
                    }
                }
            }
            .bullet-list {
                margin: 0;
                padding-top: 16px;
                color: #666;
                line-height: 20px;
                @include mobile {
                    padding: 24px 0 32px;
                }
                .b-txt + .b-txt {
                    margin-top: 4px;
                    @include mobile {
                        margin-top: 4px;
                    }
                }
            }
            // E : BTOCSITE-64122 마이페이지> 주문내역내 배송주문번호 추가
        }
        // E : BTOCSITE-44366 마이페이지 > (1)배송현황상세/(2)배송예정일 조회
        // S : BTOCSITE-83579 (소모품) 동일모델 내에서 분할 배송 송장 표기
        &.small {
            .delivery-num-box {
                display: flex;
                justify-content: space-between;
                padding: 16px 20px;
                background: #f7f7f7;
                height: 120px;
                border-radius: 8px;
                font-size: 16px;
                line-height: 23px;
                .num-tit {
                    em {
                        font-weight: 700;
                    }
                }
                .num-cont {
                    overflow-y: auto;
                    padding-right: 16px;
                    margin-right: -16px;
                    .num-list {
                        > li {
                            + li {
                                margin-top: 6px;
                            }
                            a {
                                text-decoration: underline;
                            }
                        }
                    }
                }
                @include mobile {
                    padding: 16px;
                    font-size: 14px;
                    line-height: 20px;
                    .num-cont {
                        padding-right: 10px;
                        margin-right: -10px;
                        .num-list {
                            > li {
                                + li {
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }
            }
            .pop-conts.no-footer:after {
                padding-bottom: 40px;
                @include mobile {
                    padding-bottom: 20px;
                }
            }
        }
        // E : BTOCSITE-83579 (소모품) 동일모델 내에서 분할 배송 송장 표기
    }
    // E: BTOCSITE-46839 [주문] 추석 연휴 배송일정 알림 팝업 노출건

    .text-area {
        padding: 46px 8px 0;
        .tit {
            margin-bottom: 12px;
            font-weight: 700;
            @include font-large-2;
            text-align: center;
        }
        .text {
            @include font-medium;
            text-align: center;
            &.tit {
                margin-top: 20px;
                font-weight: 700;
                @include font-large-2;
            }
            .em {
                font-weight: 700;
                color: $color-primary;
            }
        }

    }

    .dl-txt-cont {
        & + .bullet-list {
            margin-top: 32px;
        }
        dd{
            &.bold{ 
                
                font-size: 20px;
                @include mobile{
                    font-size: 18px;
                }
                font-weight: bold;
             }
           
        }
    }
    .btn-wrap {
        &.top {
            margin-bottom: 48px;
        }
        @include mobile {
            &.top {
                margin-bottom: 40px;
            }
        }
    }
    .btn {
        &.icon-msg {
            &:before {
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-right: 4px;
                background: url('/lg5-common/images/icons/icon-sms-16.svg') no-repeat 0 0;
                vertical-align: -2px;
                content: '';
            }
        }
    }
}

.video-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba(#000,.7);
    .close-video {
        overflow: hidden;
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1;
        width:48px;
        height:48px;
        font-size: 0;
        &:before {
            display: block;
            width:48px;
            height:48px;
            background-image: url('/lg5-common/images/icons/btn-close-48-white.svg');
            content: '';
        }
        @include mobile {
            top: 24px;
            right: 16px;
        }
    }
    .modal-video-asset {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        max-width: 1380px + 80px;
        padding: 0 40px;
        border-radius: 0;
        background-color: transparent;
        @include mobile {
            padding: 0 16px;
        }
    }
    &.youtube {
        .video-asset {
            overflow: hidden;
            width: 100%;
            height: calc((100vw - 15px) * (9/16));
            max-height: 706px;
            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }
    &.animation {
        .video-box {
            position: relative;
            overflow: hidden;
            width: 100%;
            video {
                width: 100%;
                height: 100%;
            }
            .video-controller {
                .btn-video {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    z-index: 2;
                    width: 80px;
                    height: 80px;
                    background: url('/lg5-common/images/icons/btn-play-80.svg') no-repeat 0 0;
                    background-size: 100% 100%;
                    @include mobile {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
        .video-info {
            padding-top: 32px;
            color: $color-white;
            text-align: center;
            .title {
                font-weight: 700;
                @include font-large-2;
            }
            .body-copy {
                margin-top: 16px;
                @include font-medium;
            }
        }
    }
}


// 공통 팝업
.popup-wrap {
    .pc-only {
        @include mobile {
            display: none;
        }
    }
    .mo-only {
        @include pc {
            display: none;
        }
    }
    .common-pop {
        // 팝업 기본
        .info-req {
            margin:-40px 0 48px;
            font-size:14px;
            line-height:24px;
            @include mobile {
                margin:-24px 0 32px;
                font-size:13px;
                line-height:21px;
            }
            &:before {
                content:'*';
                display:inline-block;
                margin-right:2px;
                color:#da0f47;
            }
            &.type2 {
                margin-bottom:-32px;
            }
        }
        .form-wrap {
            max-width:630px;
            .forms {
                .tit {
                    width:29.2%;
                    .app-exec {
                        display: none;
                    }
                    @include mobile {
                        width:100%;
                        .app-exec {
                            display: none;
                        }
                        &.app-type {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .app-exec {
                                display: block;
                                min-width: 114px;
                                height: 34px;
                                border-radius: 40px;
                                padding: 0 11px;
                            }
                        }
                    }
                }
                .conts {
                    width:70.8%;
                    @include mobile {
                        width:100%;
                    }
                    .date-select-box.col-2 {
                        padding-right:0;
                        .cell:first-child {
                            width:60%;
                        }
                        .cell:last-child {
                            width:40%;
                        }
                    }
                }
            }
            // 1149 팝업 내부 인풋일 때 half + full사이즈 혼합
            &.mix-type {
                max-width:100%;
                .forms {
                    .tit {
                        width:17.52%;
                        @include mobile {
                            width:100%;
                        }
                    }
                    .conts {
                        width:82.48%;
                        @include mobile {
                            width:100%;
                        }
                    }
                }
                .select-wrap,
                .input-wrap {
                    &.half {
                        width:calc((100% - 8px) / 2);
                        @include mobile {
                            width:100%;
                        }
                    }
                }

                .gray-box {
                    .form-wrap {
                        width: 100%;
                        max-width: 100%;
                        .forms {
                            .conts {
                                width: 100%;
                            }
                        }
                        &.col-2 {
                            display: flex;
                            justify-content: space-between;
                            .forms {
                                width:calc((100% - 8px) / 2);
                                &.inner-type {
                                    margin-top: 0;
                                }
                            }
                        }
                        &:not(:first-child) {
                            margin-top: 16px;
                        }
                        .bank-input-box {
                            .select-wrap {
                                width: 39.2%;
                                @include mobile {
                                    width:100%;
                                }
                            }
                            .box {
                                width: calc(100% - (39.2% + 8px));
                                @include mobile {
                                    width:100%;
                                }
                            }
                        }
                    }
                }
                .input-wrap.count {
                    position: relative;
                    textarea {
                        // padding-right: 100px;
                        padding-bottom: 36px; // BTOCSITE-77522 [웹앱 접근성 개선활동] 주문/청약_마이페이지_쇼핑관리
                    }
                    .total {
                        position: absolute;
                        bottom: 16px;
                        right: 24px;
                        margin-top: 0;
                    }
                }
                .cancel-agree-box {
                    margin-top: 16px;
                    padding-left: 17.52%;
                    @include mobile {
                        padding-left: 0;
                    }
                }
            }
            // 팝업 내 forms dt 영역이 넓은 케이스
            &.pop-wide {
                max-width:100%;
                .forms {
                    .tit {
                        width:263px;
                    }
                    .conts {
                        width:calc(100% - 263px);
                    }
                    @include mobile {
                        .tit, 
                        .conts {
                            width:100%;
                        }
                    }
                }
            }

            &:first-child {
                .form-infos:first-child {
                    margin-top:-36px;
                    @include mobile {
                        margin-top:-20px;
                    }
                }
            }
        }
        .sect-wrap {
            margin-top:48px;
            &.cnt01 {
                margin-top:80px;
                @include mobile {
                    margin-top:60px;
                }
            }
            @include mobile {
                margin-top:40px;
            }
            &:first-child {
                margin-top:0;
            }
            .tit-wrap {
                margin-bottom:16px;
                .h2-tit {
                    color:#000;
                    //@include font-medium;
                    font-weight:700;
                    font-size: $font-large-2;
                    line-height: 34px;
                    @include mobile {
                        font-size: $font-large-1;
                        line-height: 28px;
                    }
                }
                &.type2 {
                    margin-bottom:24px;
                }
            }
        }

        // S : BTOCSITE-27368 배송지 주소 관리 임원체험 피드백
        // 배송지 관리
        .my-address-wrap {
            .top {
                margin-bottom:24px;
                text-align:right;
                .btn-addr {
                    padding: 6px 12px;
                    line-height: 20px;
                    color: $color-primary;
                    border-color: $color-primary;
                    [class^=icon-].icon-plus {
                        padding-left: 20px;
                        background-size: 16px;
                        background-position: 0 50%;
                        font-size: 14px;
                        line-height: 20px;
                        @include mobile {
                            padding-left: 24px;
                            background-size: 20px;
                            font-size: 16px;
                        }
                    }
                    @include mobile {
                        margin-top:24px;
                        padding: 14px 0;
                    }
                }
            }
            .address-lists {
                margin-top:27px;
                @include mobile {
                    margin-top:24px;
                }
                .lists {
                    margin-top:16px;
                    padding:32px;
                    border-radius:8px;
                    background:#fff;
                    border:1px solid #ddd;
                    // box-shadow:0 4px 12px 0 rgba(0, 0, 0, .26);
                    &:first-child {
                        margin-top:0;
                    }
                    @include mobile {
                        padding:20px;
                    }
                    .inner {
                        position:relative;
                        min-height:80px;
                        padding:0 140px 0 28px;
                        @include mobile {
                            min-height:100%;
                            padding:0 0 0 30px;
                        }
                        .addr {
                            dt {
                                margin-bottom:6px;
                                font-size:0;
                                @include mobile {
                                    margin-bottom:12px;
                                }
                                p {
                                    display:inline-block;
                                    @include font-medium;
                                    font-weight:700;
                                    vertical-align:middle;
                                }
                                .flag-wrap {
                                    display:inline-block;
                                    margin-left:8px;
                                    vertical-align:middle;
                                }
                            }
                            dd {
                                font-size:0;
                                &:last-child {
                                    margin-top:6px;
                                    @include mobile {
                                        margin-top: 9px;
                                        p {
                                            font-size: 13px;
                                        }
                                    }
                                }
                                p {
                                    display:inline-block;
                                    position:relative;
                                    margin-right:6px;
                                    padding-right:7px;
                                    font-size:16px;
                                    line-height:24px;
                                    vertical-align:top;
                                    @include mobile {
                                        font-size:14px;
                                        line-height:18px;
                                    }
                                    &::after {
                                        content:' ';
                                        position:absolute;
                                        top:50%;
                                        right:0;
                                        width:1px;
                                        height:10px;
                                        background:#ddd;
                                        transform: translateY(-50%);
                                    }
                                    &:last-child {
                                        margin-right:0;
                                        padding-right:0;
                                        &::after {
                                            display:none;
                                        }
                                    }
                                }
                            }
                        }
                        .btns {
                            position:absolute;
                            bottom:0;
                            right:0;
                            font-size:0;
                            @include mobile {
                                position:relative;
                                bottom:auto;
                                right:auto;
                                margin-top:10px;
                                font-size:0;
                                text-align:left;
                            }
                            .btn {
                                margin-left:8px;
                                @include mobile {
                                    margin-left:6px;
                                    min-width:56px;
                                    border-radius:35px;
                                }
                                &:first-child {
                                    margin-left:0;
                                }
                            }
                        }
                        .rdo-wrap {
                            position:absolute;
                            top:0;
                            left:0;
                            input + label {
                                font-size:0;
                            }
                        }
                    }
                }
            }
            .no-data {
                padding:88px 0 124px;
            }
            .pagination {
                margin: 28px 0;
                @include mobile {
                    margin: 20px 0 0;
                }
                // .prev,
                // .next,
                // .first,
                // .last {
                //     width: 32px;
                //     height: 32px;
                //     border:0;
                // }
                // .prev,
                // .next {
                //     margin: 0 4px;
                //     @include mobile {
                //         margin: 0 2px;
                //     }
                // }
            }
            .page_num {
                // a,
                // button,
                // strong,
                // span {
                //     padding: 4px 8px;
                //     font-weight: 500;
                //     color: #666;
                //     @include mobile {
                //         padding: 6px 10px;
                //     }
                // }
                .on {
                    width: auto;
                    height: auto;
                    font-weight: 700;
                    color: #000;
                    line-height: inherit;
                    background: none;
                }
            }
        }        
        &.delivery-address {
            padding-top: 16px;
            @include mobile {
                padding-top: 8px;
            }
            .pop-sub-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 0;
                margin-bottom:30px;
                border-bottom: 0;
                @include mobile {
                    margin-bottom: 20px;
                }
                h2, h5 {
                    font-size: 18px;
                    @include mobile {
                        font-size: 15px;
                    }
                }
                .form-infos {
                    margin-bottom: 0;
                }
            }
            & + .pop-footer {
                .btn {
                    background: $color-primary;
                }
            }
        }
        // E : BTOCSITE-27368 배송지 주소 관리 임원체험 피드백

        // 매월 납부금 할인내역
        .fee-info-wrap {
            margin-bottom: 80px;
            padding:24px 40px 32px;
            border-radius:8px;
            background:#f3f3f3; // [BTOCSITE-81509] [웹앱 접근성 개선활동] PDP - 일반, 케어용품/소모품, 가전구독, UP가전2.0
            @include mobile {
                margin-bottom: 60px;
                padding:24px 20px;
            }
            dl {
                display:table;
                width:100%;
                margin-top:8px;
                @include mobile {
                    margin-top:8px;
                }
                &:first-child {
                    margin-top:0;
                }
                dt, dd {
                    display:table-cell;
                    @include font-small;
                    vertical-align:middle;
                }
                dt {
                    padding-right:24px;
                    color:#000;
                    text-align:left;
                    word-break:keep-all;
                }
                dd {
                    min-width:97px;
                    text-align:right;
                    font-weight:700;
                    &.minus {
                        color:#da0f47;
                    }
                }
                &.total {
                    margin-top:16px;
                    padding-top:16px;
                    border-top:1px solid #ddd;
                    dt, dd {
                        vertical-align:bottom;
                    }
                    // @include mobile {
                    //     padding-top:16px;
                    // }
                    dt {
                        color:#000;
                        font-weight:500;
                    }
                    dd {
                        @include font-large-2;
                        font-weight:700;
                        @include mobile {
                            font-size:24px;
                            line-height:32px;
                        }
                    }
                }
            }
            &.type2 {
                padding: 24px 40px;
                dl.total {
                    margin-top: 0;
                    padding-top: 0;
                    border-top: 0;
                    dt {
                        @include font-small;
                        vertical-align: middle;
                    }
                    dd {
                        @include font-large-1;
                    }
                }
            }
        }
        .fee-txt {
            dt {
                color:#000;
                @include font-medium;
                font-weight:700;
            }
            dd {
                margin-top:8px;
                color:#000;
                @include font-medium;
                em {
                    color:$color-primary;
                }
                small {
                    display:block;
                    margin-top:4px;
                    color:#767676;
                    @include font-small;
                    font-weight:500;
                }
                .btn {
                    margin-top:24px;
                }
                .bullet-list {
                    margin-top:12px;
                    & + .btn-link {
                        margin-top:24px;
                    }
                }
            }
        }
        .form-wrap.mt-type {
            margin-top:40px;
            @include mobile {
                margin-top:20px;
            }
        }
        .bk-infos {
            dt {
                @include font-medium;
                strong {
                    color:#EC455A;
                    font-weight:normal;
                }
            }
            dd {
                position: relative;
                margin-top:12px;
                padding-left: 11px;
                @include font-small;
                &:before {
                    position: absolute;
                    top: 10px;
                    left: 0;
                    width: 3px;
                    height: 3px;
                    border-radius: 100px;
                    background-color: $color-default;
                    content: '';
                }
                @include mobile {
                    margin-top:12px;
                }
                .btn {
                    margin-top:16px;
                    @include mobile {
                        margin-top:8px;
                    }
                }
            }
        }
        .input-info:not(.type2) {
            .bk-infos {
                dd:last-child {
                    &:before {
                        display: none;
                    }
                }
            }
        }
        .btn-link {
            margin-top: 24px;
        }
        .btn-link-area,
        .link {
            .btn-link {
                margin-top:0;
            }
        }

        // 케어솔루션 구매 안내
        .top-txt {
            margin-bottom: 12px;
            @include font-medium;
        }
        .gray-txt-box {
            padding:20px 24px;
            border-radius:8px;
            background:#f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
            @include mobile {
                padding:16px 20px;
            }
            p {
                @include font-medium;
                em {
                    display:block;
                    margin-top:4px;
                    font-weight:700;
                }
                @include mobile {
                }
            }
            &.align-center {
                text-align: center;
            }
            .txt-list {
                font-weight: 700;
                @include font-medium;
                li:not(:first-child) {
                    margin-top: 2px;
                }
            }
            .desc {
                margin-top: 8px;
                font-weight: 500;
                @include font-small;
                color: $color-gray-1;
            }
        }

        //케어십안내
        .careship-info {
            margin-top:16px;
            @include clearfix;
            li {
                float:left;
                position:relative;
                padding-left:48px;
                @include font-medium;
                font-weight:700;
                @include mobile {
                    padding-left:10.6707vw;
                }
                &:before {
                    content:'';
                    display:block;
                    position:absolute;
                    top:50%;
                    left:12px;
                    width:24px;
                    height:24px;
                    background:url('/lg5-common/images/icons/btn-arrow_right_24.svg') no-repeat;
                    background-size:24px 24px;
                    transform:translateY(-50%);
                    @include mobile {
                        left:12px;
                    }
                }
                &:first-child {
                    padding-left:0;
                    &:before {
                        display:none;
                    }
                }
            }
        }

        // 모델명 확인 방법
        &.academy-pop {
            .noti-txt {
                font-weight:700;
                @include font($font-large-2,34px);
                em {
                    color: $color-primary;
                }
            }
            
            .example-list {
                padding-top: 24px;
                @include mobile {
                    padding-top: 32px;
                }
                dl {
                    padding-top:4px;
                    font-size: 0;
                    @include mobile {
                        padding-top: 18px;
                    }
                    &:first-child {
                        padding-top:0;
                    }
                    dt,dd {
                        display: inline-block;
                        @include font-medium;
                        vertical-align:top;
                    }
                    dt {
                        width: 83px;
                        font-weight: 700;
                        @include mobile {
                            width: 108px;
                        }
                    }
                    dd {
                        width:calc(100% - 83px);
                        @include mobile {
                            width:calc(100% - 108px);
                        }
                    }
                    &.sel-category {
                        margin-top: 48px;
                        padding-right: 77px;
                        dt {
                            display: block;
                            width: auto;
                            margin-bottom: 16px;
                        }
                        dd {
                            display: block;
                            margin-top: 8px;
                        }
                        @include mobile {
                            margin-top: 40px;
                            padding-right: 0;
                            dt {
                                margin-bottom: 8px;
                            }
                            dd {
                                margin-top: 6px;
                            }
                        }
                    }
                }
                .example-img {
                    margin-top: 80px;
                    .desc {
                        margin-bottom: 12px;
                    }
                    @include mobile {
                        margin-top: 60px;
                        .desc {
                            margin-bottom: 24px;
                        }
                    }
                    img {
                        width:100%;
                    }
                    &.no-img {
                        &:after {
                            display: block;
                            min-height: 140px;
                            background: $color-bg-wt url('/lg5-common/images/icons/noimage-79.png') no-repeat center center;
                            content: '';
                        }
                    }
                }
                // ul {
                //     @include clearfix;
                //     margin-top:48px;
                //     li {
                //         float:left;
                //         position:relative;
                //         width: 240px;
                //         //padding:0 0 0 56px;
                //         img {
                //             max-width: 100%;
                //             vertical-align: top;
                //         }
                //         @include mobile {
                //             float:inherit;
                //             width: auto;
                //             padding:48px 0 0 0;
                //         }
                //         &:first-child {
                //             margin-right:56px;
                //             @include mobile {
                //                 margin-right:0;
                //                 padding:0;
                //             }
                //             &:after {
                //                 content:'';
                //                 display:block;
                //                 position:absolute;
                //                 top:50%;
                //                 right:-40px;
                //                 width: 24px;
                //                 height: 24px;
                //                 margin-top: -12px;
                //                 background: url('/lg5-common/images/icons/btn-arr-24x24-black.svg') no-repeat 50% 50%;
                //                 background-size: 24px 24px;
                //                 @include mobile {
                //                     top:auto;
                //                     bottom:-36px;
                //                     right:50%;
                //                     margin-top: 0;
                //                     background: url('/lg5-common/images/icons/btn-arrow-down.svg') no-repeat 50% 50%;
                //                     background-size: 24px 24px;
                //                 }
                //             }
                //         }
                //         img {
                //             @include mobile {
                //                 display:block;
                //                 width:100%;
                //             }
                //         }
                //     }
                // }
            }

            .example-result {
                padding-top:80px;
                @include mobile {
                    padding-top:60px;
                }
                .txt {
                    @include font-medium;
                }
                .img {
                    margin-top:12px;
                    img {
                        width:100%;
                        height:auto;
                        vertical-align:top;
                    }
                    .btn-img-view {
                        display:block;
                        position:relative;
                        &:after {
                            content:'';
                            position:absolute;
                            top:12px;
                            right:12px;
                            width:32px;
                            height:32px;
                            background:center / 24px rgba(0, 0, 0, 0.5)  url(/kr/support/images/icon/icon_viewer.svg) no-repeat;
                        }
                    }
                }
            }
            
            .category-select {
                margin-top: 48px;
                @include mobile {
                    margin-top: 40px;
                }
            }
        }
        //케어십 절차 안내
        .process-list {
            display: flex;
            li {
                font-weight: 700;
                @include font-medium;
                vertical-align: middle;
                &:after {
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    margin: 0 12px;
                    background: url('/lg5-common/images/icons/btn-arrow_right_24.svg') no-repeat 0 center;
                    vertical-align: bottom;
                    content: '';
                }
                &:last-child {
                    padding-right: 0;
                    &:after {
                        display: none;
                    }
                }
                .num {
                    display: block;
                    font-weight: 500;
                    @include font-x-small;
                    color: $color-gray-1;
                }
            }
            @include mobile {
                li {
                    &:after {
                        width: 16px;
                        height: 16px;
                        margin: 0 8px;
                        background-size: 16px 16px;
                        vertical-align: sub;
                    }
                }
            }
        }
    }

    // input-form
    .input-info {
        //BTOCSITE-26772 사파리 버그 수정 
        -webkit-transform: translate3d(0, 0, 0); 
        .tit-wrap {
            @include clearfix;
            position:relative;
            margin-bottom:24px;
            padding:0;
            .h2-tit {
                @include font($font-large-2,34px);
                @include mobile {
                    @include font($font-large-1,28px);
                }
                .point {
                    display: inline-block;
                    margin-left: 4px;
                    color: $color-primary;
                }
                &.req:after {
                    content: '*';
                    display: inline-block;
                    margin-left: 1px;
                    color:$color-primary;
                    font-size: inherit;
                    line-height: inherit;
                    vertical-align: top;
                }
            }
            &.type2 {
                margin-bottom:12px;
                .h2-tit {
                    display:inline-block;
                    padding-right:100px;
                    @include font-medium;
                    font-weight: 700;
                    vertical-align:middle;
                    // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    color: #000; // BTOCSITE-59759 [주문] [구독 Task] Sales - 임직원가 적용
                }
            }
            &.type3 {
                .h2-tit {
                    display:inline-block;
                    @include font-medium;
                    vertical-align:middle;
                    @include mobile {
                        font-size:13px;
                        line-height:21px;
                    }
                }
            }

            .btn-link {
                position:absolute;
                top:50%;
                right:0;
                transform:translateY(-50%);
            }
        }
        &.type2 {
            margin-top: 48px;
            .bullet-list.top-border {
                margin-top: 32px;
            }
        }
    }

    .rmsf-pop {
        .form-infos {
            margin-top: -40px;
        }
        .input-info + .input-info {
            margin-top: 80px;
        }
        @include mobile {
            .form-infos {
                margin-top: -20px;
            }
            .input-info + .input-info {
                margin-top: 60px;
            }
        }
    }

    // popup list
    .pop-list-wrap + .pop-list-wrap {
        margin-top: 24px;
    }
    .com-pop-tit {
        margin-bottom: 12px;
        @include font-large;
        font-weight: 700;
    }
    .com-pop-list {
        li {
            margin-bottom: 4px;
            @include font-medium;
            a {
                text-decoration: underline;
            }
        }  
    }

    /* 210322 공통사용으로 추가 */
    .non-members {
        .cont-wrap {
            max-width:1146px + 80px;
            @include mobile {
                padding:0 16px;
            }
            &.login-type {
                max-width:678px + 80px;
            }
        }
        .welcome-joinus {
            display:table;
            width:100%;
            margin-bottom:48px;
            padding:24px 40px;
            border-radius:8px;
            background:#f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
            @include mobile {
                margin-bottom:40px;
                padding:20px 16px;
            }
            .cell {
                display:table-cell;
                vertical-align:middle;
                &.text {
                    @include font-medium;
                    font-weight:700;
                }
                &.btns {
                    padding-left:24px;
                    text-align:right;
                    @include mobile {
                        padding-left:16px;
                    }
                }
            }
        }
        .gy-info-box {
            padding:40px;
            border-radius:8px;
            background:#f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
            @include mobile {
                padding:24px 16px;
            }
            .gy-tit {
                margin-top:24px;
                color:#000;
                @include font-small;
                font-weight:700;
                @include mobile {
                    margin-top:16px;
                }
                &:first-child {
                    margin-top:0;
                }
            }
            .bullet-list {
                margin-top:8px;
            }
        }
        .customer-center-info {
            display:table;
            width:100%;
            table-layout:fixed;
            margin-top:24px;
            padding:24px 32px;
            border-radius:8px;
            background:#f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
            @include mobile {
                display:block;
                position:relative;
                padding:24px 16px;
                padding-left:76px;
            }
            .hello {
                &::before {
                    content:' ';
                    display:inline-block;
                    width:48px;
                    height:48px;
                    margin-right:20px;
                    background:url("/lg5-common/images/icons/icon-call-svc-48.svg") center no-repeat;
                    vertical-align:middle;
                }
                display:table-cell;
                padding-right:24px;
                @include font-small;
                font-weight:500;
                vertical-align:middle;
                @include mobile {
                    display:block;
                    &::before {
                        position:absolute;
                        top:24px;
                        left:16px;
                    }
                }
            }
            &.type2 {
                margin-top:80px;
                margin-bottom:16px;
                @include mobile {
                    margin-top:60px;
                    margin-bottom:12px;
                }
                .hello {
                    position:relative;
                    width:59%;
                    padding-left:68px;
                    padding-right:56px;
                    word-break:keep-all;
                    &::before {
                        position:absolute;
                        top:50%;
                        left:0;
                        margin-top:-24px;
                    }
                    @include mobile {
                        position:static;
                        width:100%;
                        padding:0;
                        &::before {
                            top:24px;
                            left:16px;
                            margin-top:0;
                        }
                    }
                }
            }
            .call-info {
                display:table-cell;
                border-left:1px solid #ddd;
                vertical-align:middle;
                @include mobile {
                    display:block;
                    margin-top:14px;
                    padding-top:14px;
                    border-top:1px solid #ddd;
                    border-left:none;
                }
                ul {
                    font-size:0;
                    li {
                        display:inline-block;
                        margin-left:32px;
                        vertical-align:middle;
                        color:#767676;
                        font-size:12px;
                        line-height:22px;
                        word-break:keep-all;
                        vertical-align:top;
                        br {
                            display:none;
                        }
                        @include mobile {
                            width:50%;
                            margin-left:0;
                            padding-right:40px;
                            line-height:20px;
                            br {
                                display:block;
                            }
                        }
                        em {
                            display:inline-block;
                            margin-left:12px;
                            color:#000;
                            font-size:18px;
                            line-height:28px;
                            font-weight:700;
                            vertical-align:middle;
                            @include mobile {
                                display:block;
                                margin-top:8px;
                                margin-left:0;
                                font-size:15px;
                                line-height:23px;
                            }
                        }
                    }
                }
            }
            &.type2 {
                .call-info {
                    ul {
                        li {
                            margin:0;
                            padding-left:32px;
                            width:50%;
                            @include mobile {
                                padding-left:0;
                            }
                            br {
                                display:block;
                            }
                            em {
                                display:block;
                                margin:0;
                                margin-top:2px;
                            }
                        }
                    }
                }
            }
        }
        .membership-benefit {
            display:table;
            width:calc(100% + 48px);
            margin:40px -24px 0;
            table-layout:fixed;
            border-collapse:separate;
            border-spacing:24px 0;
            @include mobile {
                display:block;
                width:auto;
                margin:60px -16px 0;
                padding:32px 16px 60px;
                border-top:12px solid #f4f4f4;
            }
            .box {
                display:table-cell;
                padding:32px 40px;
                border-radius:8px;
                box-shadow:2px 4px 16px 0 rgba(#000, .14);
                @include mobile {
                    display:block;
                    margin-top:12px;
                    padding:24px 20px;
                    &:first-child {
                        margin-top:0;
                    }
                }
                dl {
                    &::before {
                        content:' ';
                        display:block;
                        width:64px;
                        height:64px;
                        margin-bottom:24px;
                        @include mobile {
                            margin-bottom:16px;
                        }
                    }
                    dt {
                        color:#000;
                        @include font-large-2;
                        font-weight:700;
                    }
                    dd {
                        margin-top:8px;
                        color:#767676;
                        @include font-medium;
                        @include mobile {
                            margin-top:6px;
                        }
                    }
                }
                &.membership {
                    dl::before {
                        background:url("/lg5-common/images/icons/icon-img01-64.svg") center no-repeat;
                    }
                }
                &.card-benefit {
                    dl::before {
                        background:url("/lg5-common/images/icons/icon-img03-64.svg") center no-repeat;
                    }
                }
            }
        }
    
        // 주문조회 로그인 안내 팝업
        .non-members-login {
            text-align: center;
            .hello-msg {
                margin-bottom:20px;
                @include font-medium;
            }
            .hello-desc {
                @include font-medium;
                word-break:keep-all;
                em {
                    font-weight:700;
                    color: $color-primary;
                }
            }
            .call-info {
                margin-top:20px;
                padding:24px;
                border-radius:8px;
                background:#f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                ul {
                    font-size:0;
                    li {
                        display:inline-block;
                        width:50%;
                        vertical-align:top;
                        color:#767676;
                        font-size:12px;
                        line-height:22px;
                        font-weight:500;
                        @include mobile {
                            line-height:20px;
                        }
                        em {
                            display:block;
                            margin-top:2px;
                            color:#000;
                            @include font-large;
                            font-weight:700;
                        }
                    }
                }
            }
        }
    
        // 비회원 로그인 화면
        .non-members-login-tabs {
            padding:24px 24px 0;
            .btn-group {
                margin-top:32px;
            }
            @include mobile {
                padding:24px 12px 0;
            }
        }
    
        &[data-consumables=true] {
            @include mobile {
                .info-tbl-wrap .box .tbl-layout .tbody .row {
                    padding-left:0;
                }
            }
        }
    }
    // S : BTOCSITE-25299
    &.find-address-layer {
        max-width: 500px;
        .pop-conts {
            padding-top: 0;
            padding-bottom: 0;
            overflow: hidden;
            @include pc {
                height: 500px;
            }
            @include mobile{
                height: 100vh;
                max-height: 100vh;
            }
        }
    }
    // E : BTOCSITE-25299
}

// S : BTOCSITE-63006 [공통] GNB >비회원 로그인 팝업
#popup-beforeNoneMemOrder {
    max-width: 444px;
    .pop-header {
        padding: 30px 0 10px;
        border-bottom: 1px solid #0f0f0f;
        @include mobile {
            padding: 24px 0 12px;
        }
        .tit span {
            height: auto;
            line-height: 26px;
            @include mobile {
                font-size: 16px;
                line-height: 23px;
            }            
        }
    }
    .pop-conts {
        padding-top: 16px;
        padding-bottom: 30px;
    }
    .pop-footer {
        visibility: initial;
        padding: 14px 0;
        @include mobile {
            padding: 14px 16px;
        }
        .btn-group {
            @include mobile {
                display: flex;
                gap: 0 8px;
                padding: 0;
            }
        }
        .btn {
            padding: 14px 0;
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
            border-radius: 6px;
            @include pc {
                min-width: 178px;
            }
            @include mobile {
                margin: 0;
            }
        }
    }
}
// E : BTOCSITE-63006 [공통] GNB >비회원 로그인 팝업

// window popup
.win-popup-wrap {
    position: relative;
    //max-width: 912px;
    .pop-header {
        position: fixed;
        width: 100%;
        height: 78px;
        padding: 32px 48px 16px;
        background-color: #fff;
        z-index: 99;
        &:after {
            display: inline-block;
            position: absolute;
            bottom: 0;
            left: 48px;
            content: '';
            width: calc(100% - 96px);
            height: 2px;
            background-color: #222;
        }
        @include mobile {
            height: 60px;
            padding: 24px 16px 12px;
            &:after {
                width: calc(100% - 32px);
                left: 16px;
            }
        }
        .tit {
            @include font-large-1;
            font-weight: $font-weight-bold;
        }
    }
    .win-btn-close {
        position:fixed;
        top:40px;
        right:48px;
        width:24px;
        height:24px;
        z-index: 100;
        @include mobile {
            top:24px;
            right:16px;
        }
        &::before {
            content:' ';
            position:absolute;
            top:0;
            left:0;
            width:24px;
            height:24px;
            background:url('/lg5-common/images/icons/btn-close-32.svg') no-repeat;
            background-size:24px;
        }
        
    }
    .pop-conts {
        padding: 126px 48px 48px; //content padding-top:48px
        @include mobile {
            //padding: 32px 16px 60px;
            padding: 100px 16px 60px;
        }
        .tit-wrap {
            .h2-tit {
                font-weight: 700;
                font-size: $font-large-2;
                line-height: 34px;
            }
            @include mobile {
                //padding-bottom: 32px;
                .h2-tit {
                    font-size: $font-large-1;
                    line-height: 28px;
                }
            }
        }
        .h3-tit {
            margin-bottom: 8px;
            @include font-small;
            color: $color-default;
            font-weight: $font-weight-bold;
        }
       .btn-group {
           padding-top: 48px;
           text-align: center;
           @include mobile {
               padding-top: 32px;
           }
       }
       .tabs-wrap.border-type {
           &:before {
               display:none;
           }
       }
    }
    .pop-footer {
        //margin-top: -16px;
        padding: 16px 0 48px;
        background: #fff;
        @include mobile {
            position: fixed;
            bottom: 0;
            width: 100%;
            margin: 0;
            padding: 0;
        }
        .btn-group {
            text-align: center;
            @include mobile {
                button, a {
                    border-radius: 0;
                } 
            } 
        }
    }
}

#history-popup {
    &.lately-popup {
        .pop-conts {
            &.support {
                .lately-list {
                    ul {
                        li {
                            dl {
                                dd {
                                    &.category {
                                        font-size: 14px;
                                        font-weight: 500;
                                        color: #666;
                                        line-height: 1.71;
                                        margin-top: 8px;
                                        @include textEllipsis;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        @include mobile {
            left: 0 !important;
        }
    }
}

// AR 체험 안내
.ar-info-wrap {
    .info-area {
        padding-left: 96px;
        background: url('/lg5-common/images/icons/icon-app-logo.svg') no-repeat 0 center;
        background-size: 64px;
        .info {
            margin-bottom: 8px;
            font-weight: 700;
            font-size: 16px;
            line-height: 26px;
        }
        .desc {
            &:before {
                content: '*';
            }
            font-size: 14px;
            line-height: 24px;
        }
        @include mobile {
            padding: 80px 0 0;
            background-position: center top;
            text-align: center;
            .info {
                font-size: 15px;
                line-height: 23px;
            }
            .desc {
                line-height: 22px;
            }
        }
    }
    .app-download-list {
        margin-top: 48px;
        font-size: 0;
        li {
            display: inline-block;
            width: 50%;
            padding: 0 8px;
            @include pc {
                .title {
                    &:before {
                        display: inline-block;
                        height: 24px;
                        margin-right: 8px;
                        background: no-repeat center center;
                        vertical-align: text-bottom;
                        content: '';
                    }
                }
                &.google-play {
                    .title {
                        &:before {
                            width: 22px;
                            background-image: url('/lg5-common/images/icons/icon-android.png');
                        }
                    }
                }
                &.app-store {
                    .title {
                        &:before {
                            width: 21px;
                            background-image: url('/lg5-common/images/icons/icon-apple.png');
                        }
                    }
                }
            }
        }
        .box {
            padding: 16px 16px 24px;
            border-radius: 8px;
            background-color: #f3f3f3; // [BTOCSITE-81509] [웹앱 접근성 개선활동] PDP - 일반, 케어용품/소모품, 가전구독, UP가전2.0
            text-align: center;
        }
        .title {
            display: inline-block;
            margin-bottom: 16px;
            font-weight: 500;
            font-size: 14px;
            line-height: 26px;
        }
        .qr-code {
            img {
                width: 80px;
            }
        }
        .btn-download {
            display: none;
        }

        @include mobile {
            margin-top: 40px;
            li {
                display: block;
                width: 100%;
                &.app-store {
                    margin-top: 12px;
                }
            }
            .qr-code {
                display: none;
            }
            .title {
                line-height: 22px;
            }
            .btn-download {
                display: block;
                img {
                    width: 100%;
                    max-width: 160px;
                }
            }
        }
    }
}

.app-popup-init {
    .headline {
        .h-tit-3 {
            margin-bottom:24px;
            font-weight: 700;
            font-size: 18px;
            line-height:1.63;
        }
    }
    .pop-conts {
        font-size: 16px;
        line-height: 1.63;
    }
    .btn-wrap {
        font-size:0;
        margin-top:24px;
        text-align: center;

        .btn {
            margin:0 4px;
        }
    }

    .btn {
        &.full {
            width:100%;
            max-width:none;
            margin:0;
        }
    }
    .pop-footer {
        &.check-type {
            input {
                &+label {
                    padding-left: 30px;
                    font-size: 16px;
                    line-height: 26px;

                    @include mobile {
                        font-size:13px;
                    }
                    &::before {
                        width: 20px;
                        height: 20px;
                    }
                    &::after {
                        top: 2px;
                        left: 0;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
            .btn {
                padding: 15px 25px;
                border-radius: 100px;
                font-size: 16px;
            }
        }
        &.align-between {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.main-init-popup {

    @include mobile {
        &.small {
            max-height:95vh !important;
            min-height:40vh !important;
            // height:90vh !important;
        }
    }
    .headline {
        .h-tit-3 {
            margin-bottom:24px;
            font-weight: 700;
            font-size: 18px;
            line-height:1.63;

            @include mobile {
                font-size:16px;
            }
        }
    }
    .pop-conts {
        font-size: 16px;
        line-height: 1.63;
        padding-bottom:20px;
        @include mobile {
            font-size:14px;
            height: calc(100% - 132px);
            max-height: calc(100% - 132px);
            padding-bottom:20px !important;
        }
    }
    .btn-wrap {
        font-size:0;
        margin-top:24px;
        text-align: center;

        .btn {
            margin:0 4px;
        }
    }

    .btn {
        &.full {
            width:100%;
            max-width:none;
            margin:0;
        }
    }
    .pop-footer {
        &.check-type {
            input {
                &+label {
                    padding-left: 30px;
                    font-size: 16px;
                    line-height: 26px;

                    @include mobile {
                        font-size:13px;
                    }
                    &::before {
                        width: 20px;
                        height: 20px;
                    }
                    &::after {
                        top: 2px;
                        left: 0;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
            .btn {
                padding: 10px 25px;
                border-radius: 100px;
                font-size: 16px;
            }
        }
        &.align-between {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }
}

/* 제휴카드 발급/변경 자동 등록을 위한 제3자 정보제공 동의 */

/* //제휴카드 발급/변경 자동 등록을 위한 제3자 정보제공 동의 */

// S : BTOCSITE-12307 : 앱설치 안내
.app-install-guide {
    max-width:678px !important;
    .app-guide-box {
        display: flex;
        align-items: center;

        .guide {
            &-thumb {
                flex:none;
                width:64px;
                height:64px;
        
                img {
                    width:100%;
                }
            }
            &-content {
                margin-left:31px;
            }
            &-title {
                display: block;
                font-size:20px;
                font-weight:bold;
                color:#000;
                line-height:1.5;
            }
            &-desc {
                font-size:16px;
                color:#000;
                line-height:1.63;
            }

            @include mobile {
                &-content {
                    margin-left:16px;
                }
                &-title {
                    font-size:16px;
                }
                &-desc {
                    font-size:13px;
                }
            }
        }
    }

    .app-qr-box {
        margin-top:48px;

        @include mobile {
            margin-top:30px;
        }

        .qr-box {
            &-row {
                display: flex;
            }
            &-col {
                flex:1;
                padding:16px 24px 24px;
                text-align: center;
                background: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                border-radius: 8px;
                & + .qr-box-col {
                    margin-left:17px;
                }

                @include mobile {
                    padding:12px 16px;

                    & + .qr-box-col {
                        margin-left:12px;
                    }
                }
            }
        }

        .qr-title {
            display: block;
            margin-bottom:16px;
            font-size:0;
            text-align: center;
            &:before {
                content:"";
                display: inline-block;
                margin-right:8px;
                vertical-align: middle;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 100% 100%;
            }

            &.android {
                &:before {
                    width:22px;
                    height:24px;
                    background-image: url('/lg5-common/images/LLS/icon-android.png');
                }
            }
            &.ios {
                &:before {
                    width:21px;
                    height:24px;
                    background-image: url('/lg5-common/images/LLS/icon-apple.png');
                }
            }
            span {
                display: inline-block;
                font-size:14px;
                line-height:24px;
                font-weight:bold;
                color:#000;
                vertical-align: middle;
            }


            @include mobile {
                margin-bottom:12px;

                &:before {
                    margin-right:4px;
                }

                &.android {
                    &:before {
                        width:16px;
                        height:18px;
                    }
                }
                &.ios {
                    &:before {
                        width:15px;
                        height:17px;
                    }
                }
                span {
                    font-size:13px;
                    line-height:24px;
                }
            }
        }

        .qr-img {
            max-width:80px;
            margin:0 auto;
            img {
                width:100%;
            }
        }
    }
}
// E : BTOCSITE-12307 : 앱설치 안내
// S: LGECOMVIO-20 step2: PDP 소모품 전체보기 팝업 , BTOCSITE-17845 연관 제품 
.btn-link {
    &.no-line {
        text-decoration: none;
    }
}
#relativeProdViewPopup,
.relative-prod-view {
    .pop-header {
        @include mobile {
            margin: 0;
            padding: 54px 20px 30px;
            border-bottom: 0;

            .tit {
                span {
                    font-size: 18px;
                }
            }
        }
    }
    .pop-conts {
        @include pc {
            // min-height:976px;
        }
        @include mobile {
            height:calc(100% - 108px);
            max-height:calc(100% - 108px);
            padding-top:0;
        }
    }
    .btn-close {
        @include mobile {
            top: 18px;
        }
    }

    .product-list {
        &__top {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom:16px;
            @include mobile {
                margin-bottom:8px;
                padding: 11px 0;
            }
        }
        &__total {
            font-size:13px;
            line-height:19px;
            color:#000;
            @include pc {
                font-size: 14px;
                line-height: 20px;
            }
            .count {
                color:$color-primary;// BTOCSITE-81509 [웹앱 접근성 개선활동] PDP - 일반, 케어용품/소모품, 가전구독, UP가전2.0
            }
        }
        &__sort {
            @include mobile {
                .ui-select-button {
                    padding-right: 20px;
                    .ui-select-text {
                        color: #666;
                    }
                    .ico {
                        background-image: url("/lg5-common/images/icons/btn-down-16-gray.svg");
                    }
                }
            }
        }
    }
    .product-item {
        display:none;
        background-color: #fff;

        &.is-active {
            display: block;
        }

        @include pc {
            border-radius: 16px;
            box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.14);
        }
        @include mobile {
            border-radius: 8px;
            box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.14);
        }

        & + .product-item {
            @include pc {
                margin-top:24px;
            }
            @include mobile {
                margin-top:12px;
            }
        }

        .item-box {
            display: flex;
            align-items: flex-start;
            @include pc {
                min-height:148px;
                padding:16px 38px 16px 32px;
            }
            @include mobile {
                min-height:128px;
                padding:20px 16px;
            }
        }
        &__thumb {
            align-self: center;
            @include pc {
                width:116px;
                height:116px;
            }
            @include mobile {
                margin-top:6px;
                width:88px;
                height:88px;
            }

            img {
                width:100%;
                height:100%;
            }
        }
        &__infos {
            flex:1;
            @include pc {
                padding:16px 0 0 38px;
            }
            @include mobile {
                padding:0 0 0 16px;
            }
        }
        &__category {
            span + span {
                &:before {
                    content: ' > ';
                }
            }
            @include pc {
                font-size:14px;
                line-height:1.43;
            }
            @include mobile {
                font-size:10px;
                line-height:15px;
            }
        }
        &__name {
            font-weight:700;
            @include textEllipsisClamp(2);
            @include pc {
                font-size:18px;
                line-height:1.43;
            }
            @include mobile {
                font-size:16px;
                line-height:22px;
            }
        }
        &__sku {
            color:#666;
            @include pc {
                margin-top:8px;
                font-size:12px;
                line-height:1.5;
            }
            @include mobile {
                font-size:10px;
                line-height:15px;
            }
        }
        &__price {
            @include pc {
                // margin-top:-8px;
                display: inline-block;
                float: right;
                margin-top:8px;
                font-size:0;
            }

            @include mobile {
                .discount,
                .current-price,
                .origin-price,
                .soldout {
                    margin-top: 4px;
                }
            }
            .soldout {
                display: inline-block;
                padding:0 6px;
                font-size:11px;
                line-height:20px;
                color:#fff;
                background: #666;
                border-radius: 4px;

                @include mobile {
                    //margin-left: 6px;
                    vertical-align: 1px;
                }

                @include pc {
                    float: left;
                    padding: 0 8px;
                    font-size:14px;
                    line-height: 26px;
                    font-weight:500;
                }
            }
            .discount {
                display: inline-block;
                font-size:15px;
                line-height:21px;
                color:$color-primary;// BTOCSITE-81509 [웹앱 접근성 개선활동] PDP - 일반, 케어용품/소모품, 가전구독, UP가전2.0

                @include mobile {
                    margin-right: 6px;
                }
                @include pc {
                    margin-left:16px;
                    font-size:16px;
                    line-height:24px;
                }
            }
            .current-price {
                display: inline-block;
                color:#000;

                @include pc {
                    margin-left:8px;
                    font-size:16px;
                    font-weight: 700;
                    line-height:24px;
                }
                @include mobile {
                    font-size:15px;
                    line-height:21px;
                    font-weight: bold;
                    margin-right: 6px;
                }
            }
            .origin-price {
                display: inline-block;
                color:#8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                text-decoration: line-through;

                @include pc {
                    margin-left:8px;
                    font-size:12px;
                    line-height:18px;
                }
                @include mobile {
                    margin-right: 6px;
                    font-size:11px;
                    line-height:15px;
                    vertical-align: 1px;
                }
            }
        }

        &.is-soldout {
            .product-item__price {
                .discount {
                    color:#e984a0;
                }
                .current-price {
                    color:#666
                }
                .origin-price {
                    color:#8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                }
            }
        }
    }
}
// E: LGECOMVIO-20 step2: PDP 소모품 전체보기 팝업
/* BTOCSITE-16830	웹접근성 확인 요청_ LGE.com(렌탈케어십 신청/조회, 출장서비스 신청/조회) 접근성 이슈 검토 요청*/
/* Landscape 모드일 때 적용할 CSS  - 가로모드 */
@media (orientation: landscape) {
    .mobile{
        .popup-wrap{
            &.full{
                height:100% !important;
                .pop-conts{
                    &.no-footer{
                        height:calc(100% - 78px) !important;
                    }
                }
            }
        }
    }
}
/* //BTOCSITE-16830	웹접근성 확인 요청_ LGE.com(렌탈케어십 신청/조회, 출장서비스 신청/조회) 접근성 이슈 검토 요청*/
// S: LGECOMVIO-20 step2: PDP 소모품 전체보기 팝업
// LGECOMVIO-20 step2 : 소모품 전체 팝업 관련 추가
.btn-link {
    &.no-line {
        text-decoration: none;
    }
}
#relativeProdViewPopup {
    .pop-header {
        @include mobile {
            margin: 0;
            padding: 54px 20px 30px;
            border-bottom: 0;

            .tit {
                span {
                    font-size: 18px;
                }
            }
        }
    }
    .pop-conts {
        @include pc {
            // min-height:976px;
        }
        @include mobile {
            height:calc(100% - 108px);
            max-height:calc(100% - 108px);
            padding-top:0;
        }
    }
    .btn-close {
        @include mobile {
            top: 18px;
        }
    }

    .product-list {
        &__top {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom:16px;
            @include mobile {
                margin-bottom:8px;
                padding: 11px 0;
            }
        }
        &__total {
            font-size:13px;
            line-height:19px;
            color:#000;
            @include pc {
                font-size: 14px;
                line-height: 20px;
            }
            .count {
                color:$color-primary;// BTOCSITE-81509 [웹앱 접근성 개선활동] PDP - 일반, 케어용품/소모품, 가전구독, UP가전2.0
            }
        }
        &__sort {
            @include mobile {
                .ui-select-button {
                    padding-right: 20px;
                    .ui-select-text {
                        color: #666;
                    }
                    .ico {
                        background-image: url("/lg5-common/images/icons/btn-down-16-gray.svg");
                    }
                }
            }
        }
    }
    .product-item {
        display:none;
        background-color: #fff;

        &.is-active {
            display: block;
        }

        @include pc {
            border-radius: 16px;
            box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.14);
        }
        @include mobile {
            border-radius: 8px;
            box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.14);
        }

        & + .product-item {
            @include pc {
                margin-top:24px;
            }
            @include mobile {
                margin-top:12px;
            }
        }

        .item-box {
            display: flex;
            align-items: flex-start;
            @include pc {
                min-height:148px;
                padding:16px 38px 16px 32px;
            }
            @include mobile {
                min-height:128px;
                padding:20px 16px;
            }
        }
        &__thumb {
            align-self: center;
            @include pc {
                width:116px;
                height:116px;
            }
            @include mobile {
                margin-top:6px;
                width:88px;
                height:88px;
            }

            img {
                width:100%;
                height:100%;
            }
        }
        &__infos {
            flex:1;
            @include pc {
                padding:16px 0 0 38px;
            }
            @include mobile {
                padding:0 0 0 16px;
            }
        }
        &__category {
            span + span {
                &:before {
                    content: ' > ';
                }
            }
            @include pc {
                font-size:14px;
                line-height:1.43;
            }
            @include mobile {
                font-size:10px;
                line-height:15px;
            }
        }
        &__name {
            font-weight:700;
            @include textEllipsisClamp(2);
            @include pc {
                font-size:18px;
                line-height:1.43;
            }
            @include mobile {
                font-size:16px;
                line-height:22px;
            }
        }
        &__sku {
            color:#666;
            @include pc {
                margin-top:8px;
                font-size:12px;
                line-height:1.5;
            }
            @include mobile {
                font-size:10px;
                line-height:15px;
            }
        }
        &__price {
            @include pc {
                // margin-top:-8px;
                display: inline-block;
                float: right;
                margin-top:8px;
                font-size:0;
            }

            @include mobile {
                .discount,
                .current-price,
                .origin-price,
                .soldout {
                    margin-top: 4px;
                }
            }
            .soldout {
                display: inline-block;
                padding:0 6px;
                font-size:11px;
                line-height:20px;
                color:#fff;
                background: #666;
                border-radius: 4px;

                @include mobile {
                    //margin-left: 6px;
                    vertical-align: 1px;
                }

                @include pc {
                    float: left;
                    padding: 0 8px;
                    font-size:14px;
                    line-height: 26px;
                    font-weight:500;
                }
            }
            .discount {
                display: inline-block;
                font-size:15px;
                line-height:21px;
                color:$color-primary;// BTOCSITE-81509 [웹앱 접근성 개선활동] PDP - 일반, 케어용품/소모품, 가전구독, UP가전2.0

                @include mobile {
                    margin-right: 6px;
                }
                @include pc {
                    margin-left:16px;
                    font-size:16px;
                    line-height:24px;
                }
            }
            .current-price {
                display: inline-block;
                color:#000;

                @include pc {
                    margin-left:8px;
                    font-size:16px;
                    font-weight: 700;
                    line-height:24px;
                }
                @include mobile {
                    font-size:15px;
                    line-height:21px;
                    font-weight: bold;
                    margin-right: 6px;
                }
            }
            .origin-price {
                display: inline-block;
                color:#8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                text-decoration: line-through;

                @include pc {
                    margin-left:8px;
                    font-size:12px;
                    line-height:18px;
                }
                @include mobile {
                    margin-right: 6px;
                    font-size:11px;
                    line-height:15px;
                    vertical-align: 1px;
                }
            }
        }

        &.is-soldout {
            .product-item__price {
                .discount {
                    color:#e984a0;
                }
                .current-price {
                    color:#666
                }
                .origin-price {
                    color:#8f8f8f; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                }
            }
        }
    }
}
// E: LGECOMVIO-20 step2: PDP 소모품 전체보기 팝업
// S : BTOCSITE-13902 : 고객번호 확인 안내
.membership-guide-wrap {
    .pop-conts {
        min-height: 628px;
        padding-top: 60px;
        .membership-guide {
            text-align: center;
            ul {
                display: inline-block;
                margin: auto;
                li {
                    display: inline-block;
                    .guide-inner {
                        width: 300px;
                        margin: auto;
                    }
                    .image {
                        width: 177px;
                        margin: auto;
                        img {width: 100%}
                    }
                    .text-area {
                        padding-top: 36px;
                        padding-bottom: 41px;
                        .tit, .desc {
                            font-size: 16px;
                            line-height: 1.75;
                        }
                        .desc {
                            margin-top: 12px;
                        }
                    }
                }
                @include clearfix;
            }
            @include pc {
                .controls-wrap, .indi-wrap {
                    display: none;
                }
            }
        }
        @include mobile {
            min-height: auto;
            padding-top: 40px;
            .membership-guide {
                position: relative;
                .guide-inner {
                    margin: auto;
                    .image {
                        width: 165px;
                    }
                }
                .controls-wrap {
                    .btn-arrow {
                        position: absolute;
                        top: 50%;
                        margin-top: -18px;
                        &.prev {
                            left: 0;
                        }
                        &.next {
                            right: 0;
                        }
                    }
                }
            }
        }
    }
}
// E : BTOCSITE-13902 : 고객번호 확인 안내
// S : BTOCSITE-13902 : LG전자 멤버십 가입안내
.membership-join-wrap {
    .desc {
        line-height: 1.63;
    }
    h2 {
        font-size: 16px;
        font-weight: bold;
        color: #000;
        margin: 32px 0 16px;
    }
    .box {
        padding: 24px;
        background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
        border-radius: 8px;
        text-align: center;
        > ul {
            > li {
                &+li {
                    padding-top: 18px;
                    margin-top: 18px;
                    border-top: 1px solid #ddd;
                }
                font-size: 16px;
                line-height: 24px;
                strong {
                    span {
                        display: inline-block;
                        font-size: 14px;
                        vertical-align: middle;
                    }
                    &:before {
                        content:"";
                        display: inline-block;
                        margin-right:8px;
                        vertical-align: middle;
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: 100% 100%;
                    }
                }
                &.android {
                    strong:before {
                        width:22px;
                        height:24px;
                        background-image: url('/lg5-common/images/LLS/icon-android.png');
                    }
                }
                &.ios {
                    strong:before {
                        width:21px;
                        height:24px;
                        background-image: url('/lg5-common/images/LLS/icon-apple.png');
                    }
                }
            }
        }
    }
    @include mobile {
        &.popup-wrap {
            .pop-footer {
                position: absolute;
                bottom: 0;
            }
            .pop-conts {
                height: 500px;
            }
        }
        .desc {
            font-size: 14px;
        }
        .box {
            padding: 25px 16px;
            > ul {
                > li {
                    &+li {
                        padding-top: 16px;
                        margin-top: 16px;
                    }
                    font-size: 14px;
                    strong {
                        span {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}
// E : BTOCSITE-13902 : LG전자 멤버십 가입안내

// S : BTOCSITE-59559 주문결제에서 우편번호 검색 팝업닫기 불가
// 주소찾기 팝업
.popup-wrap.bcsmap {
    .pop-conts {
        padding-top: 0;
        box-sizing: border-box;
        @include mobile {
            padding-bottom: 0 !important;
            height: calc(100% - 62px)!important;
            max-height: calc(100% - 62px) !important;
        }
        .bcsmap-post {
            width: 100%;
            height: 500px;
            @include mobile {
                height: 100% !important;
            }
        }
    }
}
// E : BTOCSITE-59559 주문결제에서 우편번호 검색 팝업닫기 불가

// S : BTOCSITE-31214 닷컴 홈메인 팝업 노출 요청
.today-popup {
    background: transparent;
    &.popup-wrap.small {
        border-radius: 0;
        outline: none;
        @media screen and (max-height: 667px) {
            height: 93%;

            .pop-conts {
                height: 93%;
                max-height: 93%;
                overflow: hidden;

                .section {
                    height: 100%;
                }
                .text-cont, .cont-wrap { //BTOCSITE-38812 세일즈포스연동오픈준비 - 홈메인 공지사항 팝업 개발 건
                    margin: 20px -30px 0;
                    padding: 0 30px;
                    overflow-y: auto;
                    height: calc(100% - 150px);
                }
            }
        }
        .pop-conts {
            position: relative;
            padding: 62px 30px 30px;
            background-color: #fff;
            border-radius: 10px;
            outline: none;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 20px;
                background: #da0f47;
            }
            .h-tit {
                font-size: 24px;
                line-height: 32px;
                font-weight: 700;
            }
            .text-cont {
                margin-top: 20px;
                font-size: 14px;
                line-height: 20px;
                text-align: left;
                word-break: keep-all;
                strong {
                    color: #da0f47;
                }
            }
            .call-box {
                margin-top: 30px;
                font-size: 18px;
                line-height: 26px;
                font-weight: 700;
                em {
                    color: #da0f47;
                }
            }
        }

        .pop-footer {
            padding: 13px 0 0;
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;

            // S : BTOCSITE-38812 세일즈포스연동오픈준비 - 홈메인 공지사항 팝업 개발 건
            &.check-type {
                padding: 16px 0 0;
                position: absolute;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
    
                input {
                    &:checked {
                        & + label {
                            &::after {
                                // background-image: url("/lg5-common/images/icons/btn-checkbox-on-48.svg");.
                                background-color: #fff;
                                background-image:  url("/lg5-common/images/icons/ico-chk-black.svg");
                                background-size: 11px auto;
                            }

                            // @include mobile {
                            //     &::after {
                            //         background-color: #fff;
                            //         background-image:  url("lg5-common/images/icons/ico-chk-black.svg");
                            //         background-size: 11px auto;
                            //     }
                            // }
                        }
                    }
                    & + label {
                        padding-left: 26px;
                        font-size: 14px;
                        line-height: 20px;
                        color: #fff;
                        &::after {
                            top: 1px;
                            left: 0;
                            width: 20px;
                            height: 20px;
                            background-image: none;
                            border: 1px solid #aaa;
                            border-radius: 3px;
                        }

                        // @include mobile {
                        //     padding-left: 28px;
                        //     font-size: 12px;
                        //     line-height: 20px;
                        // }
                    }
                }
            }
            // E : BTOCSITE-38812 세일즈포스연동오픈준비 - 홈메인 공지사항 팝업 개발 건
            .btn-text {
                margin-left: auto;
                font-size: 18px;
                font-weight: 400;
                color: #fff;

                &:hover, &:focus {
                    color: #fff;
                    text-decoration: none;
                }

                @include mobile {
                    font-size: 13px;
                    text-decoration: none;
                }
            }
        }

        // S : BTOCSITE-38812 세일즈포스연동오픈준비 - 홈메인 공지사항 팝업 개발 건
        &.today-type2 {
            // height: 92%;
            max-height: 824px;
            @media screen and (max-height: 667px) {
                height: auto;
    
                .pop-conts {
                    height: auto;
                }
            }

            .pop-conts {
                padding: 52px 40px 32px;
                // height: 92%;
                max-height: 92%;
                outline: none; 
                overflow: hidden;
                
                &::before {
                    background: #ea1917;
                }
                .section {
                    height: 100%;
                }
                .h-tit {
                    text-align: center;
                }
                
                .cont-wrap {
                    margin: 24px -40px 0;
                    padding: 0 40px;
                    font-size: 16px;
                    line-height: 24px;
                    overflow-y: auto;
                    height: calc(100% - 84px);
                    .text {
                        margin-top: 24px;
                    }
                    .btn-area {
                        margin-top: 32px;
                        text-align: right;
                        .btn-link {
                            font-weight: $font-weight-medium;
                            text-decoration: none;
                        }
                    }
                }
                .num_list {
                    > li {
                        margin-top: 16px;
                        padding:0 0 5px 0;
                        // list-style-type:decimal;
                        // list-style-position:outside;
                        &:first-child {
                            margin-top: 0;
                        }
                        .tit {
                            display: block;
                            margin-bottom: 4px;
                            font-size: 16px;   
                            line-height: 24px;
                        }
                        .desc {
                            font-size: 14px;
                            line-height: 20px;
                            color: #666;
                        }
                    }
                }

                @include mobile {
                    padding: 52px 20px 32px;
                    .h-tit {
                        margin: 0 -5px;
                        font-size: 18px;
                        line-height: 24px;
                    }
                    .cont-wrap {
                        margin: 28px -20px 0;
                        padding: 0 20px;
                        font-size: 14px;
                        line-height: 20px;
                        .text {
                            margin-top: 16px;
                        }
                        .btn-area {
                            margin-top: 32px;
                            .btn {
                                padding: 12px 25px;
                                font-size: 14px;
                            }
                        }
                    }
                    .num_list {
                        .bullet-list {
                            font-size: 13px;
                            .b-txt::before {
                                margin-top: 9px;
                            }
                        }
                        li {
                            .tit {
                                font-size: 14px;   
                                line-height: 20px;
                            }
                            .desc {
                                font-size: 13px;
                                line-height: 19px;
                            }
                        }
                    }
                }
            }
            .pop-footer {
                .btn-text {
                    font-size: 14px;
                }
            }
        }
        // E : BTOCSITE-38812 세일즈포스연동오픈준비 - 홈메인 공지사항 팝업 개발 건
        // S : BTOCSITE-61307 [홈 메인 팝업 게시 요청] 피싱사이트 주의 안내
        &.phishing-popup {
            @media screen and (max-height: 667px) {
                height: 81%;
            }
            .pop-conts {
                padding: 52px 40px 32px;
                max-height: 92%;
                outline: none; 
                overflow: hidden;
                
                &::before {
                    background: #ea1917;
                }
                .section {
                    height: 100%;
                }
                .h-tit {
                    text-align: center;
                    word-break:keep-all;
                }
                
                .cont-wrap {
                    margin: 24px -40px 0;
                    padding: 0 40px;
                    font-size: 16px;
                    line-height: 24px;
                    height: calc(100% - 84px);
                    .text {
                        margin-top: 24px;
                        word-break:keep-all;
                    }
                    .btn-area {
                        margin-top: 32px;
                        text-align: right;
                        .btn-link {
                            font-weight: $font-weight-medium;
                            text-decoration: none;
                        }
                    }
                }
                .num_list {
                    > li {
                        margin-top: 16px;
                        padding:0 0 5px 0;
                        &:first-child {
                            margin-top: 0;
                        }
                        .tit {
                            display: block;
                            margin-bottom: 4px;
                            font-size: 16px;   
                            line-height: 24px;
                            word-break:keep-all;
                        }
                        .desc {
                            font-size: 14px;
                            line-height: 20px;
                            color: #666;
                            word-break:keep-all;
                        }
                    }
                }

                @include mobile {
                    padding: 52px 20px 32px;
                    .h-tit {
                        margin: 0 -5px;
                        font-size: 18px;
                        line-height: 24px;
                    }
                    .cont-wrap {
                        margin: 28px -20px 0;
                        padding: 0 20px;
                        font-size: 14px;
                        line-height: 20px;
                        .text {
                            margin-top: 16px;
                        }
                        .btn-area {
                            margin-top: 32px;
                            .btn {
                                padding: 12px 25px;
                                font-size: 14px;
                            }
                        }
                    }
                    .num_list {
                        .bullet-list {
                            font-size: 13px;
                            .b-txt::before {
                                margin-top: 9px;
                            }
                        }
                        li {
                            .tit {
                                font-size: 14px;   
                                line-height: 20px;
                            }
                            .desc {
                                font-size: 13px;
                                line-height: 19px;
                            }
                        }
                    }
                }
            }
            .pop-footer {
                .btn-text {
                    font-size: 14px;
                }
            }        
        }
        // E : BTOCSITE-61307 [홈 메인 팝업 게시 요청] 피싱사이트 주의 안내
    }
}
// E : BTOCSITE-31214 닷컴 홈메인 팝업 노출 요청

// S : BTOCSITE-51511 thinQ qrcode 팝업
.popup-wrap.popup-thinq-qrcode {
    .pop-conts {
        padding-top: 15px;
        .qr-code {
            text-align: center;
            img {
                width: 160px;
                height: 160px;
            }
        }
        .bullet-list {
            margin-top: 16px;
        }
    }
}
// E : BTOCSITE-51511 thinQ qrcode 팝업

// S : BTOCSITE-51644 홈메인 트윈스 기획전 팝업 게재 요청
.fireworks {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9002;
    pointer-events: none;
}
.winwin-popup {
    background: transparent;
    &.popup-wrap.small {
        max-width: 316px;
        outline: none;
        border-radius: 0;
        @media screen and (max-height: 667px) {
            height: auto;
    
            .pop-conts {
                height: auto;
            }
        }    
        .pop-conts {
            position: relative;
            padding: 0;
            border-radius: 10px;
            .img-area {
                text-align: center;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .btn-area {
                .btn-link {
                    display: block;
                    padding: 11px 0;
                    font-size: 14px;
                    text-align: center;
                    background:#fff;
                    color: #000;
                    font-weight: 700;
                    text-decoration: none;
                    &:after {
                        position: static;
                        display: inline-block;
                        margin-left: 4px;
                        vertical-align: -2px;
                        background: url(/lg5-common/images/icons/btn_arrow_right_16.svg) 50% / 100%;
                    }
                }
            }
        }

        .pop-footer {
            padding: 14px 0 0;
            position: absolute;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            &.check-type {
                input {
                    &:checked {
                        & + label {
                            &::after {
                                background-color: #fff;
                                background-image:  url("/lg5-common/images/icons/ico-chk-black.svg");
                                background-size: 11px auto;
                            }
                        }
                    }
                    & + label {
                        padding-left: 28px;
                        font-size: 13px;
                        line-height: 20px;
                        color: #fff;
                        &:after {
                            top: 1px;
                            left: 0;
                            width: 20px;
                            height: 20px;
                            background-image: none;
                            border: 1px solid #aaa;
                            border-radius: 3px;
                        }
                    }
                }
            }
            .btn-text {
                margin-left: 0;
                font-weight: 400;
                font-size: 13px;
                color: #fff;
                &:hover, &:focus {
                    color: #fff;
                    text-decoration: none;
                }
                @include mobile {                    
                    text-decoration: none;
                }
            }
        }
    }
}
// E : BTOCSITE-51644 홈메인 트윈스 기획전 팝업 게재 요청

// S : BTOCSITE-49721 UP가전 2.0 통합 장바구니 기능 구현
.benefit-pop {
    .tit {
        position: relative;
        padding-left: 11px;
        font-size: 16px;
        line-height: 23px;
        font-weight: 700;
        @include mobile {
            padding-left: 6px;
            font-size: 13px;
            line-height: 19px;
        }
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 10px;
            display: block;
            width: 3px;
            height: 3px;
            border-radius: 100%;
            background: #0f0f0f;
            @include mobile {
                width: 2px;
                height: 2px;
            }
        }
        &-wrap {
            margin-bottom: 12px;
            &:not(:first-child) {
                margin-top: 32px;
            }
        }
    }
    // S : BTOCSITE-59759 [주문] [구독 Task] Sales - 임직원가 적용
    .bullet-list {
        margin-top: 0;
        font-size: 14px;
        color: #666; 
        @include mobile {
            font-size: 13px;
            line-height: 20px;
        }
        .b-txt {
            margin-top: 6px; // BTOCSITE-60120 [구독 Task] Sales - 재구독
            &:first-child {
                margin-top: 0;
            }
            &:before {
                width: 2px;
                height: 2px;
                background: #666;
            }
        }
    }
    // E : BTOCSITE-59759 [주문] [구독 Task] Sales - 임직원가 적용
    .gray-box {
        padding: 12px 16px;
        border-radius: 8px;
        background: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
        @include mobile {
            padding: 10px 16px;
        }
        .bullet-list {
            margin: 4px 0;
            line-height: 20px;
            @include mobile {
                margin: 6px 0; // BTOCSITE-60120 [구독 Task] Sales - 재구독
                line-height: 20px;
            }
            .b-txt {
                &:before {
                    margin-top: 10px;   // BTOCSITE-59759 [주문] [구독 Task] Sales - 임직원가 적용
                }
            }
        }
    }
    .fee-info-total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        dt {
            font-size: 14px;
            @include mobile {
                font-size: 13px;
            }
        }
        dd {
            font-size: 22px;
            line-height: 32px;
            font-weight: bold;
            @include mobile {
                font-size: 20px;
                line-height: 29px;
            }
        }
    }
    .tb_row {
        table {
            thead th {
                padding: 16px 0;
                font-size: 14px;
                line-height: 20px;
                color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                @include mobile {
                    padding: 13px 0 12px;
                    font-size: 13px;
                    line-height: 19px;
                }
            }
            td {
                padding: 14px 0 12px;
                font-size: 13px;
                line-height: 19px;    
                color: #333;     
                @include mobile {
                    padding: 12px 0;
                    font-size: 12px;
                    line-height: 17px;
                }       
            }
        }
        + .bullet-list {
            margin-top: 8px;
            font-size: 14px;
            line-height: 20px;
            color: #666;
            @include mobile {
                font-size: 13px;
                line-height: 19px;
            }
            .b-txt {
                padding-left: 7px;
                &:before {
                    margin-top: 8px;
                    margin-left: -7px;
                    margin-right: 4px;
                    width: 3px;
                    height: 3px;
                    background: #666;
                    @include mobile {
                        width: 2px;
                        height: 2px;
                    }
                }
            }
        }
    }
    .rental-card-banner {
        margin-top: 24px; // BTOCSITE-60120 [구독 Task] Sales - 재구독
        @include mobile {
            margin-top: 16px;
        }
        .banner {
            &-link {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 16px;
                border-radius: 8px;
                background: #4A5664;
                @include mobile {
                    padding: 8px;
                }
                &:after {                    
                    content: "";
                    flex-shrink: 0;
                    display: block;
                    width: 16px;
                    height: 16px;
                    background: url(/lg5-common/images/icons/btn-arr-16-white.svg) no-repeat 0 0;
                }
            }
            &-txt {
                position: relative;
                display: flex;
                align-items: center;
                gap: 0 10px;
                font-size: 16px;
                line-height: 23px;
                color: #fff;
                @include mobile {
                    gap: 0 4px;
                    font-size: 12px;
                    line-height: 17px;
                }
                strong {
                    color: #fff627;
                }
                &:before {
                    content: "";
                    display: block;
                    width: 48px;
                    height: 48px;
                    background: url(/lg5-common/images/icons/icon-card-discount-96.png) no-repeat 0 0 / 100%;
                }
            }
            &-noti {
                position: relative;
                margin-top: 8px;
                padding-left: 18px;
                font-size: 14px;
                line-height: 20px;
                color: #666;
                @include mobile {
                    font-size: 12px;
                    line-height: 17px;
                }
                &:before {
                    content: "※";
                    position: absolute;
                    top: 1px;
                    left: 0;
                    display: block;
                    
                }
            }
        }
        & + .gray-box {
            margin-top: 32px;
        }
    }
}
// E : BTOCSITE-49721 UP가전 2.0 통합 장바구니 기능 구현

// S : BTOCSITE-35499 [정기 NPS조사] 개발 요청
.customer-popup {
    background: transparent;
    .pop-conts {
        padding: 48px 30px 60px;
        max-height: inherit;
        overflow: hidden;
        border-radius: 10px;
        background: #fff;

        .h-tit {
            font-size: 24px;
            line-height: 32px;
            font-weight: $font-weight-bold;
        }
        .text-cont {
            margin-top: 12px;
            font-size: 14px;
            line-height: 20px;
            word-break: keep-all;
        }
        .survey-list {
            margin: 0 40px;
            li {
                margin-top: 40px;
                padding-top: 32px;
                font-size: 14px;
                line-height: 20px;
                font-weight: $font-weight-bold;
                border-top: 1px solid #ddd;
                &:first-child {
                    padding-top: 0;
                    border-top: none;
                }
                &::before {
                    content: '';
                    margin: 0 auto 1px;
                    display: block;
                    width: 128px;
                    height: 84px;
                }
                &:nth-child(1)::before {
                    background: url('/lg5-common/images/MA/icon_purchasing.png') no-repeat 50% 0;
                }
                &:nth-child(2)::before {
                    background: url('/lg5-common/images/MA/icon_non-purchasing.png') no-repeat 50% 0;
                }
                .btn {
                    margin-top: 12px;
                    min-width: 220px;
                    height: 40px;
                    font-size: 14px;
                    line-height: 1;
                    font-weight: $font-weight-bold;
                    border: none;
                    background: #da0f47;
                }
                .btn:hover, .btn:focus, .btn:active {
                    background: #000;
                }
            }
        }
        @include mobile {
            padding: 32px 20px 60px;
            .text-cont {
                margin-top: 8px;
                padding: 0 20px;
                .only-pc {
                    display: none;
                }
            }
            .survey-list {
                margin: 0 8px;
                li {
                    margin-top: 32px;
                    padding-top: 20px;
                    &:first-child {
                        margin-top: 25px;
                    }
                    &::before {
                        width: 128px;
                        height: 84px;
                    }
                    &:nth-child(1)::before {
                        background: url('/lg5-common/images/MA/icon_purchasing-mo.png') no-repeat 50% 0 / 100% auto;
                    }
                    &:nth-child(2)::before {
                        background: url('/lg5-common/images/MA/icon_non-purchasing-mo.png') no-repeat 50% 0 / 100% auto;
                    }
                    .btn {
                        padding: 10px 20px;
                    }
                }

            }
        }
    }
    & > .btn-close {
        position: relative;
        top: inherit;
        right: inherit;
        margin-top: 14px;
        left: 100%;
        width: auto;
        height: auto;
        color: #fff;
        font-size: 14px;
        line-height: 20px;
        transform: translateX(-100%);
        &::before {
            display: none;
        }

        @include mobile {
            top: 0 !important;
            margin-top: 17px;
        }
    }
}
// E : BTOCSITE-35499 [정기 NPS조사] 개발 요청 

// S : BTOCSITE-34206 마이페이지 > (3) 배송 희망일 변경/ (4)배송지 주소 변경
#popup-detail-address {
    .pop-conts {
        @include pc {
            min-height: 351px; // BTOCSITE-62015 배송불가지역에 대한 주문서내 안내 팝업 노출
        }
    }
    .pop-conts {
        @include mobile {
            padding-top: 16px;
        }
        .tit {
            padding-top: 0;
            // S : BTOCSITE-62015 배송불가지역에 대한 주문서내 안내 팝업 노출
            @include pc {
                width: 28.5%;
            }
            // E : BTOCSITE-62015 배송불가지역에 대한 주문서내 안내 팝업 노출
        }
        .conts {
            // S : BTOCSITE-62015 배송불가지역에 대한 주문서내 안내 팝업 노출
            @include pc {
                width: 71.5%;
            }
            // E : BTOCSITE-62015 배송불가지역에 대한 주문서내 안내 팝업 노출
            .bullet-list {
                margin-top: 20px;
                @include mobile {
                    margin-top: 16px;
                }
                .b-txt {
                    color: #666;
                    // BTOCSITE-72970 건조기 이미용 주문 불가 처리
                    &:before {
                        background: #666;
                    }
                }
            }
        }
    }
    .btn.border {
        background: #fff;
    }
}

/*BTOCSITE-64497 배송경쟁력 강화 TASK_택배추가*/
/*BTOCSITE-74131 구독 '설치 희망일 변경' 기능 적용*/
#popup-delivery-date,
#popup-placement-date {
    .pop-conts {
        // min-height: 500px;
        min-height: 575px;
        padding-top: 16px;
        padding-bottom: 30px;
        @include mobile {
            min-height: auto;
        }
        .tit {
            padding-top: 0;
            .label {
                color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
            }
        }
        .conts {
            @include pc {
                width: 100%;
            }
            @include mobile {
                margin-top: 0;
            }
            .install-type {
                @include pc {
                    min-width: 561px;
                }
                .conts-box {
                    // padding: 40px 32px;
                    padding: 24px;
                    margin-top: 8px;
                    border: 1px solid #ddd;
                    border-radius: 6px;
                    background: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    @include mobile {
                        // padding: 24px 16px;
                        padding: 16px;
                    }
                    &:first-of-type {
                        margin-top: 0;
                        .item + .item {
                            margin-top: 16px;
                        }
                    }
                    .item {
                        @include mobile {
                            line-height: normal;
                        }
                        .model-wrap {
                            .model {
                                display: flex;
                                justify-content: space-between;
                                gap: 0 107px;
                                font-size: 16px;
                                line-height: 24px;
                                font-weight: 400;
                                color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                                @include mobile {
                                    gap: 0 10px;
                                    font-size:13px;
                                    line-height: 20px;
                                }
                                .name {
                                    text-overflow:ellipsis;
                                    white-space:nowrap;
                                    word-wrap:normal;
                                    overflow:hidden;
                                    // max-width: calc(100% - 134px);
                                    @include mobile {
                                        // max-width: calc(100% - 76px);
                                    }
                                    .sub {
                                        position: relative;
                                        padding-left: 18px;
                                        @include mobile {
                                            padding-left: 16px;
                                        }
                                        &:before {
                                            content: '·';
                                            position: absolute;
                                            top: 0;
                                            left: 4px;
                                            line-height: 24px;
                                            @include mobile {
                                                line-height: 19px;
                                            }
                                        }
                                    }
                                }
                                .sku {
                                    // color:#666;
                                    &:before {
                                        content: '';
                                        display: inline-block;
                                        margin: 0 12px;
                                        // width: 2px;
                                        // height: 20px;
                                        // background: #ddd;
                                        // vertical-align: middle;
                                        width: 1px;
                                        height: 12px;
                                        background: #000;
                                        @include mobile {
                                            // width: 1px;
                                            // height: 10px;
                                            // margin: 0 7px;
                                            margin: 0 11px;
                                            vertical-align: -1px;
                                        }
                                    }
                                }
                                & + .model {
                                    // margin-top: 6px;
                                    margin-top: 12px;
                                    @include mobile {
                                        // margin-top: 4px;
                                        margin-top: 10px;
                                    }
                                }
                            }
                            .install {
                                display: inline-flex;
                                align-items: center;
                                flex-shrink: 0;
                                height: 20px;
                                padding: 0 6px;
                                background: #ECF9F9; // BTOCSITE-77522 [웹앱 접근성 개선활동] 주문/청약_마이페이지_쇼핑관리
                                border-radius: 4px;
                                // font-size: 16px;
                                // font-weight: 500;
                                font-size: 12px;
                                font-weight: 400;
                                color: #007E82; // BTOCSITE-77522 [웹앱 접근성 개선활동] 주문/청약_마이페이지_쇼핑관리
                                @include mobile {
                                    // font-size:13px;
                                    // font-weight:400;
                                    font-size: 11px;
                                }
                            }
                        }
                        .input-wrap {
                            // margin-top: 12px;
                            width: calc(100% - 188px);
                            margin-top: 8px;
                            @include mobile {
                                width: 100%;
                            }
                            .box {
                                background: #fff;
                                border-radius: 8px;
                            }
                            &.disabled {
                                input:read-only {
                                    border-color: #ddd;
                                    & + .cell {
                                        border-color: #ddd;
                                    }
                                }
                                .err-block {
                                    display: block;
                                }
                            }
                        }
                        & + .item {
                            // margin-top: 32px;
                            margin-top: 12px;
                            // @include mobile {
                            //     margin-top: 24px;
                            // }
                        }
                        & + .text {
                            margin-top: 32px;
                            padding-top: 32px;
                            border-top: 1px solid #ddd;
                            @include mobile {
                                margin-top: 24px;
                                padding-top: 24px;
                            }
                        }
                    }
                    .text {
                        // margin-bottom: 25px;
                        margin-bottom: 16px;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                        color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                        @include mobile {
                            // margin-bottom: 16px;
                            margin-bottom: 8px;
                            font-size: 14px;
                            // font-weight: 500;
                            line-height: 20px;
                        }
                    }
                }
            }
            .mo-bullet-type.accordion-wrap {
                overflow:hidden;
                border-radius:8px;
                @include mobile {
                    margin-top: 8px;
                }
                > ul {
                    > .lists {
                        // S : BTOCSITE-62015 배송불가지역에 대한 주문서내 안내 팝업 노출
                        &.on {
                            box-shadow: none;
                        }
                        // E : BTOCSITE-62015 배송불가지역에 대한 주문서내 안내 팝업 노출
                        .head {
                            border-bottom: none;
                            .accord-btn {
                                display:none;
                            }
                        }
                        .accord-cont {
                            display:block !important;
                            padding:0;
                            margin-top:0;
                        }
                        .bullet-list {
                            margin:0;
                            &:not(:first-child) {
                                .b-tit {
                                    margin-top:24px;
                                }
                            }
                            .b-tit {
                                display:block;
                                margin:24px 0 16px;
                                line-height:normal;
                                font-size:14px;
                                font-weight:700;
                                color:#000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                            }
                            .b-txt {
                                line-height: normal;
                                color: #666; // BTOCSITE-66647 배송유의사항(사다리차) 항목 추가
                                &:not(:first-child) {
                                    margin-top: 2px; // BTOCSITE-66647 배송유의사항(사다리차) 항목 추가
                                }
                            }
                        }
                    }
                }
                @include mobile {
                    border:1px solid #ddd;
                    > ul {
                        > .lists {
                            padding: 0;
                            border: none;
                            z-index:auto;
                            &.on {
                                .head {
                                    .accord-btn {
                                        &:after{
                                            transform:rotate(-180deg);
                                        }
                                    }
                                }
                                .accord-cont {
                                    display:block !important;
                                }
                            }
                            .head {
                                .accord-btn {
                                    display:block;
                                    padding:11px 56px 11px 15px;
                                    background-color:#fff;
                                    line-height:0;
                                    .title {
                                        font-weight:500;
                                        font-size:14px;
                                        color:#000; // BTOCSITE-74131 구독 '설치 희망일 변경' 기능 적용
                                        line-height:1.46;
                                    }
                                    &:after {
                                        right:12px;
                                        width:16px;
                                        height:16px;
                                        margin-top:-8px;
                                        border:0;
                                        background:url(/lg5-common/images/icons/btn-down-16-gray.svg) no-repeat 0 0 / 100%;
                                        transition:none;
                                    }
                                }
                            }                                    
                            .accord-cont {
                                display:none !important;
                                padding:0 15px 15px;
                                .bullet-list {                                        
                                    .b-tit {
                                        margin:6px 0 8px;
                                        font-size: 13px;  // BTOCSITE-74131 구독 '설치 희망일 변경' 기능 적용
                                        font-weight: 500;
                                    }                                
                                    .b-txt {
                                        margin-top:4px;
                                        padding-left:10px;
                                        line-height: 1.5;
                                    }
                                    .b-txt:nth-child(n+2) {
                                        margin-top:8px;
                                    }
                                    ul + .b-tit {
                                        margin-top:16px;
                                    }                                        
                                }
                            }
                        }
                    }
                }
            }
        }
        .load-msg {
            display: none;
            // padding-top: 64px;
            padding: 60px 0 64px;
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            color: #666;
            @include mobile {
                padding-top: 44px;
                font-size: 13px;
                line-height: 20px;
            }
        }
        &.loading {
            .form-wrap {
                display: none;
            }
            .load-msg {
                display: block;
            }
            + .pop-footer {
                display: none;
            }
        }
        .bullet-list {
            line-height: 20px;
            .b-txt:before {
                margin-top: 9px;
                background: #666;
            }
        }
    }
    // BTOCSITE-75620 [주문] 장바구니/주문결제 유의사항 UI 변경요청 (pc 일부 팝업에 유의사항 타이틀 노출 케이스 수정)
    @include pc {
        .notice-info {
            .title {
                display: none;
            }
        }
    }
}
// E : BTOCSITE-34206 마이페이지 > (3) 배송 희망일 변경/ (4)배송지 주소 변경


// S : BTOCSITE-61348 [전시] 소모품 임직원 판매  
@mixin pc2 {
    @media screen and (min-width: 1025px) {
        @content;
    }
}
@mixin sharedPopupHeader {
    @include tablet {
        .pop-header {
            margin: 0;
            padding: 54px 20px 20px;
            border-bottom: 0;

            .tit {
                span {
                    font-size: 18px;
                }
            }
        }

        .pop-conts {
            padding: 32px 20px 60px;
        }

        .pop-footer {
            padding: 14px 20px;
        }

        .btn-close {
            top: 18px;
        }
    }
    @include pc2 {
        .pop-header {
            border-width: 1px;
            .tit span{
                font-size: 22px;
                line-height: 30px;
                height: 34px;
            }
        }
    }
}
.popup-wrap.popup-shared-model {
    @include sharedPopupHeader;
    @include pc2 {
        max-width: 444px;
        min-height: initial !important;
        z-index: 100;
    }
    &:not(.small) .pop-conts {
        padding-bottom: 60px;
        @include tablet {
            overflow: hidden;
            height: calc(100vh - 98px);
            max-height: calc(100vh - 98px);
        }
    }
    .pop-conts {
        padding: 10px 20px 60px;
        @include tablet {
            overflow: hidden;
            height: calc(100vh - 98px);
            max-height: calc(100vh - 98px);
        }
        @include pc2 {
            max-height: 706px;
            padding: 40px 40px 60px;
        }
    }
    .model-information {
        margin: 0 -4px;
        font-size: 16px;
        line-height: 1.63;
        @include tablet {
            height: 100%;
        }
        @include pc2 {
            max-width: 1460px;
            margin: 0;
        }
        .tit-wrap {
            margin-bottom: 20px;
            @include pc2 {
                display: flex;
                justify-content: space-between;
                margin-bottom: 28px;
            }
            & + .count {
                margin-top: 0;
            }
        }
        .count {
            margin-top: 10px;
            font-size: 13px;
            line-height: 19px;
            @include pc2 {
                margin-top: 0;
                font-size: 14px;
                line-height: 20px;
            }
            em {
                color: #ea1917;
            }
        }
        &-wrap {
            overflow-y: auto;
            max-height: calc(100% - 100px);
            margin-top: 20px;
            padding: 12px 16px;
            border: 1px solid #ddd;
            border-radius: 8px;
            @include tablet {
                @at-root #popupAlternative .model-information-wrap {
                    max-height: calc(100% - 48px);
                }
            }
            @include pc2 {
                margin-top: 16px;
                max-height: 492px;
                padding: 12px 24px;
                @at-root #popupAlternative .model-information-wrap {
                    max-height: 570px;
                }
            }
        }
        &-list {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            overflow: visible;
            li {
                flex-basis: 50%;
                margin: 0;
                padding: 4px 0;
                font-size: 13px;
                line-height: 19px;
                @include pc2 {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
        .input-wrap {
            flex: 1;
            width: 100%;
            padding-right: 36px;
            height: 40px;
            @include pc2 {
                height: 48px;
                margin: 0 -8px;
                padding-right: 48px;
            }
            input[type=text] {
                padding: 11px 32px;
                text-transform:uppercase;
                background: #f4f5f7;
                border-radius: 30px;
                border: none;
                font-size: 18px;
                @include tablet {
                    height: 40px;
                    padding: 8px 20px;
                    font-size: 15px;
                    line-height: 22px;
                }
                &::-webkit-input-placeholder,
                &::-moz-placeholder,
                &::-ms-input-placeholder {
                    color: #666;
                    font-size: 18px;
                    line-height: 26px;
                    @include tablet {
                        font-size: 15px;
                        line-height: 22px;
                    }
                }
            }
            .btn-delete {
                right: 50px;
                @include pc2 {
                    width: 24px;
                    height: 24px;
                    right: 68px;
                }
            }
        }
        .btn-search {
            width: 36px;
            background-size: 22px auto;
            @include tablet {
                height: 40px;
                background-position: 100% 50%;
            }
            @include pc2 {
                background-size: 32px auto;
                background-position: 100% 50%;
            }
        }
        .no-data {
            display: none;
            background:none;
            padding: 44px 0;
            font-size: 13px;
            line-height: 19px;
            @include tablet {
                padding:37px 0;
            }
        }
    }
}
// E : BTOCSITE-61348 [전시] 소모품 임직원 판매  

// S :  BTOCSITE-56491 [전시] 앱 다운로드 유도 배너 개발 요청
.appbanner-popup {
    background: transparent;
    &.popup-wrap.small {
        max-width: 316px;
        outline: none;
        border-radius: 0;
        @include mobile{
            top: auto !important;
            bottom: 0;
            margin-left: -226px;
            max-width: 452px;
            width: 100%;
            height: auto;
            min-height:  inherit !important;
            transform:  none !important;
        }
        @media screen and (max-width: 452px) {
            left: 0 !important;
            margin-left: 0;
        }
        .pop-conts {
            position: relative;
            padding: 0;
            border-radius: 10px;
            @include mobile {
                border-radius: 0;
            }
            .img-area {
                text-align: center;
                // @include mobile {
                //     padding-bottom: 56px;
                // }
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .title {
                position: absolute;
                top: 30px;
                padding: 0 10px;
                width: 100%;
                text-align: center;
                font-size: 18px;
                line-height: 1.1;
                @include mobile {
                    top: 24px;
                    font-size: 14px;
                }
                em {
                    display: block;
                    font-size: 48px;
                    font-weight: 700;
                    color: #BF2B4D;
                    letter-spacing: -2px;
                    @include mobile {
                        font-size: 36px;
                    }
                }
            }
            .btn-area {
                position: absolute;
                left: 50%;
                bottom: 20px;
                text-align: center;
                transform: translateX(-50%);
                @include mobile {
                    display: none;
                }
                .btn-down {
                    margin: auto;
                    padding: 0 23px;
                    display: inline-block;
                    height: 30px;
                    font-size: 11px;
                    line-height: 30px;
                    color: #BF2B4D;
                    border: 1px solid #BF2B4D;
                    &::after {
                        content: '';
                        margin-top: 4px;
                        margin-left: 6px;
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        vertical-align: top;
                        background: url('/lg5-common/images/icons/ic_download_red_32.svg') no-repeat 0 50%/100% auto;
                    }
                }
            }
           
        }

        .pop-footer {
            padding: 12px 0 0;
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            @include mobile {
                bottom: 0;
                padding: 16px;
                width: 100%;
                height: 56px;
                background-color: #fff;
            }
            .btn-text {
                margin-left: auto;
            }
            .btn-text, .btn-day-close {
                font-size: 14px;
                line-height: 20px;
                color: #fff;
                &:hover, &:focus {
                    color: #fff;
                    text-decoration: none;
                }
                @include mobile {         
                    font-size: 12px;
                    line-height: 24px;
                    color: #000;           
                    text-decoration: none;
                }
            }
        }
    }
}
// E : BTOCSITE-56491 [전시] 앱 다운로드 유도 배너 개발 요청
// S : BTOCSITE-47988 메인 히어로배너 전체보기 요청
#view-all-popup {
    .pop-conts {
        @include mobile {
            height:calc(100% - 62px);
            max-height:calc(100% - 62px);
        }
    }
    .main-pop {
        padding:15px 40px 36px;
        .banner-list {
            display:flex;
            flex-wrap:wrap;
            li {
                width:calc((100% - 24px)/ 2);
                margin-top:16px;
                &:nth-child(-n+2) {
                    margin-top:0;
                }
                &:nth-child(even) {
                    margin-left:24px;
                }
                a {
                    display:flex;
                    align-items:center;
                    width:100%;
                    .banner-img {
                        overflow:hidden;
                        width:105px;
                        height:105px; 
                        border-radius:6px;
                        margin-right:16px;
                        img {
                            width:100%;
                            height:100%;
                        }   
                    }
                    .banner-txt {
                        width:calc(100% - 121px);
                        .title {
                            font-size:14px;
                            line-height:20px;
                            font-weight:500;
                        }
                    }
                }
            }
        } 
        @include mobile {
            padding:16px 16px 30px;
            .banner-list {
                display:block;
                li {
                    width:100%;
                    &:nth-child(2) {
                        margin-top:16px;
                    }
                    &:nth-child(even) {
                        margin-left:0;
                    }
                }
            }
        }   
    }
}
// E : BTOCSITE-47988 메인 히어로배너 전체보기 요청

// S : BTOCSITE-75396 홈메인 팝업 구현 
.html-popup {
    background: transparent;
    &.popup-wrap {
        border-radius: 0;
        outline: none;
        @include mobile {
            top: 50% !important;
            left: 50% !important;
            max-width: 316px;
            height: auto;
            transform: translate(-50%, -50%) !important;
        }
        @media screen and (max-width: 280px) {
            padding: 0 22px;
        }
        &.btm-type {
            @include mobile {
                top: auto !important;
                left: 0 !important;
                bottom: 0 !important;
                padding: 0;
                transform: none !important;
                max-width: inherit;
                width: 100%;
            }
            .popup-body {
                @include mobile {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
            .pop-conts {
                @include mobile {
                    padding-bottom: 16px;
                    max-height: 178px;
                    height: auto;
                }
            }
            .pop-footer {
               @include mobile {
                    padding: 16px;
                    width: 100%;
                    background: #fff;
                    border-top: 1px solid #ddd;
               }
                input {
                    &:checked {
                        & + label {
                            @include mobile {
                                &:after {
                                    border: none;
                                    background-image: url('/lg5-common/images/icons/btn-checkbox-on_new.svg');
                                    background-size: 24px auto;
                                }
                            }
                        }
                    }
                    & + label {
                        @include mobile {
                            color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                        }
                    }
                }
                .btn-text {
                    @include mobile {
                        color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    }
                }
                
            }
        }
        .popup-body {
            padding-bottom: 16px;
            overflow: hidden;
            background: #fff;
            border-radius: 10px;
        }
        .pop-header {
            margin: 0;
            padding: 16px 16px 0;
            border: none;          
            .tit {
                display: flex;
                align-items: center;
                height: 64px;
                padding-bottom: 16px;
                border-bottom: 1px solid #ddd;
                span {
                    height: auto;
                    font-size: 16px;
                    line-height: 24px;
                    @include textEllipsisClamp(2);
                }
                em {
                    color: #EA1917;
                }
            }
        }
        .pop-conts {
            position: relative;
            padding: 20px 16px 20px;
            color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
            outline: none;
            @include mobile {
                max-height: 264px;
                height: 264px;
                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                }
                
                &::-webkit-scrollbar:vertical {
                    width: 6px;
                }
                
                &::-webkit-scrollbar:horizontal {
                    height: 6px;
                }
                
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(0, 0, 0, .3);
                    border-radius: 10px;
                    border: 1px solid #ffffff;
                }
                
                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: #ffffff;
                }
            }
            .h-tit {
                font-size: 14px;
                line-height: 20px;
                font-weight: 700;
                &:not(:first-child) {
                    margin-top: 16px;
                }
            }
            .bullet-text {
                margin-top: 2px;
                font-size: 13px;
                line-height: 20px;
                &::before {
                    margin-top: 8px;
                }
            }
            .desc {
                margin-top: 12px;
                font-size: 13px;
                line-height: 20px;
                color: #666;
            }
            .btn-area {
                margin-top: 20px;
                text-align: right;
                .btn-link {
                    font-size: 12px;
                    line-height: 18px;
                    text-decoration: none;
                    &:after {
                        top: 1px;
                        width: 16px;
                        height: 16px;
                        background-position: center center;
                    }
                }
            }
        }
        .pop-footer {
            padding: 8px 0 0;
            position: relative; // BTOCSITE-65935 홈메인 팝업 > 모바일에서 처음 뜰때 흔들리는 현상 수정
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            @media screen and (max-width: 280px) {
                width: calc(100% - 44px);
            }

            input {
                &:checked {
                    & + label {
                        font-weight: 700;
                        &::after {
                            background-color: #fff;
                            background-image:  url("/lg5-common/images/icons/ico-chk-black.svg");
                            background-size: 11px auto;
                        }
                    }
                }
                & + label {
                    padding-left: 26px;
                    font-size: 14px;
                    line-height: 20px;
                    color: #fff;
                    &::after {
                        top: 1px;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        background-image: none;
                        border: 1px solid #aaa;
                        border-radius: 3px;
                    }
                }
            }
            .btn-text {
                margin-left: auto;
                font-size: 14px;
                text-decoration: none;
                line-height: 24px;
                color: #fff;
                font-weight: 400;

                &:hover, &:focus {
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
    }
}
.img-popup {
    background: transparent;
    &.popup-wrap {
        max-width: 316px;
        outline: none;
        border-radius: 0;
        // @media screen and (max-width: 452px) {
        //     left: 0 !important;
        //     margin-left: 0;
        // }
        @include mobile{
            top: 50% !important;
            left: 50% !important;
            max-width: 316px;
            height: auto;
            transform: translate(-50%, -50%) !important;
        }        
        @media screen and (max-width: 280px) {
            padding: 0 22px;
        }
        // 바텀타임 
        &.btm-type {
            @include mobile {
                top: auto !important;
                bottom: 0;
                padding: 0;
                max-width: 452px;
                width: 100%;
                height: auto;
                overflow: visible;
                transform:  translateX(-50%) !important;
            }
            .pop-conts  {
                @include mobile {
                    border-radius: 10px 10px 0px 0px;
                }
            }
            .pop-footer {
                @include mobile {
                     padding: 16px;
                     width: 100%;
                     background: #fff;
                     border-top: 1px solid #ddd;
                }
                 input {
                    &:checked {
                        & + label {
                            @include mobile {
                                &:after {
                                    border: none;
                                    background-image: url('/lg5-common/images/icons/btn-checkbox-on_new.svg');
                                    background-size: 24px auto;
                                }
                            }
                        }
                    }
                     & + label {
                         @include mobile {
                             color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                         }
                     }
                 }
                 .btn-text {
                     @include mobile {
                         color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                     }
                 }
                 
             }
        }
        .pop-conts {
            position: relative;
            padding: 0;
            border-radius: 10px;
            .img-area {
                text-align: center;
                img {
                    width: 100%;
                    height: auto;
                }
            }           
        }
        .pop-footer {
            padding: 8px 0 0;
            position: relative; // BTOCSITE-65935 홈메인 팝업 > 모바일에서 처음 뜰때 흔들리는 현상 수정
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            
            @media screen and (max-width: 280px) {
                width: calc(100% - 44px);
            }

            input {
                &:checked {
                    & + label {
                        font-weight: 700;
                        &::after {
                            background-color: #fff;
                            background-image:  url("/lg5-common/images/icons/ico-chk-black.svg");
                            background-size: 11px auto;
                        }
                    }
                }
                & + label {
                    padding-left: 26px;
                    font-size: 14px;
                    line-height: 20px;
                    color: #fff;
                    &::after {
                        top: 1px;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        background-image: none;
                        border: 1px solid #aaa;
                        border-radius: 3px;
                    }
                }
            }
            .btn-text {
                margin-left: auto;
                font-size: 14px;
                text-decoration: none;
                line-height: 24px;
                color: #fff;
                font-weight: 400;

                &:hover, &:focus {
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
    }
}
// E : BTOCSITE-75396 홈메인 팝업 구현 

// S :  BTOCSITE-72970 건조기 이미용 주문 불가 처리'
.ui_modal_wrap:has(.popup-wrap--new-type),
.ui_modal_wrap:has(#popup-detail-address),
.ui_modal_wrap:has(#popup-delivery-date),
.ui_modal_wrap:has(#popup-placement-date) {
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup-wrap--new-type,
#popup-detail-address,
#popup-delivery-date,
#popup-placement-date {
    position: relative !important;
    transform: none !important;
    left: auto !important;
    top: auto !important;
    .pop-conts {
        @include mobile {
            max-height: 100%;
        }
    }
}
@media screen and (max-height: 815px) and (orientation: landscape) {
    .popup-wrap--new-type .pop-conts,
    #popup-detail-address .pop-conts,
    #popup-delivery-date .pop-conts,
    #popup-placement-date .pop-conts{
        max-height: calc(100vh - 150px);
        min-height: auto;
    }
}
.popup-undeliverable {
    @include mobile {
        min-height: auto!important;
        height: auto;
        left: auto !important;
        top: auto !important;
        transform: none !important;
        border-radius: 6px;
    }
    .pop-conts {
        padding-top: 15px;
        @include mobile {
            height: auto !important;
            padding: 15px 16px 30px !important;
        }
        .tit {
            display: block;
            text-align: center;
            line-height: 24px;
            @include mobile {
                text-align: left;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    .bullet-list {
        margin-top: 16px;
        line-height: 20px;
        color: #666;
        .b-txt {
            margin-top: 8px;
            &:first-child {
                margin-top: 0;
            }
            &:before {
                margin-top: 9px;
                background: #666;
            }
        }
    }
    .pop-footer {
        @include mobile {
            position: static !important;
        }
        .btn-group {
            .btn {
                border-radius: 6px;
            }
            .btn:only-child {
                min-width: 180px;
            }
        }
    }
}
// E :  BTOCSITE-72970 건조기 이미용 주문 불가 처리

// S : BTOCSITE-64497 배송경쟁력 강화 TASK _택배추가
.delivery-pop {
    position: relative !important;
    transform: none !important;
    left: auto !important;
    top: auto !important;
	&.popup-wrap {
		.pop-header {
            padding: 30px 0 10px;
            border-bottom: 1px solid #0f0f0f;
			.tit {
                color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
				span {
                    height: 26px;
					@include font(18px, 26px);
				}
			}
		}
		.pop-body {
			padding: 15px 40px 48px;
			overflow-y: auto;
			height: auto;
			max-height: 92%;
            .info-text {
                @include font(16px, 24px);
                font-weight: 500;
                color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
            }
			.delivery-area {
				display: flex;
				flex-direction: column;
				padding: 16px 24px;
				margin: 16px 0 8px;
				background-color: #f7f7f7; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                border: 1px solid #ddd;
				border-radius: 8px;
				.delivery-info {
					display: flex;
					justify-content: space-between;
                    @include font(16px, 24px);
                    .title {
                        color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
                    }
					.text {
                        font-weight: 500;
                        color: #008282;
                    }
					& + .delivery-info {
						margin-top: 8px;
					}
				}
			}
            .desc-text {
                @include font(14px, 20px);
                color: #666;
            }
			.notice-area {
                margin-top: 16px;
                padding-top: 16px;
                border-top: 1px solid #eee;
				& > .notice-title {
					@include font(16px, 24px);
                    font-weight: 500;
                    color: #000; // BTOCSITE-77855 [웹앱 접근성 개선활동] 웹접근성 공통 퍼블리싱 가이드
				}
				.notice-list {
                    margin-top: 16px;
                    & + .notice-list {
                        margin-top: 12px;
                    }
                    .title {
                        position: relative;
                        @include font(14px, 20px);
                        font-weight: 500;
                        color: #333;
                    }
					ul {
                        margin-top: 6px;
						li{
                            &:first-child{
                                margin-top: 0; 
                            }
                            position: relative;
                            margin-top: 4px;
							@include font(14px, 20px);
                            font-weight: 400;
                            padding-left: 12px;
							color: #666;
                            &::before {
                                content: "";
                                display: inline-block;
                                width: 3px;
                                height: 3px;
                                border-radius: 50%;
                                background-color: #666;
                                position: absolute;
                                top: 9px;
                                left: 0;
                            }
						}
					}
				}
			}
		}
        & > .btn-close {
            top: 30px;
        }
		@include mobile {
            .pop-body {
                width:100%;
                padding: 15px 16px 16px;
                max-height: 88%;
                .info-text {
                    @include font(14px, 20px);
                }
                .delivery-area {
                    margin-top: 24px;
                    padding: 16px;
                    .delivery-info {
                        @include font(13px, 20px);
                        & + .delivery-info {
                            margin-top: 6px;
                        }
                    }
                }
                .desc-text {
                    @include font(11px, 15px);
                }
                .notice-area {
                    margin-top: 24px;
                    padding-top: 24px;
                    .notice-title {
                        @include font(14px, 20px);
                    }
                    .notice-list {
                        margin-top: 12px;
                        .title {
                            @include font(13px, 20px);
                        }
                        ul {
                            margin-top: 4px;
                            li{
                                &:first-child{
                                    margin-top: 0; 
                                }
                                margin-top: 2px;
                                padding-left: 11px;
                                @include font(13px, 20px);
                            }
                        }
                    }
                }
            }
		}
	}
}
// E : BTOCSITE-64497 배송경쟁력 강화 TASK _택배추가

.popup-terms-new-type2 {
    .rmsf-pop {
        &:has(.input-info) {
            overflow: hidden;
            max-height: 100%;
            padding-top: 8px;
            @include mobile {
                padding-bottom: 8px;
            }
            .input-info {
                overflow-y: auto;
                overflow-x: hidden;
                max-height: 645px;
                padding: 16px;
                background: #f9f9f9;
                border: 1px solid #eaeaea;
                border-radius: 8px;
                @include pc {
                    /* 스크롤바의 폭 너비 */
                    &::-webkit-scrollbar {
                        width: 16px;
                        background-color:transparent;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #d9d9d9;
                        border-radius: 100px;
                        border: 5px solid #f9f9f9;
                    }
                }
                @include mobile {
                    max-height: 100%;
                }
                .txt-cont,
                .txt_point {
                    font-size: 14px;
                    line-height: 20px;
                    @include mobile {
                        font-size: 11px;
                        line-height: 15px;
                    }
                    .tg td {
                        vertical-align: middle !important;
                    }
                }
            }
        }
    }
}