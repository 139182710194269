html {
    @include mobile {
        overflow-x:hidden; //BTOCSITE 1814건 결함 수정
    }
}
// 스킵 네비게이션

.skip-content {
    a {
        overflow: hidden;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
        width: 0;
        height: 0;
        background: $color-default;
        @include font-medium;
        color: $color-white;
        text-align: center;
        &:focus {
            position: relative;
            width: 100%;
            height: auto;
            padding: 15px 0;
        }
    }
}
// 자바스크립트 동작 안됨 안내
.non-javascript {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    padding: 15px 0;
    background: $color-default;
    @include font-medium;
    color: $color-white;
    text-align: center;
}

@include pc {
    .header-wrap .mobile-only,
    .nav .category-home,
    .mobile-nav-wrap,
    .mobile-nav-button,
    .login-mobile,
    .nav-logout,
    .shortcut-service-link,
    .mobile-nav-banner {
        display: none;
    }
}

@include mobile {
    .header-bottom {
        display: none;
    }
}

.scroll-fixed {
    overflow: hidden;
}

.header {
    position:relative;
    @include mobile {
        border-bottom: 0;
        margin-bottom: 0px;
    }
}

@include mobile {
    .mobile-nav-wrap {
        position:relative;
        background: #fff;
        font-size: 0;

        // BTOCSITE-1814 이벤트탭 추가
        &.mainNav {
            background:#fff url('/lg5-common/images/icons/main-nav-bg.gif') repeat-x 0 bottom;
            &:before,
            &:after {
                position: absolute;
                top: 1px;
                z-index: 1;
                width: 40px;
                height: 100%;
                content: '';
                display:none;

            }
            &:before {
                left: 0;
                background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff);
            }
            &:after {
                right: 0;
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff);
            }
            &.left:before,
            &.right:after {
                display:block;
            }
        }

        .nav {

            height:37px;
            // overflow:hidden;
            white-space: nowrap;
            // display: table;
            display:flex;
            // border-bottom: 1px solid $color-border;
            > li {
                display: inline-block;
                border-top: 0;
                margin: 0 10px;
                &:first-child {
                    margin-left:18px;
                }
                &.icon-update {
                    .nav-item {
                        &:before {
                            position: absolute;
                            top: 6px;
                            right: -6px; //BTOCSITE-4602
                            width: 4px;
                            height: 4px;
                            border-radius: 2px;
                            background-color: $color-primary;
                            content: '';
                        }
                    }
                }
            }
            .nav-item {
                padding: 6px 0 8px;
                font-weight: 500;
                @include font($font-medium - 1,23px);
                color: $color-gray-1;
                &:after {
                    display: none;
                }
                &.on {
                    position: relative;
                    font-weight: 700;
                    color: $color-default;
                    &:after {
                        display: block;
                        position: absolute;
                        top: auto;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        width: 100%;
                        height: 3px;
                        background-color: $color-default;
                        transform: rotate(0);
                    }
                }
            }
        }
        .btn-back {
            position: relative;
            display: inline-block;
            min-height: 34px;
            padding: 6px 0 6px 36px;
            background: url('/lg5-common/images/icons/icon_back_new.svg') no-repeat left 16px center;  // BTOCSITE-85601 GNB 공통영역 아이콘 통일화 
            background-size: 16px;
            vertical-align: top;
            .text {
                @include font(14px,22px);
            }
        }
        &.is-depth {
            position: relative;
            text-align: center;
            .btn-back {
                position: absolute;
                top: 0;
                left: 0;
                min-height: 40px;
                padding: 9px 0 15px 36px;
                background-position:  left 16px top 9px;
            }
            .nav-item {
                position: relative;
                display: inline-block;
                padding: 4px 0 12px;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                &:after {
                    position: absolute;
                    top: 50%;
                    right: -20px;
                    width: 16px;
                    height: 16px;
                    margin-top: -12px;
                    background: url('/lg5-common/images/icons/icon_back_new.svg') no-repeat left center;   // BTOCSITE-85601 GNB 공통영역 아이콘 통일화 
                    background-size: 100% 100%;
                    transform: rotate(-90deg);
                    transition: all ease 200ms;
                    vertical-align: bottom;
                    content: '';
                }
                &.on {
                    &:after {
                        transform: rotate(90deg);
                    }
                }
            }
            .nav-category-container {
                position: absolute;
                top: 34px;
                right: 0;
                left: 0;
                z-index: 100;
                padding: 4px 0 6px;
                background-color: #fff;
                box-shadow: 0 4px 8px 0 rgba(0,0,0,.08);
                text-align: left;
                > ul {
                    > li {
                        display: block;
                        a {
                            display: block;
                            padding: 12px 16px;
                            font-size: 15px;
                            line-height: 23px;
                        }
                    }
                }
            }
        }
        &.sub-main {
            position: relative;
            text-align: center;
            .btn-back {
                position: absolute;
                top: 0;
                left: 0;
                min-height: 40px;
                padding: 9px 0 15px 36px;
                background-position:  left 16px top 9px;
            }
            .nav-item {
                display: inline-block;
                padding: 4px 0 12px;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
            }
        }
        //  BTOCSITE-71915 [스토리개편] 홈메인 전시개편         
        .nav-story-wrap {
            position: absolute;
            display: none;
            top: 50%;
            right: -36px;
            transform: translateY(-50%);
            // &:after {
            //     content: '';
            //     position: absolute;
            //     top: 50%;
            //     right: 0;
            //     display: block;
            //     width: 1px;
            //     height: 16px;
            //     background-color: #ddd;
            //     transform: translateY(-50%);
            // }
            .btn-story {
                padding: 0 12px;
                display: inline-flex;
                align-items: center;
                min-width: 69px;
                height: 32px;
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                color: #0f0f0f;
                border: 1px solid #0F0F0F;
                border-radius: 20px;
                @include mobile {
                    font-size: 12px;
                    line-height: 18px;
                }
                // &::before {
                //     content: '';
                //     margin-right: 2px;
                //     display: inline-block;
                //     width: 16px;
                //     height: 16px;
                //     background: url('/lg5-common/images/icons/icon_storyhome.svg') no-repeat 50% 50% / 100% auto;
                // }
            }
        }
    }
}

//@include mobile { //스크롤시 스티키 확인 후 삭제
//    .mobile-sticky-on {
//        position: fixed;
//        top: 0;
//        right: 0;
//        left: 0;
//        z-index: 1;
//        background-color: #fff;
//        .logo {
//            display: none;
//        }
//        .utility,
//        & + .mobile-nav-button {
//            position: fixed;
//            z-index: 2;
//        }
//    }
//}

.logo {
    float: left;
    padding-left: 42px;
    // S :  BTOCSITE-48637 B2B 복지몰(제휴사) 전시 구축
    display: flex;
    align-items: center;
    a {
        display: block;
        width: auto;
        height: 30px;
        // background-image: url("/lg5-common/images/header/lg_logo.svg");
        // background-size:100% 100%;
        
        &:not(:first-child) {
            position: relative;
            margin-left: 16px;
            padding-left: 17px;
            width: auto;
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                display: block;
                width: 1px;
                height: 20px;
                background: #ddd;
                transform: translateY(-50%);
            }
        }
            
        img {
            width: auto;
            height: 100%;
            vertical-align: top;
        }

        &.bi-logo {
            width: auto;
            height: 20px;
            background: none;
            &.symbol {
                height: 30px;
            }
        }        
        &.none {
            display: none;
            & + a {
                margin-left: 0;
                padding-left: 0;
                &::before {
                    display: none;
                }
            }
        }
        // E :  BTOCSITE-48637 B2B 복지몰(제휴사) 전시 구축
    }

    @include mobile {
        float: none;
        padding-top: 0;
        padding-left: 0;
        // S :  BTOCSITE-48637 B2B 복지몰(제휴사) 전시 구축
        display: flex;
        a {
            width: auto;
            height: 24px;
            // background-image: url("/lg5-common/images/header/lg_logo-m.svg");            
            &:not(:first-child) {
                margin-left: 8px;
                padding-left: 9px;
                &::before {
                    height: 16px;
                }
            }
            &.bi-logo {
                height: 16px;
                &.symbol {
                    height: 24px;
                }
            }
            &.none {
                display: none;
                & + a {
                    margin-left: 0;
                    padding-left: 0;
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 319px) {
        a:not(:first-child) {
            margin-left: 0;
            padding-left: 0;
            &::before {
                display: none;
            }
        }
        .bi-logo {
            display: none;

        }
    }
    // E :  BTOCSITE-48637 B2B 복지몰(제휴사) 전시 구축
}

.utility {
    float: right;
    padding-right: 150px; //247px; BTOCSITE-15539
    display:flex; // 회사소개 탑메뉴 추가
    @media screen and (min-width: 1760px) {
        padding-right: 150px; //247px; BTOCSITE-15539
    }
    .shortcut {
        font-size: 0;
        > li {
            position: relative;
            display: inline-block;
            width: 24px;
            height: 24px;
            background: no-repeat center center;
            background-size: 24px 24px;
            &:not(:first-child) {
                margin-left: 16px;
            }
            a {
                display: block;
                height: 100%;
            }
            &.search {
                background-image: url("/lg5-common/images/icons/icon_search_new.svg");   // BTOCSITE-85601 GNB 공통영역 아이콘 통일화 // BTOCSITE-44601 [전시] 아이콘2.0 변경 건
            }
            &.home {
                display: none;
                background-image: url("/lg5-common/images/icons/icon_home_new.svg");  //  BTOCSITE-85601 GNB 공통영역 아이콘 통일화
            }
            &.share {
                display: none;
                background-image: url("/lg5-common/images/icons/icon_share_new.svg"); // BTOCSITE-85601 GNB 공통영역 아이콘 통일화 
            }
            &.mypage {
                background-image: url("/lg5-common/images/icons/icon_my_new.svg");   //  BTOCSITE-85601 GNB 공통영역 아이콘 통일화 // BTOCSITE-44601 [전시] 아이콘2.0 변경 건
                &.after-login {
                    display: none; // BTOCSITE-47324 .mypage 기본 노출로 유지하고 .after-login 만 none 처리
                    position: relative;
                    &:after {
                        position: absolute;
                        top: 0;
                        right: 2px;
                        width: 4px;
                        height: 4px;
                        border-radius: 2px;
                        background-color: $color-primary;
                        content: '';
                    }
                    .mypage-layer {
                        .mypage-layer-inner {
                            min-width: 157px;
                        }
                    }
                }
                .mypage-layer {
                    display: none;
                    position: absolute;
                    top: 24px;
                    left: 50%;
                    z-index: 104;
                    transform: translateX(-50%);
                    padding-top: 14px;
                    .mypage-layer-inner {
                        min-width: 138px;
                        padding: 4px 0;
                        border-radius: 8px;
                        background-color: #fff;
                        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
                        text-align: center;
                        a {
                            display: block;
                            padding: 8px 0;
                            @include font-small;
                            &:hover,
                            &:focus {
                                border-radius: 4px;
                                background-color: $color-bg;
                            }
                        }
                        .mypage-shortcut {
                            border-top: 1px solid $color-border;
                        }
                    }
                }
            }
            &.cart {
                background-image: url("/lg5-common/images/icons/icon_cart_new.svg"); // BTOCSITE-85601 GNB 공통영역 아이콘 통일화 // BTOCSITE-44601 [전시] 아이콘2.0 변경 건
            }
            &.notice {
                background-image: url("/lg5-common/images/icons/icon-notice-20.svg");
            }
            .count {
                position: absolute;
                top: -5px;
                left: 10px;
                display: block;
                min-width: 14px;
                height: 14px;
                padding: 2px 4px;
                border-radius: 8px;
                background-color: $color-primary;
                font-weight: 700;
                font-size: 10px;
                line-height: 10px;
                color: $color-white;
                text-align: center;
            }
        }
    }
    // 회사소개 탑메뉴 //BTOCSITE-19196 브랜드관 구축(브랜드 홈)
    .about-company, .brand-home {
        position: relative;
        margin-left: 24px;
        &:before {
            display: inline-block;
            width: 1px;
            height: 14px;
            margin-right: 24px;
            background-color: #ddd;
            content: '';
        }
        @include mobile {
            display: none;
        }
        > a {
            font-size: 14px;
            line-height: 24px;
        }
        // 회사소개 탑메뉴 추가 START
        .about-company-layer {
            display: none;
            position: absolute;
            top: 20px;
            left: -6px;
            max-width: 125px;
            padding-top: 12px;
            z-index: 104;
            > ul {
                padding: 4px 0;
                border-radius: 4px;
                background-color: #fff;
                box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
                li {
                    > a {
                        display: block;
                        padding: 8px 24px;
                        font-size: 14px;
                        line-height:24px;
                        text-align: center;
                        &:hover {
                            background-color:#f4f4f4
                        }
                    }
                    > a.out_link {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-right: 20px;
                        i.go_out {
                            display: block;
                            width: 16px;
                            height: 16px;
                            margin-left: 4px;
                            background: url(/lg5-common/images/icons/btn-outlink-16.svg) no-repeat 50%;
                            background-size: 14px 14px
                        }
                    }
                }
            }
        }
        // 회사소개 탑메뉴 end
    }

    @include mobile {
        float: none;
        padding-right: 0;
        position: absolute;
        top: 6px;
        right: 11px;
        z-index: 1;
        .shortcut {
            > li {
                width: 34px;
                height: 34px;
                &:not(:first-child) {
                    margin-left: 6px;
                }
                > a {
                    padding: 5px;
                }
                .count {
                    top: 1px;
                    left: 15px;
                }
                &.mypage {
                    display: none;
                }
            }
        }
    }
}

// Out Link
.nav-outer-link {
    @include pc {
        position: absolute;
        top: 19px;
        right: 30px;
        margin-right: 0;
        font-size: 0;
        &:before {
            display: inline-block;
            width: 1px;
            height: 14px;
            margin-left: 24px;
            background-color: $color-border;
            content: '';
        }
    }
    @media screen and (min-width: 1760px) {
        right: 50%;
        margin-right: -852px;
    }

    .links {
        display: inline-block;
        li {
            display: inline-block;
            margin-left: 24px;
            a {
                position: relative;
                padding-right: 18px;
                @include font-small;
                background: url('/lg5-common/images/icons/btn-outlink-16.svg') no-repeat right top 3px;

                // S : BTOCSITE-44085 모바일 햄버거메뉴 하단 > 패밀리사이트> 베스트샵 제거
                @include pc {
                    &.praL {
                        display: none; // BTOCSITE-15539
                    }    
                }
                // E : BTOCSITE-44085 모바일 햄버거메뉴 하단 > 패밀리사이트> 베스트샵 제거
            }
        }
    }
    @include pc {
        .links {
            &.mobile {
                display:none;
            }
        }
    }

    @include mobile {
        padding: 32px 16px;
        border-top: 12px solid $color-bg;
        .links {
            width: 100%;
            font-size: 0;
            li {
                width: 50%;
                margin-left: 0;
                a {
                    font-weight: 500;
                }
            }
            & + .links {
                padding-top:18px;
            }
        }
    }
}

.nav-wrap {

    overflow: hidden;
    font-size: 0;
    white-space: nowrap;
    padding-left: 42px; /* BTOCSITE-1937 스프레드 메뉴 수정 */

    /* BTOCSITE-1937 스프레드 메뉴 수정 */
    @include pc {
        &.is-horizon-scroll {
            padding-left:62px;
        }
    }

    @include mobile {
        margin-top: 64px; //BTOCSITE-24121 추가
        padding-left: 0;
        isolation: isolate;
        ul.nav {
            display:block;
        }
        //s : BTOCSITE-24121 추가
        .btn-emp {
            padding: 12px 16px;
            &>a{
                display: block;
                padding: 0 16px;
                font-size: 14px;
                font-weight: 700;
                background: #f6f6f6;
                line-height: 69px;
                border-radius: 8px;
                & > span {
                    position: relative;
                    padding-right: 18px;
                    &:after {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        content: '';
                        display: block;
                        width: 12px;
                        height: 12px;
                        margin-top: -6px;
                        background: url('/lg5-common/images/icons/btn-arr-16-black.svg') no-repeat 0 0;
                        background-size: 100%;
                    }
                    & > strong {
                        color: #da0f47;
                    }
                }
            }
        }
        //e : BTOCSITE-24121 추가
    }
}
.nav-brand-gate {
    display: inline-block;
    vertical-align: top;
    margin-left:24px; /* BTOCSITE-1937 스프레드 메뉴 수정 */
    @include pc {
        .title {
            display: none;
        }
    }
    @include mobile {
        margin-left:0; /* BTOCSITE-1937 스프레드 메뉴 수정 */
        border-bottom: 13px solid #f4f4f4 !important;
    }

    .brand-link {
        display: flex;
        align-items: center;
        height: 34px;
        padding: 8px 16px;
        border-radius: 18px;
        background-color: $color-bg;
        font-size: 0;
        li {
            display: inline-block;
            margin-left: 16px;
            vertical-align: middle;
            &:first-child {
                margin-left: 0;
            }
            a {
                display: block;
                background-color: $color-bg;
            }
            span {
                font-weight: 700;
                @include font(14px,1);
            }
        }
        &.text {
            padding: 5px 16px;
            @include font-small;
            font-weight: 700;
        }
    }

    @include mobile {
        display: block;
        padding: 24px 16px;
        border-bottom: 12px solid $color-bg;
        .title {
            font-weight: 500;
            font-size: $font-small - 1;
            line-height: 21px;
        }
        .brand-link {
            height: auto;
            margin-top: 12px;
            padding: 0;
            border-radius: 0;
            background-color: transparent;
            li {
                flex: auto;
                margin-left: 5px;
                text-align: center;
                a {
                    padding: 11px 0;
                    border-radius: 100px;
                }
                img {
                    height: 18px;
                }
                span {
                    line-height: 18px;
                }
            }
            &.text {
                padding: 0;
                li {
                    a {
                        padding: 9px 0 10px;
                    }
                }
            }
        }
    }
}
.marketing-link{
    @include pc {
        display: none;
    }
    // padding: 24px 0; //BTOCSITE-10816 GNB 수정 컨텐츠 수정
    border-bottom: 13px solid $color-bg;
    .ui_carousel_list {
        padding: 0 16px;
        background-color: #f4f4f4; //BTOCSITE-10816 GNB 수정 컨텐츠 수정
    }
    .ui_carousel_slide {
        padding-right: 12px;
    }
    .banner-box {
        position: relative;
        display: inline-block;
        width: 60vw;

        //BTOCSITE-10816 GNB 수정 컨텐츠 수정
        border-radius: 8px;
        overflow: hidden;
        // height: 112px;
        img {
            width: 100%;
            //BTOCSITE-10816 GNB 수정 컨텐츠 수정
            border-radius: 8px;
            // position: absolute;
            // top: 0;
            // left: 0;
        }
        .mob-navSlideBan {
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center center;
            height: 21.903520208604952vw;
            min-height: 114px;
            // width: 100%;
            @media screen and (max-width: 500px) {
                background-size: cover;
                background-position: 100% center;
            }
        }
        .banner-text {
            position: absolute;
            top: 14px;
            left: 0; //220119 BTOCSITE-10816 수정
            margin-left:12px; //220119 BTOCSITE-10816 추가
            font-weight: bold; //220119 BTOCSITE-10816 추가
            font-size: $font-medium - 1;
            line-height: 23px;
            //color: $color-white; //삭제됨
            //text-shadow: 0 1px 20px rgba(0, 0, 0, 0.4); //220119 BTOCSITE-10816 제거
            width: 60%;
            white-space: normal;
            p {
                &.text {
                    position: relative;
                    word-break: keep-all;
                }
            }
        }
    }
}
.nav {

    @include pc {
        display: inline-block;

        //BTOCSITE-7335 : gnb mobile nav 최초 display none 처리
        &.mo-nav {
            display: none;
        }

        > li {
            display: inline-block;
            padding-left: 24px;
            vertical-align: top;
            /* BTOCSITE-1937 스프레드 메뉴 수정 */
            &:first-child {
                padding-left:0;
            }
            &:last-child {
                padding-right: 0;
            }
            &.icon-update {
                .nav-item {
                    &:before {
                        position: absolute;
                        top: 5px;
                        right: -5px;
                        width: 4px;
                        height: 4px;
                        border-radius: 2px;
                        background-color: #da0f47;
                        content: '';
                    }
                }
            }
            &.event-link {
                display: none;
            }
        }

        .nav-category {
            &.active {
                margin-left: 8px;
                padding: 0 16px;
                border-radius: 18px;
                background-color: $color-bg;
            }
            @include pc {
                &:hover,
                &:focus,
                &.active {
                    .nav-item {
                        &:after {
                            right: 21px;
                        }
                    }
                }
            }
        }
    }

    .nav-item {
        position: relative;
        display: block;
        padding: 4px 0 12px; /* BTOCSITE-1937 스프레드 메뉴 수정 */
        font-weight: 500; /* BTOCSITE-1937 스프레드 메뉴 수정 */
        font-size: $font-medium;
        line-height: 24px; /* BTOCSITE-1937 스프레드 메뉴 수정 */
        vertical-align: top;

        .icon-nav {
            position: relative;
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-left: 2px;
            background: url('/lg5-common/images/icons/btn-plus-16-round.svg') no-repeat 0 0;
            transition: all ease 500ms;
            vertical-align: -1px;
        }

        @include pc {
            &:hover,
            &:focus,
            &.active {
                font-weight: 700;
                /* BTOCSITE-1937 스프레드 메뉴 수정 : 삭제 */
                /* @include text-underline(7px,$color-default); */
                /* BTOCSITE-1937 스프레드 메뉴 수정 : 삭제*/
                &:after {
                    content:"";
                    position: absolute;
                    bottom:0;
                    left:0;
                    width:100%;
                    height:4px;
                    background: #000;
                }
                .icon-nav {
                    transform: rotate(135deg);
                }
            }

            &.active {
                display: inline-block;
                & + .nav-category-container,
                & + .nav-category-layer {
                    display: inline-block;
                }
            }
        }
    }

    @include mobile {
        //BTOCSITE-7335
        &.mo-nav {
            display: block;
        }
        > li {
            border-top: 2px solid $color-bg;
            &:first-child {
                border-top: 0;
            }

            &.on {
                .nav-item {
                    display: block;
                    & + .nav-category-container,
                    & + .nav-category-layer {
                        display: block;
                    }

                    &:after {
                        transform: rotate(0);
                    }
                }
            }
        }
        /* BTOCSITE-35717 베스트랭킹 개편(게이트웨이 배너) */
        .gateway-banner, .subscribe-banner{
            margin:-4px 0 28px;
            width:100%;
            height:40px;
            background:#fdf2ff;
            border-radius:8px;
            .btn-link{
                display:block;
                padding:10px 13px 10px 16px;
                font-weight:700;
                font-size:14px;
                line-height:20px;
                text-decoration:none;
                &:after{
                    right:auto;
                    top:50%;
                    transform:translateY(-50%);
                    margin-left:5px;
                    width:12px;
                    height:12px;
                    background-size:100% 100%;
                }
                >span{
                    color:$color-primary;
                }
            }
        }
        .subscribe-banner{
            margin-top:14px;
            background:#f2f4f5;
        }
        /* //BTOCSITE-35717 베스트랭킹 개편(게이트웨이 배너) */
        .nav-item {
            position: relative;
            padding: 20px 16px;
            font-weight: 700;
            line-height: 24px;

            &:after {
                position: absolute;
                @include calc(top,50%,12px);
                right: 16px;
                width: 24px;
                height: 24px;
                background: url('/lg5-common/images/icons/btn-arr-48-black.svg') no-repeat 0 0;
                background-size: 24px 24px;
                transform: rotate(180deg);
                transition: all ease 500ms;
                content: '';
            }

            .icon-nav {
                display: none;
            }
        }
        // 서브메인 바로가기
        .category-home {
            border-top: 1px solid $color-bg;
            .super-category-item,
            .category-item {
                display: block;
                padding: 14px 16px;
                font-weight: 500;
                @include font($font-small,22px);
            }
            .super-category-item {
                &:after {
                    display: none;
                }
            }
        }
    }
}

.nav-category-container {
    display: none;
    @include pc {
        > ul {
            @include clearfix;
            padding-left: 5px;
            > li {
                display: inline-block;
            }
        }

        .super-category-item {
            &:hover,
            &:focus,
            &.active {
                font-weight: 500;
                color: $color-default;
            }
        }
    }

    .super-category-item {
        display: block;
        position: relative;
        padding: 5px 0 5px 12px;
        font-size: 14px;
        line-height: 24px;
        color: $color-gray-1;
        @include mobile {
            line-height: 22px;
        }
        &.active {
            & + .nav-category-layer {
                display: block;
            }
        }
    }
}

.header-wrap:not(.type-new){
    max-width: (1680px + 80px);
    margin: 0 auto;
    /* padding-bottom: 16px; /* BTOCSITE-1937 스프레드 메뉴 수정 */
    @include mobile {
        max-width: 100%;
        padding: 0 16px;
    }
    .dimmed {
        display: none;
        position: fixed;
        top: 126px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 101;
        background-color: rgba(0,0,0,.56);
        content: '';
        @include mobile {
            top: 0;
            z-index: 106;
        }
    }
    
    .header-top {
        @include clearfix;
        padding: 18px 0;
    
        @include mobile {
            padding: 10px 0 11px;
        }
    }  

    .logo {
        float: left;
        padding-left: 42px;
        // S :  BTOCSITE-48637 B2B 복지몰(제휴사) 전시 구축
        display: flex;
        align-items: center;
        a {
            display: block;
            width: auto;
            height: 30px;
            // background-image: url("/lg5-common/images/header/lg_logo.svg");
            // background-size:100% 100%;
            
            &:not(:first-child) {
                position: relative;
                margin-left: 16px;
                padding-left: 17px;
                width: auto;
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    display: block;
                    width: 1px;
                    height: 20px;
                    background: #ddd;
                    transform: translateY(-50%);
                }
            }
                
            img {
                width: auto;
                height: 100%;
                vertical-align: top;
            }

            &.bi-logo {
                width: auto;
                height: 20px;
                background: none;
                &.symbol {
                    height: 30px;
                }
            }        
            &.none {
                display: none;
                & + a {
                    margin-left: 0;
                    padding-left: 0;
                    &::before {
                        display: none;
                    }
                }
            }
            // E :  BTOCSITE-48637 B2B 복지몰(제휴사) 전시 구축
        }
    
        @include mobile {
            float: none;
            padding-top: 0;
            padding-left: 0;
            // S :  BTOCSITE-48637 B2B 복지몰(제휴사) 전시 구축
            display: flex;
            a {
                width: auto;
                height: 24px;
                // background-image: url("/lg5-common/images/header/lg_logo-m.svg");            
                &:not(:first-child) {
                    margin-left: 8px;
                    padding-left: 9px;
                    &::before {
                        height: 16px;
                    }
                }
                &.bi-logo {
                    height: 16px;
                    &.symbol {
                        height: 24px;
                    }
                }
                &.none {
                    display: none;
                    & + a {
                        margin-left: 0;
                        padding-left: 0;
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 319px) {
            a:not(:first-child) {
                margin-left: 0;
                padding-left: 0;
                &::before {
                    display: none;
                }
            }
            .bi-logo {
                display: none;

            }
        }
        // E :  BTOCSITE-48637 B2B 복지몰(제휴사) 전시 구축
    }
    
    .utility {
        float: right;
        padding-right: 52px; //247px; BTOCSITE-15539 // 150px > 82px BTOCSITE-48265 [전시] B2B OBS 구축
        display:flex; // 회사소개 탑메뉴 추가
        @media screen and (min-width: 1760px) {
            // padding-right: 150px; //247px; BTOCSITE-15539
        }
        .shortcut {
            font-size: 0;
            > li {
                position: relative;
                display: inline-block;
                width: 24px;
                height: 24px;
                background: no-repeat center center;
                background-size: 24px 24px;
                &:not(:first-child) {
                    margin-left: 16px;
                }
                a {
                    display: block;
                    height: 100%;
                }
                &.search {
                    background-image: url("/lg5-common/images/icons/icon_search_new.svg");   // BTOCSITE-85601 GNB 공통영역 아이콘 통일화  // BTOCSITE-44601 [전시] 아이콘2.0 변경 건
                }
                &.home {
                    display: none;
                    background-image: url("/lg5-common/images/icons/icon_home_new.svg");  //  BTOCSITE-85601 GNB 공통영역 아이콘 통일화
                }
                &.share {
                    display: none;
                    background-image: url("/lg5-common/images/icons/icon_share_new.svg"); // BTOCSITE-85601 GNB 공통영역 아이콘 통일화 
                }
                &.mypage {
                    //display: none;
                    background-image: url("/lg5-common/images/icons/icon_my_new.svg");  //  BTOCSITE-85601 GNB 공통영역 아이콘 통일화  // BTOCSITE-44601 [전시] 아이콘2.0 변경 건
                    > a { display:none;}
                    &.after-login {
                        position: relative;
                        &:after {
                            position: absolute;
                            top: 0;
                            right: 2px;
                            width: 4px;
                            height: 4px;
                            border-radius: 2px;
                            background-color: $color-primary;
                            content: '';
                        }
                        .mypage-layer {
                            .mypage-layer-inner {
                                min-width: 157px;
                            }
                        }
                    }
                    .mypage-layer {
                        display: none;
                        position: absolute;
                        top: 24px;
                        left: 50%;
                        z-index: 104;
                        transform: translateX(-50%);
                        padding-top: 14px;
                        .mypage-layer-inner {
                            min-width: 138px;
                            padding: 4px 0;
                            border-radius: 8px;
                            background-color: #fff;
                            box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
                            text-align: center;
                            a {
                                display: block;
                                padding: 8px 0;
                                @include font-small;
                                &:hover,
                                &:focus {
                                    border-radius: 4px;
                                    background-color: $color-bg;
                                }
                            }
                            .mypage-shortcut {
                                border-top: 1px solid $color-border;
                            }
                        }
                    }

                    &.before {
                        > a.before-link { display:block;}
                        &:hover {
                            .mypage-layer.before-login { display:block;}
                        }
                    }
                    &.after {
                        > a.after-link { display:block; position:relative;
                            
                            &:after { content:''; display:block; position:absolute; top:0; right:2px; width:4px; height:4px; border-radius:50%; background:#ea1917;}
                        }
                        &:hover {
                            .mypage-layer.after-login { display:block;}
                        }
                    }
                }
                &.cart {
                    background-image: url("/lg5-common/images/icons/icon_cart_new.svg"); // BTOCSITE-85601 GNB 공통영역 아이콘 통일화  // BTOCSITE-44601 [전시] 아이콘2.0 변경 건
                }
                &.notice {
                    background-image: url("/lg5-common/images/icons/icon-notice-20.svg");
                }
                .count {
                    position: absolute;
                    top: -5px;
                    left: 10px;
                    display: block;
                    min-width: 14px;
                    height: 14px;
                    padding: 2px 4px;
                    border-radius: 8px;
                    background-color: $color-primary;
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 10px;
                    color: $color-white;
                    text-align: center;
                }
                &.mypage {
                    .mypage-layer {
                        .mypage-layer-inner {
                            .mypage-shortcut {
                                // S : BTOCSITE-57367 [전시] 리뷰 내재화(크레마 솔루션제거)
                                li {
                                    .count {
                                        position:initial;
                                        display:inline-block;
                                        border-radius:100%;
                                        background-color:#000;
                                        color:#fff;
                                        padding:0 4px;
                                        height:20px;
                                        line-height:20px;
                                        min-width:20px;
                                        margin:2px 0 0 2px;
                                        vertical-align:top;
                                    }
                                }
                                // E : BTOCSITE-57367 [전시] 리뷰 내재화(크레마 솔루션제거)
                            }
                        }
                    }
                }
            }
        }
        // 회사소개 탑메뉴 //BTOCSITE-19196 브랜드관 구축(브랜드 홈)
        .about-company, .brand-home, .business, .business-B2B { // BTOCSITE-48265 [전시] B2B OBS 구축  클래스 추가 
            position: relative;
            margin-left: 24px;
            &:before {
                display: inline-block;
                width: 1px;
                height: 14px;
                margin-right: 24px;
                background-color: #ddd;
                content: '';
            }
            // S : BTOCSITE-48265 [전시] B2B OBS 구축  클래스 추가 
            &.business-B2B {
                a {
                    padding-right: 18px;
                    background: url(/lg5-common/images/icons/btn-outlink-16.svg) no-repeat right top 3px;
                }
            }
            // E : BTOCSITE-48265 [전시] B2B OBS 구축  클래스 추가 
            @include mobile {
                display: none;
            }
            > a {
                font-size: 14px;
                line-height: 24px;
            }
            // 회사소개 탑메뉴 추가 START
            .about-company-layer {
                display: none;
                position: absolute;
                top: 20px;
                max-width: 160px; // BTOCSITE-66327 모바일 GNB 내, LifeisGood 캠페인 링크 추가건 
                padding-top: 12px;
                z-index: 104;
                width:160px; // BTOCSITE-66327 모바일 GNB 내, LifeisGood 캠페인 링크 추가건 
                left:-27px; // BTOCSITE-66327 모바일 GNB 내, LifeisGood 캠페인 링크 추가건 
                > ul {
                    padding: 4px 0;
                    border-radius: 4px;
                    background-color: #fff;
                    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
                    li {
                        > a {
                            display: block;
                            padding: 8px 24px;
                            font-size: 14px;
                            line-height:24px;
                            text-align: center;
                            &:hover {
                                background-color:#f4f4f4
                            }
                        }
                        > a.out_link {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding-right: 20px;
                            i.go_out {
                                display: block;
                                width: 16px;
                                height: 16px;
                                margin-left: 4px;
                                background: url(/lg5-common/images/icons/btn-outlink-16.svg) no-repeat 50%;
                                background-size: 14px 14px
                            }
                        }
                    }
                }
            }
            // 회사소개 탑메뉴 end
        }
    
        @include mobile {
            float: none;
            padding-right: 0;
            position: absolute;
            top: 6px;
            right: 11px;
            z-index: 1;
            @media screen and (min-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
                right:51px;
            }
            .shortcut {
                > li {
                    width: 34px;
                    height: 34px;
                    &:not(:first-child) {
                        margin-left: 6px;
                    }
                    > a {
                        padding: 5px;
                    }
                    .count {
                        top: 1px;
                        left: 15px;
                    }
                    &.mypage {
                        display: none;
                    }
                }
            }
        }
    }
    
    // Out Link
    .nav-outer-link {
        @include pc {
            position: absolute;
            top: 19px;
            right: 30px;
            margin-right: 0;
            font-size: 0;
            display: none; // TOCSITE-48265 [전시] B2B OBS 구축
            &:before {
                display: inline-block;
                width: 1px;
                height: 14px;
                margin-left: 24px;
                background-color: $color-border;
                content: '';
            }
        }
        @media screen and (min-width: 1760px) {
            right: 50%;
            margin-right: -852px;
        }
    
        .links {
            display: inline-block;
            li {
                display: inline-block;
                margin-left: 24px;
                a {
                    position: relative;
                    padding-right: 18px;  
                    @include font-small;
                    background: url('/lg5-common/images/icons/btn-outlink-16.svg') no-repeat right top 3px; 
    
                    // BTOCSITE-48265 [전시] B2B OBS 구축
                    &.business {
                        padding-right: 0;
                        background: none;
                    }

                    // S : BTOCSITE-44085 모바일 햄버거메뉴 하단 > 패밀리사이트> 베스트샵 제거
                    @include pc {
                        &.praL {
                            display: none; // BTOCSITE-15539
                        }    
                    }
                    // E : BTOCSITE-44085 모바일 햄버거메뉴 하단 > 패밀리사이트> 베스트샵 제거
                }
            }
        }
        @include pc {
            .links {
                &.mobile {
                    display:none;
                }
            }
        }
    
        @include mobile {
            padding: 32px 16px;
            border-top: 12px solid $color-bg;
            .links {
                width: 100%;
                font-size: 0;
                li {
                    width: 50%;
                    margin-left: 0;
                    a {
                        font-weight: 500;
                    }
                }
                & + .links {
                    padding-top:18px;
                }
            }
        }
    }
    
    .nav-wrap {
    
        overflow: hidden;
        font-size: 0;
        white-space: nowrap;
        padding-left: 42px; /* BTOCSITE-1937 스프레드 메뉴 수정 */
    
        /* BTOCSITE-1937 스프레드 메뉴 수정 */
        @include pc {
            &.is-horizon-scroll {
                padding-left:62px;
            }
        }
    
        @include mobile {
            margin-top: 64px; //BTOCSITE-24121 추가
            padding-left: 0;
            isolation: isolate;
            ul.nav {
                display:block;
            }
            //s : BTOCSITE-24121 추가 
            // BTOCSITE-48265 [전시] B2B OBS 구축 .btn-edm
            .btn-emp, .btn-edm {
                padding: 12px 16px;
                &>a{
                    display: block;
                    padding: 0 16px;
                    font-size: 14px;
                    font-weight: 700;
                    background: #f6f6f6;
                    line-height: 69px;
                    border-radius: 8px;
                    & > span {
                        position: relative;
                        padding-right: 18px;
                        &:after {
                            position: absolute;
                            right: 0;
                            top: 50%;
                            content: '';
                            display: block;
                            width: 12px;
                            height: 12px;
                            margin-top: -6px;
                            background: url('/lg5-common/images/icons/btn-arr-16-black.svg') no-repeat 0 0;
                            background-size: 100%;
                        }
                        & > strong {
                            color: #da0f47;
                        }
                    }
                }
            }
            //e : BTOCSITE-24121 추가
        }
    }
    .nav-brand-gate {
        display: inline-block;
        vertical-align: top;
        margin-left:24px; /* BTOCSITE-1937 스프레드 메뉴 수정 */
        @include pc {
            .title {
                display: none;
            }
        }
        @include mobile {
            margin-left:0; /* BTOCSITE-1937 스프레드 메뉴 수정 */
            border-bottom: 13px solid #f4f4f4 !important;
        }
    
        .brand-link {
            display: flex;
            align-items: center;
            height: 34px;
            padding: 8px 16px;
            border-radius: 18px;
            background-color: $color-bg;
            font-size: 0;
            li {
                display: inline-block;
                margin-left: 16px;
                vertical-align: middle;
                &:first-child {
                    margin-left: 0;
                }
                a {
                    display: block;
                    background-color: $color-bg;
                }
                span {
                    font-weight: 700;
                    @include font(14px,1);
                }
            }
            &.text {
                padding: 5px 16px;
                @include font-small;
                font-weight: 700;
            }
        }
    
        @include mobile {
            display: block;
            padding: 24px 16px;
            border-bottom: 12px solid $color-bg;
            .title {
                font-weight: 500;
                font-size: $font-small - 1;
                line-height: 21px;
            }
            .brand-link {
                height: auto;
                margin-top: 12px;
                padding: 0;
                border-radius: 0;
                background-color: transparent;
                li {
                    flex: auto;
                    margin-left: 5px;
                    text-align: center;
                    a {
                        padding: 11px 0;
                        border-radius: 100px;
                    }
                    img {
                        height: 18px;
                    }
                    span {
                        line-height: 18px;
                    }
                }
                &.text {
                    padding: 0;
                    li {
                        a {
                            padding: 9px 0 10px;
                        }
                    }
                }
            }
        }
    }
    .marketing-link{
        @include pc {
            display: none;
        }
        // padding: 24px 0; //BTOCSITE-10816 GNB 수정 컨텐츠 수정
        border-bottom: 13px solid $color-bg;
        .ui_carousel_list {
            padding: 0 16px;
            background-color: #f4f4f4; //BTOCSITE-10816 GNB 수정 컨텐츠 수정
        }
        .ui_carousel_slide {
            padding-right: 12px;
        }
        .banner-box {
            position: relative;
            display: inline-block;
            width: 60vw;
    
            //BTOCSITE-10816 GNB 수정 컨텐츠 수정
            border-radius: 8px;
            overflow: hidden;
            // height: 112px;
            img {
                width: 100%;
                //BTOCSITE-10816 GNB 수정 컨텐츠 수정
                border-radius: 8px;
                // position: absolute;
                // top: 0;
                // left: 0;
            }
            .mob-navSlideBan {
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: center center;
                height: 21.903520208604952vw;
                min-height: 114px;
                // width: 100%;
                @media screen and (max-width: 500px) {
                    background-size: cover;
                    background-position: 100% center;
                }
            }
            .banner-text {
                position: absolute;
                top: 14px;
                left: 0; //220119 BTOCSITE-10816 수정
                margin-left:12px; //220119 BTOCSITE-10816 추가
                font-weight: bold; //220119 BTOCSITE-10816 추가
                font-size: $font-medium - 1;
                line-height: 23px;
                //color: $color-white; //삭제됨
                //text-shadow: 0 1px 20px rgba(0, 0, 0, 0.4); //220119 BTOCSITE-10816 제거
                width: 60%;
                white-space: normal;
                p {
                    &.text {
                        position: relative;
                        word-break: keep-all;
                    }
                }
            }
        }
    }
    .nav {
    
        @include pc {
            display: inline-block;
    
            //BTOCSITE-7335 : gnb mobile nav 최초 display none 처리
            &.mo-nav {
                display: none;
            }
    
            > li {
                display: inline-block;
                padding-left: 24px;
                vertical-align: top;
                /* BTOCSITE-1937 스프레드 메뉴 수정 */
                &:first-child {
                    padding-left:0;
                }
                &:last-child {
                    padding-right: 0;
                }
                &.icon-update {
                    .nav-item {
                        &:before {
                            position: absolute;
                            top: 5px;
                            right: -5px;
                            width: 4px;
                            height: 4px;
                            border-radius: 2px;
                            background-color: $color-primary;
                            content: '';
                        }
                    }
                }
                &.event-link {
                    display: none;
                }
                // BTOCSITE-62058 스마트홈 경험 솔루션
                &.icon-beta {
                    .nav-item {
                        &:before {
                            position: absolute;
                            top: -1px;
                            right: 0;
                            width: 22px;
                            height: 11px;
                            background: url('/lg5-common/images/icons/badge_beta.svg') no-repeat 0 0 / 100% auto;
                            content: '';
                        }
                    }
                }
            }
    
            .nav-category {
                &.active {
                    margin-left: 8px;
                    padding: 0 16px;
                    border-radius: 18px;
                    background-color: $color-bg;
                }
                @include pc {
                    &:hover,
                    &:focus,
                    &.active {
                        .nav-item {
                            &:after {
                                right: 21px;
                            }
                        }
                    }
                }
            }
        }
    
        .nav-item {
            position: relative;
            display: block;
            padding: 4px 0 12px; /* BTOCSITE-1937 스프레드 메뉴 수정 */
            font-weight: 500; /* BTOCSITE-1937 스프레드 메뉴 수정 */
            font-size: $font-medium;
            line-height: 24px; /* BTOCSITE-1937 스프레드 메뉴 수정 */
            vertical-align: top;
    
            .icon-nav {
                position: relative;
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-left: 2px;
                background: url('/lg5-common/images/icons/btn-plus-16-round.svg') no-repeat 0 0;
                transition: all ease 500ms;
                vertical-align: -1px;
            }
    
            @include pc {
                &:hover,
                &:focus,
                &.active {
                    font-weight: 700;
                    /* BTOCSITE-1937 스프레드 메뉴 수정 : 삭제 */
                    /* @include text-underline(7px,$color-default); */
                    /* BTOCSITE-1937 스프레드 메뉴 수정 : 삭제*/
                    &:after {
                        content:"";
                        position: absolute;
                        bottom:0;
                        left:0;
                        width:100%;
                        height:4px;
                        background: #000;
                    }
                    .icon-nav {
                        transform: rotate(135deg);
                    }
                }
    
                &.active {
                    display: inline-block;
                    & + .nav-category-container,
                    & + .nav-category-layer {
                        display: inline-block;
                    }
                    
                    //  BTOCSITE-62058 스마트홈 경험 솔루션 
                    // 메뉴가 영어 일 경우 
                    &.eng {
                        letter-spacing: -0.25px;
                    }
                }
            }
        }
    
        @include mobile {
            //BTOCSITE-7335
            &.mo-nav {
                display: block;
            }
            > li {
                border-top: 2px solid $color-bg;
                &:first-child {
                    border-top: 0;
                }
    
                &.on {
                    .nav-item {
                        display: block;
                        & + .nav-category-container,
                        & + .nav-category-layer {
                            display: block;
                        }
    
                        &:after {
                            transform: rotate(0);
                        }
                    }
                }
            }
            /* BTOCSITE-35717 베스트랭킹 개편(게이트웨이 배너) */
            .gateway-banner{
                margin:-4px 0 28px;
                width:100%;
                height:40px;
                background:#fdf2ff;
                border-radius:8px;
                .btn-link{
                    display:block;
                    padding:10px 13px 10px 16px;
                    font-weight:700;
                    font-size:14px;
                    line-height:20px;
                    text-decoration:none;
                    &:after{
                        right:auto;
                        top:50%;
                        transform:translateY(-50%);
                        margin-left:5px;
                        width:12px;
                        height:12px;
                        background-size:100% 100%;
                    }
                    >span{
                        color:$color-primary;
                    }
                }
            }
            /* //BTOCSITE-35717 베스트랭킹 개편(게이트웨이 배너) */
            .nav-item {
                position: relative;
                padding: 20px 16px;
                font-weight: 700;
                line-height: 24px;
    
                &:after {
                    position: absolute;
                    @include calc(top,50%,12px);
                    right: 16px;
                    width: 24px;
                    height: 24px;
                    background: url('/lg5-common/images/icons/btn-arr-48-black.svg') no-repeat 0 0;
                    background-size: 24px 24px;
                    transform: rotate(180deg);
                    transition: all ease 500ms;
                    content: '';
                }
    
                .icon-nav {
                    display: none;
                }
            }
            // 서브메인 바로가기
            .category-home {
                border-top: 1px solid $color-bg;
                .super-category-item,
                .category-item {
                    display: block;
                    padding: 14px 16px;
                    font-weight: 500;
                    @include font($font-small,22px);
                }
                .super-category-item {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
        
    .nav-category-layer {
        display: none;
    
        @include pc {
    
            .nav-category-inner {
                position: absolute;
                top: 105px; /* BTOCSITE-1937 스프레드 메뉴 수정 */
                left: 0;
                right: 0;
                z-index: 103;
                border-top:1px solid #ddd; /* BTOCSITE-1937 스프레드 메뉴 수정 */
                background-color: #fff;
            }
            .nav-category-wrap {
                @include clearfix;
                position: relative;
                max-width: (1680px + 80px);
                margin: 0 auto;
                padding: 28px 40px 64px;
                background-color: #fff;
            }
            .category-title {
                margin-bottom: 32px;
                font-weight: 700;
                @include font-large-1;
                // S : BTOCSITE-62058 스마트홈 경험 솔루션
                @include pc {
                    a {
                        padding-right: 24px;
                        display: inline-block;
                        background: url(/lg5-common/images/icons/btn-arr-16-black-sm.svg) no-repeat right center / 24px auto;
                    }
                }
                // E : BTOCSITE-62058 스마트홈 경험 솔루션
            }
    
            .category-box {
                @include clearfix;
                position: relative;
                .column {
                    float: left;
                    min-width: 25%;
                    min-height: 1px;
                    padding-right: 24px;
                    &.additional-category {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 25%;
                        float: none;
                        width: 25%;
                        background-color: $color-bg-wt;
                    }
                    &.category-banner {
                        position: relative;
                        float: right;
                        right: 0;
                        @include calc(width,25%,24px);
                        min-width: auto;
                        padding-right: 0;
                        /* BTOCSITE-15884 GNB 제품체험/패널구매 추가 요청 */
                        .bg-banner{
                            margin-top:30px;
                            .banner-link{
                                display:table;
                                position:relative;
                                padding:0 36px 0 40px;
                                width:100%;
                                height:110px;
                                background:#f4f4f4;
                                border-radius:8px;
                                .tit{
                                    display:table-cell;
                                    vertical-align:middle;
                                    font-size:18px;
                                }
                                .thumb{
                                    position:absolute;
                                    top:50%;
                                    right:36px;
                                    width:92px;
                                    height:92px;
                                    transform:translateY(-50%);
                                    img{
                                        width:100%;
                                        height:100%;
                                    }
                                }
                                @media screen and (max-width: 1500px) {
                                    padding:0 7% 0 6%;
                                    .tit{
                                        font-size:16px;
                                    }
                                    .thumb{
                                        right:7%;
                                        width:78px;
                                        height:78px;
                                    }
                                }
                            }
                        }
                        /* //BTOCSITE-15884 GNB 제품체험/패널구매 추가 요청 */
                        
                        // S : BTOCSITE-57084 데스크탑> gnb> 설치/구매가이드 노출 강화 건
                        .guide-link-banner {
                            display: flex;
                            width: 316px;
                            flex-wrap: wrap;
                            li {
                                position: relative;
                                margin-bottom: 16px;
                                width: 150px;
                                height: 150px;
                                max-width: 150px;
                                &:nth-child(odd) {
                                    margin-right: 16px;
                                }
                                >a{
                                    display:block;
                                    position:relative;
                                    overflow: hidden;
                                    border-radius: 20px;
                                }
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                                .title {
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    padding: 5px 8px 6px;
                                    width: 100%;
                                    font-size: 12px;
                                    line-height: 16px;
                                    color: #fff;
                                    text-align: center;
                                    white-space: normal;
                                    -webkit-backdrop-filter: blur(5px);
                                    backdrop-filter: blur(5px);
                                    background-color: rgba(0, 0, 0, .5); // BTOCSITE-73711 PC GNB 넛지 컨텐츠 이미지 수정
                                }
                            }
                        }
                        // E : BTOCSITE-57084 데스크탑> gnb> 설치/구매가이드 노출 강화 건
                    }
                    @media screen and (max-width: 1240px) {
                        width: 33.33%;
                        &.additional-category {
                            right: 0;
                            width: inherit;
                        }
                        &.category-banner {
                            display: none;
                        }
                    }
                    /* BTOCSITE-35717 베스트랭킹 개편(게이트웨이 배너) */
                    .bg-banner{
                        &~.gateway-banner{
                            margin-top:30px;
                        }
                    }
                    .gateway-banner{
                        margin-top:18px;
                        padding-right:0;
                        width:100%;
                        min-width:auto;
                        height:40px;
                        background:#fdf2ff;
                        border-radius:6px;
                        .btn-link{
                            display:block;
                            padding:10px 13px 10px 16px;
                            font-weight:700;
                            font-size:14px;
                            line-height:20px;
                            text-decoration:none;
                            &:after{
                                top:50%;
                                right:13px;
                                transform:translateY(-50%);
                                width:16px;
                                height:16px;
                                background-size:100% 100%;
                            }
                            >span{
                                color:$color-primary;
                            }
                        }
                    }
                    /* //BTOCSITE-35717 베스트랭킹 개편(게이트웨이 배너) */
                    /* BTOCSITE-83147 다이렉트 청약 서비스 도입 */
                    .link-direct-subscribe{
                        display:inline-block;
                        margin-top:8px;
                        padding-right:20px;
                        font-size:14px;
                        font-weight:500;
                        line-height:20px;
                        background:url("/lg5-common/images/icons/btn-arr-16-black-sm.svg") no-repeat right center;
                    }
                    /* //BTOCSITE-83147 다이렉트 청약 서비스 도입 */
                }
                &.category-image-box {
                    margin: 0 -12px;
                    .column {
                        width: 25%;
                        min-width: auto;
                        padding: 0 12px;
                    }
                }
            }
        }
    
        .category {
            > li {
                margin-bottom: 32px;
                &:last-child {
                    margin-bottom: 0;
                }
    
                .category-item {
                    display: inline-block;
                    padding-right: 20px;
                    font-weight: 700;
                    font-size: $font-medium;
                    line-height: 26px;
                }
                a.category-item {
                    background: url('/lg5-common/images/icons/btn-arr-16-black-sm.svg') no-repeat right center;
    
                    // BTOCSITE-21430 category target=_blank 새창 아이콘 타입 추가
                    &[target="_blank"] {
                        background-image: url("/lg5-common/images/icons/btn-outlink-16.svg");
                    }
                }
                .sub-category {
                    margin-top: 16px;
                    li {
                        margin-bottom: 8px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        a {
                            padding-right: 18px;
                            @include font-small;
                            color: #111;
    
                            //BTOCSITE-1937
                            &:hover, &:focus {
                                @include pc {
                                    text-decoration: underline;
                                }
                            }
                            &[target='_blank'] {
                                background: url('/lg5-common/images/icons/btn-outlink-16.svg') no-repeat right top 5px;
                                @include mobile {
                                    background-position: right 28px top 8px;
                                }
                            }
                            //  S : BTOCSITE-62058 스마트홈 경험 솔루션
                            &.sub-category-item {
                                position: relative;
                                padding-right: 17px;
                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 3px;
                                    right: -2px;
                                    width: 15px;
                                    height: 15px;
                                    background: url('/lg5-common/images/icons/btn-arr-16-black-sm.svg') no-repeat 100% 50% /  100% auto;
                                    transform: rotate(90deg);
                                }
                                &:hover, &:focus {
                                    @include pc {
                                        text-decoration: none;
                                    }
                                }
                            }
                            // E : BTOCSITE-62058 스마트홈 경험 솔루션
                        }
                        
                        // S : BTOCSITE-62058 스마트홈 경험 솔루션
                        &.active {
                            a {
                                &.sub-category-item {
                                    font-weight: 700;
                                    &::after {
                                        transform: rotate(-90deg);
                                    }
                                }
                            }
                            .sub-depth-category {
                                display: block;
                            }
                        }
                        .sub-depth-category {
                            padding-bottom: 4px;
                            display: none;
                            li {
                                margin-top: 8px;
                                a {
                                    font-size: 13px;
                                    line-height: 1.5;
                                    color: #666;
                                }
                            }
                        }
                        // E : BTOCSITE-62058 스마트홈 경험 솔루션
                    }
                }
            }
            & + .category-related,
            & + .category-hashtag {
                margin-top: 48px;
            }
            
            //  S : BTOCSITE-62058 스마트홈 경험 솔루션
            &.category-bg {
                li {
                    margin-bottom: 12px;
                }
                .category-item {
                    padding: 14px 33px 14px 14px;
                    font-size: 14px;
                    line-height: 16px;
                    font-weight: 400;
                    overflow: hidden;
                    background: #F3F3F3 url('/lg5-common/images/icons/btn-arr-16-black-sm.svg') no-repeat right 14px center;
                    border-radius: 8px;
                }                    
            }
            //  E : BTOCSITE-62058 스마트홈 경험 솔루션
        }
    
        .category-related {
            margin-bottom: 32px;
            .link-item {
                display: inline-block;
                padding-right: 20px;
                margin-bottom: 4px;
                background: url('/lg5-common/images/icons/btn-arr-16-black-sm.svg') no-repeat right center;
                @include font-small;
                text-decoration: underline;
            }
        }
        .category-hashtag {
            .tag-item {
                display: inline-block;
                margin-bottom: 8px;
                padding: 7px 8px;
                border: 1px solid $color-border;
                border-radius: 8px;
                font-weight: 500;
                @include font-small;
                color: $color-gray-1;
            }
        }
    
        .category-banner {
            position: relative;
            .ui_carousel_slider {
                position:relative;
                overflow:hidden;
                padding-bottom: 34px;
                box-sizing: border-box;
                &.slide-solo{
                    padding-bottom: 0;
                }
            }
            .indi-wrap {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                padding-right: 26px;
                .ui_carousel_play {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin-left:0;
                    .btn-play {
                        position: relative;
                        display: inline-block;
                        width: 18px;
                        height: 18px;
                        margin-left: 12px;
                        background: #fff no-repeat 0 0;
                        font-size: 0;
                    }
                    &.play {
                        .btn-play {
                            background-image: url('/lg5-common/images/icons/btn-play-18.svg');
                        }
                    }
                    &.stop {
                        .btn-play {
                            background-image: url('/lg5-common/images/icons/btn-pause-18.svg');
                        }
                    }
                }
            }
            .slide-controls {
                display: none;
                .btn-arrow {
                    position: absolute;
    
                    top: calc(50% - 37px); /* BTOCSITE-1937 스프레드 메뉴 수정 */
                    transform:translateY(-50%);  /* BTOCSITE-1937 스프레드 메뉴 수정 */
                    box-shadow:2px 4px 16px 0 rgba(#000, .14);
                    &:before {
                        background-image: url('/lg5-common/images/icons/btn-arr-32x32.svg');
                        box-shadow: none;
                    }
                    &.prev {
                        left: 16px;
                        &:before {
                            transform: rotate(-180deg);
                        }
                    }
                    &.next {
                        right: 16px;
                    }
                }
            }
            img {
                width: 100%;
                border-radius: 8px;
            }
            .banner-box {
                display: block;
                text-align: center;
                .banner-text {
                    margin-top: 16px;
                    white-space: normal;
                    .text {
                        font-weight: 700;
                        @include font-large; //BTOCSITE-1937
                        @include textEllipsisClamp(2);
                    }
                }
            }
        }
    
        .category-image-box {
            .category {
                > li {
                    margin-bottom: 0;
                    .category-item {
                        margin-bottom: 16px;
                    }
                }
                &.brand-category {
                    padding: 22px 0 0 20px;
                }
            }
            .category-image {
                border-radius: 8px;
                img {
                    width: 100%;
                    border-radius: 8px;
                }
            }
            .category-content {
                a {
                    display: inline-block;
                    margin-top: 8px;
                    font-size: 14px;
                    line-height: 20px;
                    color: #111;
                }
            }
        }
    }
    
    @include pc{
        .btn-nav-arrow {
            display: none;
            position: absolute;
            top: 60px;
            z-index: 5;
            width: 54px;
            height: 34px;
            &:after {
                position: absolute;
                top: 5px;
                width: 32px;
                height: 32px;
                border-radius: 16px;
                background: url('/lg5-common/images/icons/btn-arr-32x32.svg') no-repeat 0 0;
                box-shadow: 1px 3px 9px 0 rgba(0, 0, 0, 0.14);
                content: '';
            }
            &.prev {
                left: 0;
                background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0) 60%);
                &:after {
                    left: 24px;
                    transform: rotateY(180deg);
                }
            }
            &.next {
                right: 0;
                background-image: linear-gradient(to left, #fff, rgba(255, 255, 255, 0) 60%);
                &:after {
                    right: 24px;
                }
            }
            &:disabled, &.disabled, &[aria-disabled=true] {
                display: none;
            }
        }
    
        .utility {
            right: 51px;
        }
    }
    
    @include mobile {
        .category-title,
        .sub-category,
        .category-related,
        .category-hashtag,
        .category-banner,
        .category-image,
        .category-content,
        .brand-category {
            display: none;
        }
    
        .nav-category-layer {
            .nav-category-inner {
                .nav-category-wrap {
                    padding-bottom: 14px;
                }
            }
            .category {
                > li {
                    margin-bottom: 0;
                    &.on {
                        .category-item {
                            &:after {
                                background-image: url('/lg5-common/images/icons/btn-minus-48.svg');
                            }
                        }
                    }
                    
                    
                    // S : BTOCSITE-62058 스마트홈 경험 솔루션 - 모바일
                    &.category-bg {
                        margin-top: 8px;
                        &:nth-child(odd) {
                            padding-right: 4px;
                        }
                        &:nth-child(even) {
                            padding-left: 4px;
                        }
                        .category-item {
                            padding-left: 12px !important;
                            overflow: hidden;
                            background: #F9F9F9 !important;
                            border-radius: 8px;
                        }
                    }
                    // E  : BTOCSITE-62058 스마트홈 경험 솔루션 
                    .category-item {
                        display: block;
                        position: relative;
                        margin-bottom: 0;
                        padding: 14px 16px;
                        font-weight: 400;
                        font-size: $font-small;
                        line-height: 22px;
                        &:after {
                            position: absolute;
                            @include calc(top,50%,12px);
                            right: 24px;
                            width: 24px;
                            height: 24px;
                            background: url('/lg5-common/images/icons/btn-plus-48.svg') no-repeat 0 0;
                            background-size: 24px 24px;
                            transition: all ease 500ms;
                            content: '';
                        }
                        &.none-toggle {
                            &:before {
                                display: none !important;
                            }
                            &:after {
                                display: none;
                            }
                        }
                    }
                    // BTOCSITE-21430 category target=_blank 새창 아이콘 타입 추가
                    a.category-item {
                        background: none !important;
                    }
                    span.category-item {
                        cursor: pointer;
                    }
    
                    .sub-category {
                        margin-top: 0;
                        padding: 14px 0;
                        background-color: #f9f9f9;
                        li {
                            margin-bottom: 0;
                            a {
                                display: block;
                                padding: 10px 28px;
                            }
                        }
                    }
                }
            }
            .category-image-box {
                .column {
                    width: 100%;
                }
                .category {
                    > li {
                        .category-item {
                            margin-bottom: 0;
                            &.active {
                                & ~ .category-content {
                                    display: block;
                                }
                            }
                        }
                    }
                }
                .category-content {
                    padding: 0 20px;
                    li {
                        margin-bottom: 0;
                        background-color: #f4f4f4;
                        &:nth-child(1) {
                            padding-top: 6px;
                        }
                        &:nth-last-child(1) {
                            padding-bottom: 6px;
                        }
                        a {
                            display: block;
                            margin-top: 0;
                            padding: 6px 16px;
                            line-height: 22px;
                            color: #000;
                            &:focus,
                            &.active {
                                font-weight: inherit;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    
        .nav-category-container {
            padding-bottom: 14px;
            > ul {
                > li {
                    &.on {
                        .super-category-item {
                            &:after {
                                background-image: url('/lg5-common/images/icons/btn-minus-48.svg');
                            }
                        }
                    }
                }
            }
            .super-category-item {
                padding: 14px 16px;
                font-weight: 400;
                line-height: 22px;
                color: $color-default;
    
                &:after {
                    position: absolute;
                    @include calc(top,50%,12px);
                    right: 24px;
                    width: 24px;
                    height: 24px;
                    background: url('/lg5-common/images/icons/btn-plus-48.svg') no-repeat 0 0;
                    background-size: 24px 24px;
                    transition: all ease 500ms;
                    content: '';
                }
            }
    
            .nav-category-inner {
                .nav-category-wrap {
                    padding: 14px 0;
                    background-color: $color-bg;
                    .category-box {
                        padding: 0;
                        border-top: 0;
                    }
                }
            }
    
            .category {
                > li {
                    .category-item {
                        padding: 10px 28px;
                        font-size: $font-small - 1;
                        line-height: 21px;
                        color: $color-gray-1;
                        &:after {
                            display: none;
                        }
                        &:active,
                        &.focus {
                            font-weight: 500;
                            color: $color-default;
                        }
                    }
                    //span.category-item { 보류....
                    //    display: none;
                    //    & + .sub-category {
                    //        display: block;
                    //        margin-top: 0;
                    //        padding: 0;
                    //        li {
                    //            padding: 0;
                    //            a {
                    //                padding: 6px 28px;
                    //                font-size: $font-small - 1;
                    //                line-height: 19px;
                    //            }
                    //        }
                    //    }
                    //}
                }
            }
        }
    }
    
    .shortcut-service-link { //BTOCSITE-10816 GNB 수정 컨텐츠 수정
        padding: 32px 16px;
        // border-top: 12px solid #f5f5f5;
        .title {
            @include blind;
            //display: block;
            margin-bottom: 4px;
            font-weight: 500;
            font-size: $font-small - 1;
            line-height: 21px;
        }
        .links {
            @include clearfix;
            margin-top: -16px;
            li {
                float: left;
                width: 50%;
                margin-top: 16px;
                a {
                    //padding-right: 22px;
                    //background: url('/lg5-common/images/icons/btn-arr-40-black.svg') no-repeat right center;
                    //background-size: 20px 20px;
                    font-weight: 500;
                    @include font($font-small - 1,21px);
                    &:focus,
                    &:active {
                        //@include text-underline(1px,$color-default); #11544 요청건 220714
                    }
                }
            }
        }
    }
    
    .mobile-nav-button {
        // LGECOMVIO-21 추가
        display: none;
        position: absolute;
        top: 6px;
        right: 11px;
        width: 34px;
        height: 34px;
        padding: 5px;
        background: url('/lg5-common/images/icons/icon_menu_new/lg5-common/images/icons/icon_cart_new.svg') no-repeat center center;   //  BTOCSITE-85601 GNB 공통영역 아이콘 통일화
        background-size: 24px;     
    
        span[class^=line-],
        .mobile-nav-close {
            display: none;
        }
    
        @include mobile {
            @media screen and (min-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
                display:block;
            }
            & + .header-bottom {
                display: block;
                position: fixed;
                top: 0;
                right: -100%;
                bottom: 0;
                z-index: 111;
                transition: right .5s;
                width: calc(100% - 20px);
                overflow-y: auto;
                padding-top: 60px;
                background-color: #fff;
            }
    
            //210701 앱 알람아이콘 N 뱃지 추가
            .count{
                position: absolute;
                top: 2px;
                left: 18px;
                display: block;
                min-width: 14px;
                height: 14px;
                padding: 2px 2px;
                border-radius: 8px;
                background-color: $color-primary;
                font-weight: 700;
                font-size: 10px;
                line-height: 10px;
                color: $color-white;
                text-align: center;
            }
            //210701 앱 알람아이콘 N 뱃지 추가
        }
    
        &.active {
            position: fixed;
            top: 11px;
            right: 11px;
            z-index: 112;
            width: auto;
            height: auto;
            padding: 0;
            background: none;
            .mobile-nav-close {
                display: block;
                width: 34px;
                height: 34px;
                padding: 5px;
                background: url('/lg5-common/images/icons/btn-close-24-black.svg') no-repeat center center;
            }
    
            @include mobile {
                & + .header-bottom {
                    right: 0;
                    .login-mobile {
                        &:before {
                            right: 0;
                        }
                    }
                    &.app-btm {
                        padding-bottom:60px;
                    }
                }
                //210701 앱 알람아이콘 N 뱃지 추가
                .count {
                    display: none;
                }
                //210701 앱 알람아이콘 N 뱃지 추가
    
            }
        }
    
    }
    
    .login-mobile {
        padding: 0 16px;
        border-bottom: 12px solid $color-bg;
        @include mobile {
            isolation: isolate;
        }
        &:before {
            position: fixed;
            top: 0;
            right: -100%;
            z-index: 112;
            transition: right .5s;
            width: calc(100% - 20px);
            height: 60px;
            background-color: $color-bg-wt;
            content: '';
        }
    
        .login-info {
            position: relative;
            padding: 3px 0 17px;
            font-weight: 700;
            font-size: $font-large;
            line-height: 26px;
            .text {
                margin-top: -3px;
                &:after {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    margin-left: 6px;
                    background: url('/lg5-common/images/icons/btn-arr-32-black.svg') no-repeat center center;
                    background-size: 100% 100%;
                    transform: rotate(180deg);
                    vertical-align: -1px;
                    content: '';
                }
                span {
                    color: $color-gray-1;
                }
            }
            .app-settings-button {
                display: none;
                position: absolute;
                //right: 11px;
                //right: -5px;
                right: -2px;
                top: -2px;
                //width: 34px;
                width: 29px;
                height: 36px;
                background: url('/lg5-common/images/icons/icon-app-settings-new.svg') no-repeat center center; // BTOCSITE-20264
                background-size: 24px; // BTOCSITE-20264
                // z-index: 113;
                &.active {
                    display: block;
                }
            }
            .app-alarm-button{
                display:none;
                position:absolute;
                right:33px; // 210621 앱 알림함 아이콘 추가 원복처리
                top:-2px;
                width:29px;
                height:36px;
                background:url('/lg5-common/images/icons/icon-app-alarm.svg') no-repeat center center;
                background-size:24px;
                &.active{
                    display:block;
                }
                .app-alarm-count{
                    display:none;
                    position:absolute;
                    left:13px;
                    top:3px;
                    padding:2px 4px;
                    min-width:14px;
                    height:14px;
                    border-radius:7px;
                    background-color:$color-primary;
                    text-align:center;
                    line-height:10px;
                    font-weight:700;
                    font-size:10px;
                    color:#fff;
                    &.active{
                        display:block;
                    }
                }
            }
            &.after-login {
                .btn-mypage {
                    &:after {
                        position: absolute;
                        top: 6px;
                        right: 6px;
                        width: 4px;
                        height: 4px;
                        border-radius: 2px;
                        background-color: $color-primary;
                        content: '';
                    }
                }
            }
        }
        .btn-mypage {
            position: absolute;
            top: -1px;
            right: 0;
            z-index: 1;
            a {
                display: block;
                width: 34px;
                height: 34px;
                background: url('/lg5-common/images/icons/icon-person-48-m.svg') no-repeat center center;
                background-size: 24px;
            }
        }
        .btn-logout {
            display: none;
            position: fixed;
            top: 16px;
            left: 16px;
            z-index: 113;
            a {
                display: block;
                padding: 0 10px 1px;
                border-radius: 24px;
                border: 1px solid $color-border;
                font-weight: 500;
                @include font(13px,21px);
                color: $color-gray-1;
            }
        }
    }
    
    .nav-logout {
        border-top: 12px solid $color-bg;
        .btn-logout {
            display: block;
            padding: 24px 16px;
            font-weight: 500;
            font-size: 13px;
            line-height: 21px;
        }
    }
    
    .mobile-nav-banner {
        border-top: 12px solid $color-bg;
        .banner-inner {
            position: relative;
            img {
                width: 100%;
            }
            .banner-text {
                position: absolute;
                top: 24px;
                left: 16px;
                color: $color-white;
                .title {
                    display: block;
                    margin-bottom: 12px;
                    font-weight: 700;
                    font-size: $font-large;
                    line-height: 26px;
                }
                .copy {
                    font-size: $font-small - 1;
                    line-height: 21px;
                }
            }
        }
    }
    /*LGECOMVIO-20 네비게이션 개선*/
    @include mobile {
        .sticky_shadow {
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14%);
        }

        // S : BTOCSITE-47272 gnb 아이콘2.0 수정
        .mobile-nav-close {
            display: block;
            width: 29px;
            height: 36px;
            margin-top: 5px;
            background: url('/lg5-common/images/icons/btn-close-48-black.svg') no-repeat center center;
            background-size:24px 24px;
        }
        .mobile-nav-button.active {
            right: 14px;
            .mobile-nav-close {
                display: none;
                width: 29px;
                height: 36px;
                margin-top: 5px;
                background: url('/lg5-common/images/icons/btn-close-48-black.svg') no-repeat center center;
                background-size:24px 24px;
            }
        }
        // E : BTOCSITE-47272 gnb 아이콘2.0 수정

        .mobile-nav-button.done {
            .mobile-nav-close {
                display: block;
            }
        }

        .mobile-nav-button.active + .header-bottom {
            left: 0 !important;
            right: auto  !important;
        }
        .mobile-nav-button + .header-bottom.header-new {
            width: 100%;
            padding-top: 0;
            left: -100%;
            right: auto;
            transition: left .5s;
            &.no-transition{
                transition: none;
            }
            .login-mobile {
                z-index: 1;
                position: fixed;
                width: 100%;
                padding:19px 50px 0 16px;
                border-bottom: none;
                background: #fff;

                &:before {
                    display: none;
                }

                .login-info {
                    padding: 0;
                    .text {
                        //s : BTOCSITE-24121 추가
                        display: inline-block;  // BTOCSITE-74623 [모니터링 G][APP] trendline[김사업님]에서 공백을 Tap 후 [마이페이지]화면으로 이동됨
                        padding: 0 0 20px 0;
                        //s : BTOCSITE-24121 추가
                        font-size: 20px;
                        line-height: 26px;
                        &:after {
                            background: url("/lg5-common/images/icons/btn_icon_arrow_right_32.svg") center center no-repeat;
                            background-size: 100% 100%;
                            transform: none;
                            margin-left: 4px;
                        }
                        span {
                            font-weight: 700;
                            color: #000;
                        }
                    }
                    .btn-mypage {
                        &:after {
                            top: 3px;
                            right: 2px;
                        }
                        a {
                            position: relative;
                            top: 3px;
                            width: 24px;
                            height: 24px;
                        }
                    }

                    /* .app-alarm-button{
                        top: -4px;
                        right: -5px !important;
                        background-image: url('/lg5-common/images/icons/icon-app-alarm-new.svg');
                    } */ // BTOCSITE-20264 삭제

                }
            }

            .mo-nav--new {
                // margin-top: 66px; //BTOCSITE-24121 수정
            }

            //.menu-button {
            //    display: flex;
            //    flex-wrap: wrap;
            //    margin: 74px 16px 24px;
            //
            //    a {
            //        display: inline-block;
            //        flex: 1 1 calc(50% - 4px);
            //        width: 100%;
            //        height: 42px;
            //        border: 1px solid #ddd;
            //        border-radius: 8px;
            //        font-size: 13px;
            //        text-align: center;
            //        line-height: 42px;
            //
            //        &:before {
            //            display: inline-block;
            //            content: "";
            //            width: 30px;
            //            height: 30px;
            //            margin-right: 4px;
            //            vertical-align: top;
            //            background-size: 30px;
            //        }
            //    }
            //
            //    &-bestshop {
            //        margin-right: 4px;
            //
            //        &:before {
            //            background: url("/lg5-common/images/icons/icon-header-bestshop.svg") no-repeat 0 5px;
            //        }
            //    }
            //
            //    &-store {
            //        margin-left: 4px;
            //
            //        &:before {
            //            background: url("/lg5-common/images/icons/icon-header-store.svg") no-repeat 0 5px;
            //        }
            //    }
            //}

            .super-category .nav-category-layer .category {
                display: none;
            }
            .nav-category-layer .category  li .sub-category {
                display: none;
            }

            .super-category .category-item {
                padding: 12px !important;
                color: #000;
                line-height: 19px;
            }
            .nav > li {
                position: relative;
                margin: 10px 16px 0;
                border-top: 0;

                .category-round {
                    display: inline-block;
                    position: absolute;
                    top: 8px;
                    right: 4px;
                    a {
                        display: block;
                    //  BTOCSITE-62058 스마트홈 경험 솔루션
                        padding: 0 20px;
                        min-width: 94px;
                        height: 24px;
                        border: 1px solid #8f8f8f;
                        border-radius: 20px;
                        font-size: 11px;
                        font-weight: 500;
                        line-height: 23px;
                        text-align: center;
                    }
                }
            }

            .nav-item {
                display:inline-block;
                padding: 11px 0 11px 4px;
                font-size: 15px;
                line-height: 17px;
                ~.nav-category-layer{
                    padding-top:4px;
                    border-top:1px solid #ddd;
                }

                &:after {
                    display: none;
                }
                //  BTOCSITE-62058 스마트홈 경험 솔루션
                &.icon-beta {
                    &:after {
                        content: '';
                        position: static;
                        margin-left: 4px;
                        display: inline-block;
                        width: 22px;
                        height: 11px;
                        background: url('/lg5-common/images/icons/badge_beta.svg') no-repeat 100% 50% / 100% auto;
                        transform: none;
                        transition: none;
                    }
                }
            }

            .nav-category-container {
                display: block;
                padding-top:4px;
                border-top:1px solid #ddd;

                li.on .super-category-item {
                    position: relative;
                    font-weight: 700;

                    &:before {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        display: block;
                        content: "";
                        width: calc(100% - 4px);
                        height: 1px;
                        background: #000;
                    }

                    &:after {
                        background-image: url("/lg5-common/images/icons/btn-minus-12.svg");
                    }
                }

                .super-category {
                    display: flex;
                    flex-wrap: wrap;

                    &-item {
                        padding: 11px 0 11px 4px;
                        background: #fff;
                        line-height: 20px;

                        &:after {
                            top: 50%;
                            right: 8px;
                            width: 12px;
                            height: 12px;
                            background: url("/lg5-common/images/icons/btn-plus-12.svg") no-repeat 0 0;
                            background-size: 100% 100%;
                            transition: none;
                            transform:translateY(-50%);
                        }
                    }

                    &-list {
                        flex: 1 1 calc(50% - 4px);
                        max-width: 50%;

                        .super-category-item {
                            padding-right: 4px;
                        }

                        .nav-category-wrap {
                            width: 200%;
                            padding: 0;
                            background: transparent;
                        }

                        &:nth-child(even) {
                            .super-category-item {
                                padding-left: 8px;
                                padding-right: 0;

                                &:before {
                                    left: auto;
                                    right: 0;
                                }
                                &:after {
                                    right: 4px;
                                }
                            }

                            .nav-category-wrap {
                                margin-left: -100%;
                            }
                        }
                    }
                }
            }
            .nav-category-layer {
                display: block;
                width: 100%;
                .nav-category-wrap {
                    padding-bottom: 0;
                }
            }
            .nav-category-layer .category  {
                display: flex;
                flex-wrap: wrap;

                //  S : BTOCSITE-62058 스마트홈 경험 솔루션
                // 3뎁스 카테고리가 있는 경우
                &.category-depth {
                    li {
                        &.on {
                            .category-item {
                                font-weight: 700;
                            }
                            .sub-category {
                                display: flex !important;
                                flex-wrap: wrap;
                                li {
                                    float: none;
                                }
                            }
                        }
                    }
                }
                //  E : BTOCSITE-62058 스마트홈 경험 솔루션

                li {
                    position: relative;
                    flex: 1 1 calc(50% - 4px);
                    max-width: 50%;
                    .category-item {
                        padding: 12px 0 11px 4px;
                        line-height: 20px;

                        &[target="_blank"]:after {
                            position: relative;
                            top: auto;
                            right: auto;
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            margin-left: 4px;
                            background: url("/lg5-common/images/icons/btn-outlink-16.svg") center no-repeat;
                            background-size: 16px 16px;
                            vertical-align: -3px;
                        }
                    }

                    &:nth-child(even) {
                        .category-item{
                            padding-left: 8px;
                            &:before {
                                left: auto !important;
                                right: 0;
                            }
                            &:after {
                                right: 4px;
                            }
                            &[target="_blank"]:after {
                                right: auto;
                            }
                        }
                    }
                    &.on .category-item {
                        position: relative;
                        font-weight:700;
                        &:before {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            display: block;
                            content: "";
                            width: calc(100% - 4px);
                            height: 1px;
                            background: #000;
                        }
                        // S : BTOCSITE-33379 전체메뉴(햄버거) 모바일에서 화살표 뜨는 이슈
                        &:after {
                            background-image: url("/lg5-common/images/icons/btn-minus-12.svg");
                        }
                        // E : BTOCSITE-33379 전체메뉴(햄버거) 모바일에서 화살표 뜨는 이슈

                        // BTOCSITE-68772 [모니터링 G][APP] browser 종료 시 [메뉴] 화면에 이동하고 [제휴혜택 -] 아이콘 노출됨/비정상 "-" 아이콘이 노출됨
                        &[target=_blank]:after {
                            background-image: url("/lg5-common/images/icons/btn-outlink-16.svg");
                        }
                    }
                    .category-item + .sub-category {
                        display: none;
                    }

                    .category-item:after {
                        top: 50%;
                        right: 8px;
                        width: 12px;
                        height: 12px;
                        background: url("/lg5-common/images/icons/btn-plus-12.svg") no-repeat 0 0;
                        background-size: 100% 100%;
                        transition: none;
                        transform:translateY(-50%);
                    }
                    &.on:nth-child(even) .sub-category {
                        margin-left: -100%;
                    }
                    &.on {
                        z-index: 10;
                    }
                }
            }

            .super-category-list .nav-category-layer .category {
                // S : BTOCSITE-21923 전체메뉴 2뎁스 배경색 변경 건
                background: #f6f6f6;
                // E : BTOCSITE-21923 전체메뉴 2뎁스 배경색 변경 건
                &:after {
                    display: block;
                    content: "";
                    clear: both;
                }
                li {
                    float: left;
                    width: 50%;
                }
            }
            .nav-category-layer .category > li .sub-category {
                display: none;
                width: 200%;
                padding: 0;

                &:after {
                    content: "";
                    display: block;
                    clear: both;
                }

                li {
                    float: left;
                    width: 50%;
                    a{

                        padding: 12px;
                        color: #000;
                        line-height: 19px;

                        &[target="_blank"] {
                            background: none;

                            &:after {
                                content: '';
                                display: inline-block;
                                width: 16px;
                                height: 16px;
                                margin-left: 4px;
                                background: url("/lg5-common/images/icons/btn-outlink-16.svg") center no-repeat;
                                background-size: 100%;
                                vertical-align: -3px;
                            }
                        }
                    }
                    
                    // S : BTOCSITE-62058 스마트홈 경험 솔루션 // 모바일
                    &.active {
                        z-index: 2;
                        &:nth-child(even) {
                            .sub-depth-category {
                                margin-left: -100%;
                            }
                        }
                        a.sub-category-item {
                            font-weight: 700;
                            &::after {
                                background: url("/lg5-common/images/icons/btn-minus-12.svg") no-repeat 0 0 / 100% auto;
                            }
                        }
                        .sub-depth-category {
                            display: flex;
                            flex-wrap: wrap;
                        }
                    }                            
                    // 2뎁스 하위메뉴가 있을때
                    .sub-category-item {
                        &::after {
                            top: 50%;
                            right: 8px;
                            width: 12px;
                            height: 12px;
                            background: url("/lg5-common/images/icons/btn-plus-12.svg") no-repeat 0 0;
                            background-size: 100% 100%;
                            transform:translateY(-50%);
                        }
                    }
                    // 3뎁스 메뉴
                    .sub-depth-category {
                        width: 200%;
                        padding-bottom: 14px;
                        li {
                            flex: 1 1 calc(50% - 4px);
                            margin-top: 0;
                            a {
                                padding: 10px 8px 10px 24px;
                                font-size: 13px;
                                line-height: 20px;
                                color: #666;
                            }
                        }
                    }
                    // E : BTOCSITE-62058 스마트홈 경험 솔루션
                }
            }

            .event-link {
                overflow-x: auto;
                margin:28px 0 !important;
                border-bottom: 0;
                .banner-box {
                    overflow:hidden;
                    display: inline-block;
                    width: 272px;
                    height:auto;
                    margin-left: 8px;
                    border-radius: 6px;

                    &:first-child {
                        margin-left: 16px;
                    }
                    &:last-child {
                        margin-right: 16px;
                    }
                    img {
                        width:100%;
                        height:100%;
                    }
                    &:only-child {
                        width:-webkit-fill-available;
                    }
                }
            }

            .nav-outer-link {
                margin-top: -2px;
                padding: 0 16px;
                border-top: 0;
                font-size: 12px;
                .nav-outer-title {
                    display: block;
                    margin-bottom: 4px;
                    padding: 16px 0 10px 4px;
                    border-top: 0;
                    border-bottom: 1px solid #ddd;
                    font-size:15px;
                    line-height: 17px;
                    font-weight: 700;
                }

                .links {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        max-width: 50%;
                        flex: 1 1 50%;
                        padding: 12px 0 12px 4px;
                        a {
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 400 !important;
                        }
                        .bestshop {
                            display: inline-block;
                        }
                    }
                    + .links {
                        padding-top: 0;
                    }
                }
            }
            /* BTOCSITE-19196 브랜드관 구축 */
            .brand-banner {
                position:relative;
                margin: 28px 0 0;
                padding: 11px 16px;
                background: #f7f7f7;
                .title {
                    padding: 11px 0 10px 4px;
                    font-size:15px;
                    line-height: 17px;
                    font-weight: 700;
                }
                .category-round {
                    position:absolute;
                    top: 18px;
                    right: 16px;
                    a {
                        display: block;
                        width: 94px;
                        height: 24px;
                        border: 1px solid #ddd;
                        border-radius: 20px;
                        font-size: 11px;
                        line-height:23px;
                        font-weight: 500;
                        text-align: center;
                    }
                }
                .brand-banner-link {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-top: 8px;

                    li:nth-child(n+5){
                        display: none;
                    }
                    li {
                        flex: 1 1 calc(50% - 4px);
                        max-width:calc(50% - 4px);
                        margin-bottom:8px;

                        a {
                            display: block;
                            border-radius: 6px;
                            overflow: hidden;
                        }

                        img{
                            width: 100%;
                            height: 100%;
                        }
                        &.active {
                            display:block;
                        }
                    }
                }
                .button-area {
                    margin-bottom: -10px;
                    text-align:center;
                    .all-view {
                        font-size: 13px;
                        line-height: 19px;
                        padding:10px;
                        &:after {
                            display:inline-block;
                            content:"";
                            width: 12px;
                            height: 12px;
                            margin-left: 4px;
                            background: url("/lg5-common/images/icons/icon-arr-down-12.svg") 0 1px no-repeat;
                            background-size: 100% auto;
                        }
                        &.close {
                            //display: none;
                            &:after {
                                transform: rotate(180deg);
                                background-position: 0 -1px;
                            }
                        }
                    }
                }
            }
            /* //BTOCSITE-19196 브랜드관 구축 */
            .service-list {
                padding: 20px 16px;
                background: #f4f4f4;
                ul {
                    display: flex;
                }
                li {
                    flex: 1 1 50%;
                    &:first-child {
                        margin-right: 8px;
                    }
                }
                a {
                    position: relative;
                    display: block;
                    padding: 12px 8px 12px 12px;
                    background: #fff;
                    box-shadow: 0.5px 2px 7px -1.5px rgba(0,0,0,.14);
                    border-radius: 4px;
                    font-size: 10px;
                    line-height: 16px;
                    &:after {
                        display: block;
                        content:"";
                        position: absolute;
                        top: calc(50% - 6px);
                        right: 8px;
                        width: 12px;
                        height: 12px;
                        background:url("/lg5-common/images/icons/arr-12x12.svg") 0 0 no-repeat;
                        background-size: 12px;
                    }
                    strong {
                        font-size: 12px;
                    }
                }
            }

            .shortcut-service-link + .shortcut-service-link {
                padding-top: 0;
                .category-round {
                    top: 9px;
                }
            }

            .shortcut-service-link {
                position: relative;
                padding:28px 16px 28px;

                .category-round {
                    position:absolute;
                    top: 39px;
                    right: 16px;
                    a {
                        display: block;
                        width: 94px;
                        height: 24px;
                        border: 1px solid #ddd;
                        border-radius: 20px;
                        font-size: 11px;
                        line-height: 23px;
                        font-weight: 500;
                        text-align: center;
                    }
                }

                .links {
                    margin: 0 0 0 4px;
                    li {
                        margin-top: 0;
                        padding: 12px 0;
                        a {
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 400;
                            &[target="_blank"] {
                                padding-right: 18px;
                                background: url(/lg5-common/images/icons/btn-outlink-16.svg) no-repeat right top 3px;
                            }
                        }
                    }
                    .icon-link a {
                        padding-right: 18px;
                        background: url("/lg5-common/images/icons/btn-outlink-16.svg") no-repeat right top 3px;
                    }
                }
                .shortcut-service-title {
                    display: block;
                    margin-bottom: 4px;
                    padding: 16px 0 10px 4px;
                    border-top: 0;
                    border-bottom: 1px solid #ddd;
                    font-size:15px;
                    line-height: 17px;
                    font-weight: 700;
                }

            }

            .nav-logout {
                padding: 40px 16px;
                border-top: 0;
                text-align: center;
                .btn-logout {
                    display: inline-block;
                    padding:0;
                    font-size: 12px;
                    line-height: 17px;
                    text-decoration: underline;
                }
            }

            .mobile-nav-banner {
                margin:28px 0 0;
                border-top: 0;
                .event-link {
                    margin: 0 !important;
                }
            }
            .nav-inner .nav-brand-gate {
                display: none;
            }
        }
    }
}

/* BTOCSITE-1937 스프레드 메뉴 수정 start */
.super-category-nav {
    &.swiper-container {
        text-align: left;
        max-width:1760px;
        margin:0 auto 6px;
        padding:25px 40px 0;
        touch-action:pan-y;
        position:relative;
        overflow:hidden;
        list-style:none;
        z-index:1;
        box-shadow:none !important; //BTOCSITE-4602
        border-radius:0 !important; //BTOCSITE-4602

        .swiper-notification {
            position: absolute;
            left: 0;
            top: 0;
            pointer-events: none;
            opacity: 0;
            z-index: -1000;
        }

        .swiper-wrapper,
        .swiper-slide {
            transform:translate3d(0px, 0,0);
        }
        .swiper-wrapper {
            position: relative;
            width: 100%;
            height:100%;
            z-index:1;
            display: flex;
            transition-property: transform;
            box-sizing: content-box;
        }
        .swiper-slide {
            flex-shrink: 0;
            display: inline-block;
            position: relative;
            width:auto;
            margin-left:16px;
            padding-bottom:2px;
            transition-property: transform;

            &:first-child {
                margin-left:0;
            }
            // S : BTOCSITE-67128 닷컴 PC GNB내 제품 소모품 펼침 화면에서 각 브랜드 관 메뉴 구분 적용
            a {
                display: inline-block;
                font-size:14px;
                line-height:20px;
                color:#666;
            }

            &.on {
                a {
                    color:#0f0f0f;
                    font-weight:700;
                }
                &:before {
                    content:"";
                    position: absolute;
                    display: block;
                    height:2px;
                    left:0;
                    width:100%;
                    bottom:0;
                    background: #0f0f0f; 
                }
            }
            &.brand-menu {
                a {
                    color:#0f0f0f;
                }
                &.signature {
                    padding-left:33px;
                    margin-left:0;
                    &.on {
                        &:before {
                            width:calc(100% - 32px);
                            left:32px;
                        }
                    }
                    &:after {
                        content:'';
                        position:absolute;
                        top:50%;
                        left:16px;
                        width:1px;
                        height:12px;
                        background:#aaa;
                        transform:translateY(-50%);
                    }     
                }    
            }
            // E : BTOCSITE-67128 닷컴 PC GNB내 제품 소모품 펼침 화면에서 각 브랜드 관 메뉴 구분 적용
            // S : BTOCSITE-72997 [구독] 닷컴 카테고리 hierarchy 개선 요청 건
            &.util-menu {
                a {
                    color:#0f0f0f;
                }   
                &.line {
                    padding-left:33px;
                    margin-left:0;
                    &.on {
                        &:before {
                            width:calc(100% - 32px);
                            left:32px;
                        }
                    }
                    &:after {
                        content:'';
                        position:absolute;
                        top:50%;
                        left:16px;
                        width:1px;
                        height:12px;
                        background:#aaa;
                        transform:translateY(-50%);
                    }   
                }
            }
            // E : BTOCSITE-72997 [구독] 닷컴 카테고리 hierarchy 개선 요청 건
        }
    }
}
.super-category-content {
    display: none;
    &.on {
        display: block;
    }

    .ui_carousel_hidden {
        display: none !important;
    }
}
/* BTOCSITE-1937 스프레드 메뉴 수정 end */

/* 통합검색 레이어팝업 */
@import "../pages/UTS/SERF9001";

//앱 인 경우 퀵메뉴 리스트 안보이게
html {
    #appType {
        .appType-tab {
            display:none;
            & + .box {
                margin-top:0 !important;
            }
        }
    }
    @include mobile {
        #appType {
            .appType-tab {
                display:block;
                > div {
                    display:flex;
                    padding:6px 0 12px;
                    > button {
                        display:flex;
                        flex:1;
                        width:100%;
                        height:50px;
                        font-weight:700;
                        font-size:14px;
                        line-height:20px;
                        color:#111;
                        border:2px solid #fff;
                        border-radius:8px;
                        box-shadow:1px 2px 8px 0 rgba(0, 0, 0, 0.14);
                        background-color:#ffffff;
                        align-items:center;
                        justify-content:center;
                        i {
                            overflow:hidden;
                            display:inline-block;
                            width:18px;
                            height:18px;
                            margin:-2px 8px 0 0;
                            vertical-align:middle;
                            &.ico_lgeapp_direct{
                                background:url('/lg5-common/images/MAP/ico_keypad.png') no-repeat left top;
                                background-size:100%;
                            }
                            &.ico_lgeapp_barcode{
                                background:url('/lg5-common/images/MAP/ico_barcode.png') no-repeat left top;
                                background-size:100%;
                            }
                        }
                        &.on {
                            border-color:$color-primary;
                            box-shadow:0 3px 6px 0 rgba(0, 0, 0, 0.16);
                        }
                        & + button {
                            margin-left:12px;
                        }
                    }
                }
            }
        }
    }
    &.app {
        .login-mobile {
            .btn-mypage {
                // right: 30px;
                right: 60px; //210621 앱 알림함 아이콘 추가에 따른 my-page 아이콘 위치 조정 원복처리
            }
        }
        // .KRP0005, BTOCSITE-27076 공통 > 챗봇 아이콘 변경 및 활성화
        .KRP0032,
        .quick-menu-list,
        .easy-path {
            display: none !important;
        }

        .btn-app-ar {
            display:none;
            .app-ar {
                position:relative;
                transition:transform .5s ease-out;
                box-shadow:none;
                z-index: 110;
            }
            .app-ar-txt {
                display:block;
                font-size:14px;
                line-height:22px;
                white-space:nowrap;
                &:after {
                    content:' ';
                    display:inline-block;
                    width:16px;
                    height:16px;
                    margin-left:2px;
                    background:url('/lg5-common/images/icons/icon-float-arr-16.svg') no-repeat 0 0;
                    vertical-align:-2px;
                }
                i{
                    display:inline-block;
                    margin-right:6px;
                    width:18px;
                    height:19px;
                    background:url('/lg5-common/images/MAP/ico_ar.png') no-repeat left top;
                    background-size:100%;
                }
            }
            a, button {
                display:block !important;
                position: absolute;
                right: 0;
                top: 0;
                width:100% !important;
                height:100% !important;
                padding:0 !important;
                background: transparent !important;
                background-image: linear-gradient(135deg, #9c72e7 28%, #f85784 89%) !important;
                border-radius:28px !important;
                box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.15) !important;
                overflow: hidden !important;
                transition:width .5s;
                span {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    //font-weight: bold;
                    //font-size: 25px;
                    font-size: 18px;
                    line-height: 1.3;
                    color: #fff;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                }
                &.active {
                    width:260px !important;
                    span {
                        display:none;
                    }
                    .app-ar-txt {
                        display:flex;
                    }
                }
            }
        }

        #quickMenu {
            .floating-menu {
                position: relative;
                right: auto;
                bottom: auto;
            }
        }

        .floating-wrap {
            //노치 있음
            &.app-LGEAPP-in {
                //bottom:79px;
                bottom:40px;   // BTOCSITE-72152 [모니터링] PC에서 챗봇과 TOP버튼 겹침현상      // BTOCSITE-56610 LG그램 PDP 화면 진입시 최상단 올라가기 버튼 가려지는 현상
                z-index:10;
                &.app-chng-pos {
                    bottom:24px;
                }
                &.app-chng-push-pos {
                    bottom:24px;
                }
            }
            //노치 없음
            &.app-LGEAPP-io {
                //bottom:79px;
                bottom:40px;   // BTOCSITE-72152 [모니터링] PC에서 챗봇과 TOP버튼 겹침현상      // BTOCSITE-56610 LG그램 PDP 화면 진입시 최상단 올라가기 버튼 가려지는 현상
                z-index:10;
                &.app-chng-pos {
                    bottom:24px;
                }
                &.app-chng-push-pos {
                    bottom:24px;
                }
            }
            //안드로이드
            &.app-LGEAPP {
                //bottom:84px;
                bottom:40px;   // BTOCSITE-72152 [모니터링] PC에서 챗봇과 TOP버튼 겹침현상      // BTOCSITE-56610 LG그램 PDP 화면 진입시 최상단 올라가기 버튼 가려지는 현상
                z-index:10;
                &.app-chng-pos {
                    //bottom:84px;
                    bottom:24px;
                }
                &.app-chng-push-pos {
                    bottom:24px;
                }
            }
        }
    }
}


//BTOCSITE 2116 - 모바일(디바이스) 메인 헤더 스티키
.is-main-sticky-header {
    //BTOCSITE-1967 2차 추가수정 모바일 사이즈만 적용
    @include mobile {
        //.mobile-nav-wrap {
        //    position: sticky;
        //    display: block;
        //    top: 0;
        //    z-index:100;
        //    border-top:1px solid #fff;
        //    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1);
        //}

        #content.container {
            margin-top:-86px;
            padding-top:86px;
        }

        #sw_con {
            .swiper-wrapper,.swiper-slide {
                will-change: transform;
                transition-timing-function : cubic-bezier(0.49, 0.46, 1, 1)
            }
        }
    }
}


/* BTOCSITE-1967 */
.mdevice {
    @include mobile {
        .utility {
            right:11px;
        }
        .mobile-nav-button {
            display: none;
            &.active {
                display: block;
            }
        }
        .scroll-down {
            //BTOCSITE-7335
            .mobile-nav-button.active {
                top:54px;
                & + .header-bottom {
                    .login-mobile:before {
                        top:43px;
                    }
                }
            }
            .header-bottom {
                height:100vh;
                top:43px;
                // transform:translateY(43px);
            }
            .dimmed {
                height:100vh;
            }
        }

        &:not(.app) {
            .mobile-nav-button {
                & + .header-bottom {
                    padding-bottom:70px;
                }
            }
        }
    }
}

// LGECOMVIO-21 PC에서 전체메뉴 버튼 및 gnb 타이틀 여백 관련 분기 추가
@mixin pcGnb {
    @media all and (max-width: 767px) {
        .mobile-nav-button {
            display: block;
        }
    }

    .subRenewMainWrap,
    .subRenewPdpWrap,
    .subRenewWrap {
        &:not(.snsHead) {
            &:not(.searchHead) {
                &:not(.cartHead) {
                    .sub-renew-nav-wrap {
                        width: calc(100% - 120px);
                        padding-left: 50px;
                    }
                }
            }
        }

        &.snsHead {
            .sub-renew-nav-wrap {
                width: calc(100% - 90px);
            }
        }
    }
    .subRenewPdpWrap {
        &:not(.snsHead) {
            &:not(.searchHead) {
                &:not(.cartHead) {
                    .sub-renew-nav-wrap {
                        width: calc(100% - 160px);
                    }
                }
            }
        }
    }

    .utility {
        right: 51px;
    }
}

@media screen and (min-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
    @include pcGnb;
    @media screen and (max-width: 767px) {
        .mobile-nav-button {
            display: block;
        }
    }
}

@media screen and (min-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    @include pcGnb;
    @media screen and (max-width: 767px) {
        .mobile-nav-button {
            display: block;
        }
    }
}


/* BTOCSITE-6416 매장상담예약 노출 강화 (햄버거메뉴) */
.header-bottom{

    .marketing-link {
        /* BTOCSITE-10816 GNB 수정 컨텐츠 수정 */
        &.mob-navSlide_check {
            @include mobile {
                //color: #f00; //삭제됨
                .ui_carousel_track {
                    transform: translate3d(0px, 0px, 0px) !important;
                    width: 100% !important;
                    .ui_carousel_slide {
                        width: 100%;
                        padding-right: 0;
                        .banner-box {
                            width: 100%;
                            .mob-navSlideBan {
                                background-size: 100%;
                                background-position: center center;
                                height: 34.02868318122555vw;
                                min-height: 114px;
                                // width: 100%;
                                @media screen and (max-width: 450px) {
                                    height: 32.028683vw;
                                }
                                @media screen and (max-width: 365px) {
                                    height: 31.3283vw;
                                }
                                @media screen and (max-width: 350px) {
                                    background-size: cover;
                                    background-position: 100% 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
        /* //BTOCSITE-10816 GNB 수정 컨텐츠 수정 */
    }

    .store-counsel-banner{
        @include pc {
            display: none !important;
        }
        @include mobile{
            margin-top: 0; //BTOCSITE-10816
            >a{
                display:block;
                img{
                    width:100%;
                }
            }

            /* BTOCSITE-10034 야간무인매장 추가 */
            .mob_navBanner-content {
                position: relative;
                width: 100%;
                font-size: 13px;
                background-color: #f4f4f4;
                display: flex;
                justify-content: space-between;
                padding: 12px;
                .mob_navBanner-type1 {
                    display: flex;
                    justify-content: space-around;
                    background-color: #fff;
                    padding: 18px 0px;
                    border-radius: 8px;
                    width: 68%;
                    box-shadow: 1px 1px 16px 0 rgba(0, 0, 0, 0.04);
                    li {
                        // padding: 0 12px;
                        width: 50%;
                        text-align: center;
                        &:first-child {
                            position: relative;
                            &::after {
                                content: '';
                                width: 1px;
                                height: 100%;
                                background-color: #ddd;
                                position: absolute;
                                top: 0;
                                right: 0;
                            }
                        }
                        &.store_reservation {
                            position: relative;
                            a {
                                background-image: url('/lg5-common/images/icons/moBanIcon_storeReservation.png');
                            }
                        }
                        &.store_unmanned {
                            position: relative;
                            a {
                                background-image: url('/lg5-common/images/icons/moBanIcon_bestshop.png');
                            }
                        }
                        a {
                            display: block;
                            padding-top: 51px;
                            background-repeat: no-repeat;
                            background-size: 40px;
                            background-position: center 0px;
                        }
                    }
                }
                .mob_navBanner-type2 {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    background-color: #fff;
                    padding: 18px 12px;
                    border-radius: 8px;
                    width: 35%;
                    margin-left: 12px;
                    box-shadow: 1px 1px 16px 0 rgba(0, 0, 0, 0.04);
                    text-align: center;
                    a {
                        background-image: url('/lg5-common/images/icons/moBanIcon_catalogue.png');
                        background-repeat: no-repeat;
                        background-size: 40px;
                        background-position: center 0px;
                        padding-top: 51px;
                    }
                }
            }
            /* //BTOCSITE-10034 야간무인매장 추가 */
        }
    }
}


/*BTOCSITE-2117 공통 > 모바일 웹/앱 GNB 개선*/
@include mobile {
    .subRenewMainWrap,
    .subRenewPdpWrap,
    .subRenewWrap {
        // [BTOCSITE-21039] 인사이더관련 css 변경 요청건(삭제)
        // .ins-preview-wrapper {
        //     position: relative;
        //     z-index: 1;
        //     margin-top: 46px;
        // }

        .header {
            z-index: 100;
            position: fixed;
            top:0;
            width:100%;
            transition: background 0.3s ease, box-shadow 0.3s ease, top 0.3s ease;
            background: #fff;
            &.absolute {
                position: absolute;
            }
            &.no-bg {
                background: transparent;
                box-shadow: none;
            }
            &.has-shadow {
                box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
            }
            &.shadow-none {
                transition: box-shadow 0s linear;
                box-shadow: none !important;
            }
            &.hide {
                top: -46px;
            }
        }
        .header-top {
            padding: 11px 0;
            min-height: 46px;
        }
        .logo {
            display: none;
            a {
                // width: 91px; //   BTOCSITE-48637 B2B 복지몰(제휴사) 전시 구축
                height: 24px;
                &.bi-logo {
                    height: 16px;
                    &.symbol {
                        height: 24px;
                    }
                }
            }
        }
        //컨텐츠 내 상단 타이틀 히든처리
        .page-header {
            .title,
            .desc {
                display: none;
            }
        }
        .sub-renew-nav-wrap {
            z-index: 101;
            position: fixed;
            top: 0;
            left: 0;
            width: calc(100% - 86px);
            height: 46px;
            padding-left: 76px;
            text-align: center;
            background: none;
            transition: top 0.3s ease;
            &.hide {
                top: -46px;
            }
            .nav-item {
                position: relative;
                display: inline-block;
                width: 100%;
                padding: 12px 0;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                word-break: break-all;
                @media screen and (max-width: 353px) {
                    font-size: 14px;
                }
                // BTOCSITE-78612 [LGE.COM] 렛츠그램 개선 - 모바일 로고
                .logo_gram {
                    display: inline-block;
                    width: 90px;
                    height: 20px;
                    vertical-align: middle;
                    // background: #000;
                    // mask-image: url('/lg5-common/images/GRS/logo_gram_balck.svg');
                    // mask-repeat: no-repeat;
                    // mask-size: 100% auto;
                    // mask-position: 50% 50%;
                    background: url('/lg5-common/images/GRS/logo_gram_balck.svg') no-repeat 50% 50% / 100% auto;
                }
                // BTOCSITE-78612 [LGE.COM] 렛츠그램 개선 - 모바일 로고
            }
            .nav-category-container {
                top: 46px;
                overflow-y: auto;
                width: 100vw;
                max-height: 376px;
                padding: 0;
                border-top: solid 1px #f4f4f4;
                .superCategory {
                    position: relative;
                    padding: 12px 0;
                    height: 100%;
                    & > li {
                        display: inline-block;
                        width: 50%;
                        a {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 12px;
                            line-height: 17px;
                        }
                        > a {
                            color: #666;
                        }
                        &.isActive {
                            a {
                            }
                            > a {
                                font-weight: 700;
                                color: #000;
                            }
                        }
                    }
                }
                &.hasDepth {
                    .superCategory {
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: calc((210 / 360) * 100%);
                            height: 100%;
                            background: #f9f9f9;
                        }
                        & > li {
                            display: block;
                            width: calc((150 / 360) * 100%);
                            &.isActive {
                                .subCategory {
                                    display: block;
                                }
                            }
                        }
                    }
                    .subCategory {
                        //display: none;
                        //overflow-y: auto;
                        //position: absolute;
                        //padding: 12px 0 12px 6.5px;
                        //top: 0;
                        //left: calc((150 / 360) * 100% - 6.5px);
                        //width: calc((210 / 360) * 100% + 6.5px);
                        height: 100%;
                        z-index: 100;
                        .isActive {
                            //position: relative;
                            a {
                                font-weight: 700;
                                color: #000;
                            }
                            //&:before {
                            //    content: '';
                            //    display: block;
                            //    position: absolute;
                            //    top: 50%;
                            //    left: -6.5px;
                            //    width: 0;
                            //    height: 0;
                            //    transform: translateY(-50%);
                            //    border-right: 6.5px solid #f9f9f9;
                            //    border-top: 6.5px solid transparent;
                            //    border-bottom: 6.5px solid transparent;
                            //}
                        }
                        li {
                            a {
                                padding: 14.5px 16px;
                                font-size: 15px;
                                line-height: 21px;
                                color: #666;
                                text-align: center;
                            }
                        }
                    }
                }
            }
            .btn-back {
                position: absolute;
                top: 0;
                left: 0;
                min-height:24px;
                padding:23px;
                background-position: left 16px top 11px;
                background-size:24px;
                .text {
                    overflow: hidden;
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    padding: 0;
                    clip: rect(0, 0, 0, 0);
                    white-space: nowrap;
                }
            }
            &.isActive + .sub-renew-dimmed{
                display: block;
            }
            &.is-depth {
                .nav-item {
                    &:after {
                        position:relative;
                        display:inline-block;
                        right:-4px;
                        top: -3px;
                        width: 16px;
                        height: 16px;
                        margin-top: -12px;
                        background: url('/lg5-common/images/icons/icon_gnb_arrow_down_32.svg') no-repeat left center;
                        background-size: 100% 100%;
                        transform: none;
                        transition: all ease 200ms;
                        vertical-align: bottom;
                        content: '';
                    }
                    &.on {
                        &:after {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
            //not(.is-depth) : 2depth가 존재하지 않는 경우
            &:not(.is-depth) {
                .nav-item {
                    pointer-events: none;
                }
            }
        }
        .sub-renew-dimmed {
            display: none;
            position: fixed;
            top:0;
            left:0;
            right:0;
            bottom:0;
            width: 100%;
            height: 100%;
            background:rgba(0,0,0,0.7);
            z-index: 99;
        }
        &.wrap {
            //padding-top: 46px;
        }
        &.snsHead {
            .utility {
                display: none;
            }
            .sub-renew-nav-wrap {
                width: calc(100% - 58px);
            }
        }
        &.cartHead {
            .utility {
                display: none;
            }
            .sub-renew-nav-wrap {
                width: calc(100% - 76px);
            }
        }
        //&.searchHead {
        //    .header-wrap {
        //        border-bottom: none;
        //    }
        //    .sub-renew-nav-wrap {
        //        width: 50px;
        //        padding: 0;
        //        .btn-back {
        //            background-position: left 10px top 9px;
        //        }
        //    }
        //    .contents {
        //        &.search {
        //            .input-keyword-wrap {
        //                left: 50px;
        //                width: calc(100% - 50px);
        //                padding: 7px 16px;
        //                .input-keyword {
        //                    width: 100%;
        //                }
        //            }
        //        }
        //    }
        //}
        &.isActive {
            .header {
                //z-index 8000으로 줘야하는 이슈 재현되면 재확인, 일단 주석 처리 함
                //z-index: 8000;
            }
            .sub-renew-nav-wrap {
                //z-index 8000으로 줘야하는 이슈 재현되면 재확인, 일단 주석 처리 함
                //z-index: 8000;
            }
            .sub-renew-dimmed {
                //z-index: 7000;
            }
        }
        &.isHide {
            .sub-renew-nav-wrap {
                z-index: 99;
            }
        }
        &.hasFocusSkipContent {
            .skip-content a:focus {
                position: absolute;
            }
            .sub-renew-nav-wrap {
                z-index: 99;
            }
        }
    }
    //BTOCSITE-41849 [탐색고도화] 전체메뉴/GNB 개선
    .subRenewPdpWrap,
    .subRenewWrap {
        .logo {
            display: none !important;
        }
        .header-wrap.type-new{
            .header-top{
                .logo{
                    display:none !important; // BTOCSITE-48265 [전시] B2B OBS 구축                    
                }
            }
        }
    }


    .subRenewMainWrap {
        .logo {
            // display: block;
            // BTOCSITE-48637 B2B 복지몰(제휴사) 전시 구축
            display: flex;
        }
        .search-layer {
            .btn-close {
                @include mobile {
                    display: none !important;
                }
            }
            &.open {
                .btn-close {
                    @include mobile {
                        display: block !important;
                    }
                }
            }
        }
    }
    .subRenewPdpWrap {
        // BTOCSITE-55949 PDP 모바일 헤드> 전체메뉴 아이콘노출 건  
        .header-wrap:not(.type-new) {
            .utility {
                right: 11px;
                .shortcut {
                    > li {
                        &.home {
                            display: inline-block;
                        }
                    }
                }
            }
            .mobile-nav-button {
                display: none;
                &.done {
                    display: block;
                }
            }
            
        }
        // S : BTOCSITE-55949 PDP 모바일 헤드> 전체메뉴 아이콘노출 건  
        .is-pdpNav {
            .mobile-nav-button {
                display: block;
                position: absolute;
                top: 6px;
                left: 46px;
                width: 34px;
                height: 34px;
                padding: 5px;
                background: url('/lg5-common/images/icons/icon_menu_new.svg') no-repeat center center;   //  BTOCSITE-85601 GNB 공통영역 아이콘 통일화
                background-size: 24px;
            
                span[class^=line-],
                .mobile-nav-close {
                    display: none;
                }
                @media screen and (min-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
                    @include mobile{
                        display:block;
                    }
                }
            
                &.active {
                    position: fixed;
                    top:14px;
                    left: 56px;
                    z-index: 112;
                    width: auto;
                    height: auto;
                    padding: 0;
                    background: none;
                    .mobile-nav-close {
                        display: block;
                        width: 34px;
                        height: 34px;
                        padding: 5px;
                        background: url('/lg5-common/images/icons/btn-close-24-black.svg') no-repeat center center;
                    }
            
                }
            }
        }
        // E : BTOCSITE-55949 PDP 모바일 헤드> 전체메뉴 아이콘노출 건  
        
        .sub-renew-nav-wrap {
            width: calc(100% - 126px);
            &.absolute {
                position: absolute;
            }
        }
        .header {
            .header-status-bar-background {
                display: block;
            }
            &.no-bg {
                .header-status-bar-background {
                    background: transparent;
                }
            }
        }
        .search-layer {
            .btn-close {
                @include mobile {
                    display: none !important;
                }
            }
            &.open {
                .btn-close {
                    @include mobile {
                        display: block !important;
                    }
                }
            }
        }
        // S : BTOCSITE-78612 [LGE.COM] 렛츠그램 개선
        &.gram-only {
            padding-top: 46px;
            .sub-renew-nav-wrap {
                @include mobile {
                    width: calc(100% - 86px);
                }
            }
            .header-wrap:not(.type-new) {
                .utility {
                    .shortcut {
                        > li {
                            &.home {
                                display: none;
                            }
                        }
                    }
                }
            }
            &.hellobar-wrap {
                padding-top: 88px;
            }
        }
        // E : BTOCSITE-78612 [LGE.COM] 렛츠그램 개선
    }
    .subRenewWrap {
        padding-top: 46px;
        &.hellobar-wrap {
            padding-top: 88px;
            .container-fluid {
                &:first-child {
                    margin-top: -88px;
                }
                #content {
                    min-height: 88px;
                }
            }
        }
        .container-fluid {
            &:first-child {
                margin-top: -46px;
            }
            // [BTOCSITE-21039] 인사이더관련 css 변경 요청건(삭제)
            // .ins-preview-wrapper {
            //     margin-top: 0;
            // }

            #content {
                min-height: 46px;
            }
        }

        .sub-renew-nav-wrap {
            &.absolute {
                position: absolute;
            }
        }
        &.is-search {
            .header-top,
            .mobile-nav-wrap {  display: none; }
        }
        &--share {
            .utility {
                .shortcut {
                    > li {
                        &.search,
                        &.cart {
                            display: none;
                        }
                        &.share {
                            display: inline-block;
                            .tooltip-wrap {
                              &.share {
                                  width: 34px;
                                  height: 34px;
                                  padding: 0;
                                  .tooltip-icon {
                                      width: 34px;
                                      height: 34px;
                                      background: none;
                                  }
                              }
                            }
                        }
                    }
                }
            }
            //BTOCSITE-20618(스토리 상세 페이지 모바일 gnb 적용)
            .sns-share, .component.KRP0016, .lgestg-sns-box {
                display:none;
            }
            
            // S :  BTOCSITE-48265 [전시] B2B OBS 구축 
            .header-wrap:not(.type-new) {
                .utility {
                    .shortcut {
                        > li {
                            &.search,
                            &.cart {
                                display: none;
                            }
                            &.share {
                                display: inline-block;
                                .tooltip-wrap {
                                  &.share {
                                      width: 34px;
                                      height: 34px;
                                      padding: 0;
                                      .tooltip-icon {
                                          width: 34px;
                                          height: 34px;
                                          background: none;
                                      }
                                  }
                                }
                            }
                        }
                    }
                }
            }
            //E :  BTOCSITE-48265 [전시] B2B OBS 구축 
        }
        //BTOCSITE-24502(주문 심플GNB 내 홈버튼 추가요청)
        &--cartOrder {
            .utility {
                //display: none;
                .shortcut {
                    > li {
                        &.search {
                            display: none;
                        }
                        &.cart {
                            display: none;
                        }
                        &.home {
                            display: inline-block;
                        }
                    }
                }
            }

            // S :  BTOCSITE-48265 [전시] B2B OBS 구축 
            .header-wrap:not(.type-new) {
                .utility {
                    //display: none;
                    .shortcut {
                        > li {
                            &.search {
                                display: none;
                            }
                            &.cart {
                                display: none;
                            }
                            &.home {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
            //E :  BTOCSITE-48265 [전시] B2B OBS 구축 
        }
        // BTOCSITE-71915 [스토리개편] 홈메인 전시개편
        &--story {
            .nav-story-wrap {
                display: block;
            }
            .component.KRP0016 {
                display: none;
            }
        }
    }

    html:not(.mobile.mdevice) {
        //.subRenewMainWrap,
        //.subRenewPdpWrap,
        //.subRenewWrap {
        //    &:not(.snsHead) {
        //        &:not(.searchHead) {
        //            &:not(.cartHead) {
        //                .sub-renew-nav-wrap {
        //                    width: calc(100% - 120px);
        //                    padding-left: 50px;
        //                }
        //            }
        //        }
        //    }
        //    &.snsHead {
        //        .sub-renew-nav-wrap {
        //            width: calc(100% - 90px);
        //        }
        //    }
        //}
        //.subRenewPdpWrap {
        //    &:not(.snsHead) {
        //        &:not(.searchHead) {
        //            &:not(.cartHead) {
        //                .sub-renew-nav-wrap {
        //                    width: calc(100% - 160px);
        //                }
        //            }
        //        }
        //    }
        //}
    }

    // S : BTOCSITE-56491 [전시] 앱 다운로드 유도 배너 개발 요청
    .hellobar-wrap {
        // _RMSF2015.scss - 구독
        .contents.benefit {
            .ui_sticky.fixed {
                @include mobile {
                    margin-top: 68px;
                }
            }
        }

        // Catalog.scss - 제품 카탈로그
        .catal-list-wrap {
            .catal-tab {
                .ui_sticky {
                    &.fixed{
                        @include mobile{
                            top:88px !important;
                        }
                    }
                }
            }
        }
        .ui_sticky_wrap.down {
            top: 88px !important;
        }

        .plp-new {
            .cate-m {
                .cate-wrap.fixed.top-extand {
                    top: 88px !important;
                }
            }
        }
        // bestRanking.scss
        .best-ranking .sub-main .ranking__top .form-wrap.filter-tab-wrap.down {
            top:88px;   //  BTOCSITE-56491 [전시] 앱 다운로드 유도 배너 개발 요청
        }

        .header-wrap:not(.type-new) {
            .mobile-nav-button:not(.active) {
                top: 48px;
            }
        }

        // 기획전 상세  탭
        .tab_btn_wrap {
            top: 86px !important;
        }
        //  검색결과 화면
        //.search-layer.re22 .input-keyword,
        &.wrap .header:not(.hide) ~ .container .contents.search.re22 .input-keyword {
            top: 42px;
            transition: top .3s ease;
        }
        &.wrap .header:not(.hide) ~ .container .contents.search.re22.re23 .nafilter_tabs {
            top: 106px;
            transition: top .3s ease;
        }
        &.wrap .header:not(.hide) ~ .container .contents.search.re22.re23 .nafilter_choice {
            top: 163px;
            transition: top .3s ease;
        }

        // 다품목 기획전 혜택안내 내부 탭 위치 조정
        .promo-benefit-cont {
            .promo-sticky-wrap {
                &.up {
    
                }
                &.down {
                    top: 136px;
                }
            }
        }
        
        // S : BTOCSITE-78612 [LGE.COM] 렛츠그램 개선
        .gram-cont {            
            .logo-tab-wrap  {
                &:has(.ui_sticky_wrap) {
                    & ~ .gr-search-wrap:not(.expanded) {
                        top: 134px;
                    }
                }
                &:has(.ui_sticky_wrap.down) {
                    & ~ .gr-search-wrap {
                        &.fixed {
                            top: 134px;
                            transition: top 0.3s ease;
                        }
                    }
                }
            }
            .gr-prod-info-wrap.gr-prod-compare {
                .cont-wrap {
                    .ui_sticky.fixed {
                        &.down {
                            top: 134px !important;
                            transition: top 0.3s ease;
                        }
                    }
                }
            }
        }
        // E : BTOCSITE-78612 [LGE.COM] 렛츠그램 개선
    }
    // E : BTOCSITE-56491 [전시] 앱 다운로드 유도 배너 개발 요청
}

/* pdp 화면에서  header z-index 수정 */
.scroll-lock {
    .subRenewPdpWrap {
        .header {
            z-index: 101;
        }
        .sub-renew-nav-wrap {
            z-index: 99;
        }
    }
}

/* BTOCSITE-22241	IE 접속 시 안내 팝업 노출 건 */
.header{
    @include pc{
        .ie-notice-wrap{
            display:none;
            background:#f4f4f4;
            .ie-notice{
                position:relative;
                margin:0 auto;
                padding:32px 30px;
                max-width:1760px;
                .txt{
                    max-width:calc(100% - 170px);
                    font-size:16px;
                    line-height:24px;
                    word-break:keep-all;
                    strong{
                        position:relative;
                        display:inline-block;
                        &:after{
                            content:'';
                            position:absolute;
                            left:-3px;
                            right:-3px;
                            bottom:0;
                            height:12px;
                            background:#e81953;
                            opacity:0.15;
                        }
                    }
                }
                .browser-area{
                    display:flex;
                    margin-top:12px;
                    .edge, .chrome{
                        position:relative;
                        margin-right:18px;
                        padding:0 17px 0 36px;
                        line-height:32px;
                        font-size:14px;
                        &:before{
                            content:'';
                            position:absolute;
                            top:0;
                            left:0;
                            width:32px;
                            height:32px;
                        }
                        &:after{
                            content:'';
                            position:absolute;
                            top:50%;
                            right:0;
                            width:16px;
                            height:16px;
                            background: url("/lg5-common/images/icons/btn-arr-16-black-sm.svg") no-repeat;
                            transform:translateY(-50%);
                        }
                    }
                    .edge{
                        &:before{
                            background: url("/lg5-common/images/header/icon-edge.png") no-repeat;
                        }
                    }
                    .chrome{
                        &:before{
                            background: url("/lg5-common/images/header/icon-chrome.png") no-repeat;
                        }
                    }
                }
                .close{
                    position:absolute;
                    top:50%;
                    right:30px;
                    transform:translateY(-50%);
                }
            }
            ~.header-wrap{
                .dimmed{
                    top:261px;
                    @include tablet{
                        top:310px;
                    }
                }
            }
        }
    }
    @include mobile{
        .ie-notice-wrap{
            display:none !important;
        }
    }
}
/* //BTOCSITE-22241	IE 접속 시 안내 팝업 노출 건 */

/* BTOCSITE-33562 [개인맞춤서비스] 구매가망고객 추천 - 홈메인 개발 */
.header{
    .hello-bar-wrap{
        display:none;
        // BTOCSITE-48637 B2B 복지몰(제휴사) 전시 구축
        &.emp-hello {
            background-color: #333;
        }
    }
    &.helloBar{
        // BTOCSITE-48637 B2B 복지몰(제휴사) 전시 구축
        &[data-hellobar='helloEmp'] {
            .hello-bar-wrap{
                display: none;
                &.emp-hello {
                    display: block;
                }
            }
            
            .btn-nav-arrow {
                top: 102px;
            }
            @include mobile {
                .utility {
                    top: 48px;
                }
                & ~ .container {
                    .main {
                        &-renewal {
                            padding-top: 42px;  // 띠배너 관련 수정
                        }
                    }
                }
            }
        }
        // BTOCSITE-56491 [전시] 앱 다운로드 유도 배너 개발 요청
        &[data-hellobanner='helloBanner'] {
            & ~ .container {
                .main {
                    &-renewal {
                        @include mobile {
                            padding-top: 42px;
                        }
                    }
                }
            }
            
        }
        .hello-bar-wrap{
            position:relative;
            z-index:10;
            display:block;
            height:42px;
            // S : BTOCSITE-56491 [전시] 앱 다운로드 유도 배너 개발 요청
            &.hello-app {
                display: none;
                background: #333 !important;
                @include tablet {
                    display: block;
                    // height: 38px;
                }
                @include mobile {
                    // height: 42px;
                }

                .txt-wrap {
                    padding-right: 48px;
                    a {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                    }
                    @include tablet {
                        padding-right: 84px;
                        padding-left: 42px;
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: 700;
                    }
                    @include mobile {
                        padding-left: 16px;
                        padding-right: 48px;
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: 700;
                    }
                    @media screen and (max-width: 280px) {
                        font-size: 12px;
                    }
                    .tit {
                        @include mobile {
                            padding-right: 16px;
                        }
                    }
                    .close {
                        @include tablet {
                            right: 52px;
                        }
                        @include mobile {
                            right: 0;
                            width: 32px;
                        }
                        &::before {
                            @include tablet {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }
                .txt-app {
                    margin-left: auto;
                    padding: 0 12px;
                    height: 24px;
                    border-radius: 16px;
                    font-size: 12px;
                    line-height: 24px;
                    color: #fff;
                    background: #ea1917;
                    @media screen and (max-width: 280px) {
                        padding: 0 8px;
                        font-size: 10px;
                    }
                }
            }
            // E : BTOCSITE-56491 [전시] 앱 다운로드 유도 배너 개발 요청
            .txt-wrap{
                display:flex;
                align-items:center;
                position:relative;
                margin:0 auto;
                max-width:1380px;
                height:100%;
                text-align:center;
                font-weight:500;
                font-size:16px;
                @include pc{
                    justify-content:center;
                }
                @include mobile{
                    padding:0 16px;
                    font-size:11px;
                }
                .tit{
                    color:#fff;
                }
                .txt{
                    margin-left:8px;
                    color:#ff84bd;
                    @include mobile{
                        margin-left:6px;
                    }
                }
                .ico{
                    margin-left:8px;
                    width:32px;
                    height:32px;
                    @include mobile{
                        margin-left:6px;
                    }
                    img{
                        max-width:100%;
                    }
                }
                .close{
                    position:absolute;
                    right:0;
                    width:24px;
                    height:42px;
                    @include mobile{
                        width:48px;
                    }
                    &:before{
                        content:'';
                        position:absolute;
                        top:50%;
                        right:0;
                        width:24px;
                        height:24px;
                        transform:translateY(-50%);
                        background:url("/kr/images/gnb/icon_close_white_24.svg") no-repeat;
                        background-size:100% 100%;
                        @include mobile{
                            right:16px;
                            width:16px;
                            height:16px;
                        }
                    }
                }
                // BTOCSITE-48637 B2B 복지몰(제휴사) 전시 구축
                .txt-desc {
                    position: relative;
                    padding-right: 20px;
                    font-weight: $font-weight-regular;
                    color: #fff;
                    &:after {
                        content: '';
                        position: absolute;
                        top: 1px;
                        right: 0;
                        width: 16px;
                        height: 16px;
                        background: url('/lg5-common/images/icons/btn-arrow-right-wt-32.svg') no-repeat 50% 50% / 20px auto;
                    }

                    @include mobile {
                        display: block;
                        width: 100%;
                        text-align: left;
                        @include textClipClamp(1);
                        &:after {
                            top: -2px;
                            right: 0;
                            background-size: 16px 16px;
                        }
                    }
                }
            }
            ~.header-wrap{
                //position:relative;
                .dimmed{
                    top:261px;
                    @include tablet{
                        top:310px;
                    }
                }
            }
        }
        ~.mobile-nav-wrap{
            top:42px;
        }
        &.hide{
            top:-88px;
            transition: top 0.3s ease;
            ~.mobile-nav-wrap{
                top:-46px;
            }
        }
        // BTOCSITE-48265 [전시] B2B OBS 구축
        @include pc {
            .header-wrap:not(.type-new) .nav-outer-link {
                top: 61px;
            }
        }
        // S : BTOCSITE-56491 [전시] 앱 다운로드 유도 배너 개발 요청
        .header-wrap {
            .utility {
                top: 48px;
            }
        }
        // E : BTOCSITE-56491 [전시] 앱 다운로드 유도 배너 개발 요청
    }
}
/* //BTOCSITE-33562 [개인맞춤서비스] 구매가망고객 추천 - 홈메인 개발 */

/* BTOCSITE-41849 [탐색고도화] 전체메뉴/GNB 개선 */
// 홈메인 헤더 백버튼 보이게
.header-wrap:not(.type-new) { // b2c에서 사업자몰 피씨 온라인 링크 구분 
    .nav-outer-link {
        @include pc {
            display: none;
        }
        @include mobile {
            display: block;
        }
        .links li {
            &.pc-only {
                display: inline-block;
            }
            &.mo-only {
                display: none;
            }
        }
    }
}
.subRenewMainWrap.isHide {
    .mobile-nav-wrap.edmMain {
        z-index: 99;
    }
}
.subRenewWrap.isHide {
    @include mobile {
        .mobile-status-bar.edm-status {
            display: none;
        }
    }
}
.mobile-nav-wrap.edmMain {
    position: fixed;
    top: 6px;
    left: 7px;
    z-index: 101;
    background-color: transparent;
    transition: top .3s ease;
    &.absolute {
        position: absolute;
    }
    &.hide {
        top: -46px;
    }
    .btn-back {
        background-position: center;
        background-size: 24px;
    }
}
/* BTOCSITE-48265 [전시] B2B OBS 구축 -  사업자몰에서 사용 */
.header-wrap.type-new {
    @include mobile {
        .pc-nav-inner,
        .category-etc,
        .utility .dropdown-wrap,
        .utility-top {
            display: none;
        }
    }    
    .mobile-nav-button {
        // LGECOMVIO-21 추가
        display: none;
        position: absolute;
        top: 6px;
        right: 11px;
        width: 34px;
        height: 34px;
        padding: 5px;
        background: url('/lg5-common/images/EDM/ic_toolbar_b2b_menu_off_24.svg') no-repeat center center;
        background-size: 24px;
    
        span[class^=line-],
        .mobile-nav-close {
            display: none;
        }
        @media screen and (min-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
            @include mobile{
                display:block;
            }
        }
        @include mobile {
            & + .header-bottom {
                display: block;
                position: fixed;
                top: 0;
                right: -100%;
                bottom: 0;
                z-index: 111;
                transition: right .5s;
                width: calc(100% - 20px);
                overflow-y: auto;
                padding-top: 60px;
                background-color: #fff;
            }
    
            //210701 앱 알람아이콘 N 뱃지 추가
            .count{
                position: absolute;
                top: 2px;
                left: 18px;
                display: block;
                min-width: 14px;
                height: 14px;
                padding: 2px 2px;
                border-radius: 8px;
                background-color: $color-primary;
                font-weight: 700;
                font-size: 10px;
                line-height: 10px;
                color: $color-white;
                text-align: center;
            }
            //210701 앱 알람아이콘 N 뱃지 추가
        }
    
        &.active {
            position: fixed;
            top:14px;
            right: 11px;
            z-index: 112;
            width: auto;
            height: auto;
            padding: 0;
            background: none;
            .mobile-nav-close {
                display: block;
                width: 34px;
                height: 34px;
                padding: 5px;
                background: url('/lg5-common/images/icons/btn-close-24-black.svg') no-repeat center center;
            }
    
            @include mobile {
                & + .header-bottom {
                    right: 0;
                    .login-mobile {
                        &:before {
                            right: 0;
                        }
                    }
                    &.app-btm {
                        padding-bottom:60px;
                    }
                }
                //210701 앱 알람아이콘 N 뱃지 추가
                .count {
                    display: none;
                }
                //210701 앱 알람아이콘 N 뱃지 추가
    
            }
        }
    }
    
    .login-mobile {
        padding: 0 16px;
        border-bottom: 12px solid $color-bg;
        @include mobile {
            isolation: isolate;
        }
        &:before {
            position: fixed;
            top: 0;
            right: -100%;
            z-index: 112;
            transition: right .5s;
            width: calc(100% - 20px);
            height: 60px;
            background-color: $color-bg-wt;
            content: '';
        }
    
        .login-info {
            position: relative;
            padding:0;
            line-height:26px;
            .text {
                font-weight:700;
                font-size:18px;
                &:after {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    margin-left:4px;
                    background: url("/lg5-common/images/EDM/edm_header/icon_arrow_right_16.svg") center center no-repeat;
                    background-size: 100% 100%;
                    transform: none;
                    vertical-align: -1px;
                    content: '';
                }
            }
            &.after-login {
                .btn-mypage {
                    &:after {
                        position: absolute;
                        top: 6px;
                        right: 6px;
                        width: 4px;
                        height: 4px;
                        border-radius: 2px;
                        background-color: $color-primary;
                        content: '';
                    }
                }
            }
        }
        
        .button-area{
            display:flex !important;
            position:absolute;
            top:12px;
            right:52px;
            >button{
                position:relative;
                margin-left:12px;
                width:24px;
                height:24px;            
                &.search-button{
                    background:url("/lg5-common/images/icons/icon_search_new.svg") no-repeat center center;  // BTOCSITE-85601 GNB 공통영역 아이콘 통일화
                    background-size:24px;
                }
                &.app-settings-button {
                    display:none;
                    // background: url('/lg5-common/images/header/icon-app-settings-new-white.svg') no-repeat center center; // BTOCSITE-20264
                    background: url('/lg5-common/images/icons/icon-app-settings-new.svg') no-repeat center center;
                    background-size: 24px;
                    &.active {
                        display: block;
                    }
                }
                    
                &.app-alarm-button{
                    display:none;
                    // background:url('/lg5-common/images/header/icon-app-alarm-white.svg') no-repeat center center;
                    background:url('/lg5-common/images/icons/icon-app-alarm.svg') no-repeat center center;
                    background-size:24px;
                    &.active{
                        display:block;
                    }
                    .app-alarm-count{
                        display:none;
                        position:absolute;
                        left:13px;
                        top:3px;
                        padding:2px 4px;
                        min-width:14px;
                        height:14px;
                        border-radius:7px;
                        background-color:$color-primary;
                        text-align:center;
                        line-height:10px;
                        font-weight:700;
                        font-size:10px;
                        color:#fff;
                        &.active{
                            display:block;
                        }
                    }
                }
            }
        }
        .btn-mypage {
            position: absolute;
            top: -1px;
            right: 0;
            z-index: 1;
            a {
                display: block;
                width: 34px;
                height: 34px;
                background: url('/lg5-common/images/icons/icon-person-48-m.svg') no-repeat center center;
                background-size: 24px;
            }
        }
    }
    
    .mobile-nav-banner {
        border-top: 12px solid $color-bg;
        .banner-inner {
            position: relative;
            img {
                width: 100%;
            }
            .banner-text {
                position: absolute;
                top: 24px;
                left: 16px;
                color: $color-white;
                .title {
                    display: block;
                    margin-bottom: 12px;
                    font-weight: 700;
                    font-size: $font-large;
                    line-height: 26px;
                }
                .copy {
                    font-size: $font-small - 1;
                    line-height: 21px;
                }
            }
        }
    }
    .dimmed {
        display: none;
        position: fixed;
        top: 126px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 101;
        background-color: rgba(0,0,0,.56);
        content: '';
        @include mobile {
            top: 0;
            z-index: 106;
        }
    }
    .header-top {
        // S : BTOCSITE-57367 [전시] 리뷰 내재화(크레마 솔루션제거)
        .utility {
            .shortcut {
                > li {
                    &.mypage {
                        .mypage-layer {
                            .mypage-layer-inner {
                                .mypage-shortcut {
                                    // S : BTOCSITE-57367 [전시] 리뷰 내재화(크레마 솔루션제거)
                                    li {
                                        .count {
                                            position:initial;
                                            display:inline-block;
                                            border-radius:100%;
                                            background-color:#000;
                                            color:#fff;
                                            padding:0 4px;
                                            height:20px;
                                            line-height:20px;
                                            min-width:20px;
                                            margin:2px 0 0 2px;
                                            vertical-align:top;
                                        }
                                    }
                                    // E : BTOCSITE-57367 [전시] 리뷰 내재화(크레마 솔루션제거)
                                }
                            }
                        }
                    }
                }
            }
        }
        // E : BTOCSITE-57367 [전시] 리뷰 내재화(크레마 솔루션제거)
        @include clearfix;
        .logo{
            display: flex;
            align-items: center;
            @include pc{
                padding-left: 0;
                float:left;
                a{
                    display: block;
                    position:relative;
                    top:0;
                }
            }   
            a {
                display: block;
                &:not(:first-child) {
                    position: relative;
                    margin-left: 16px;
                    padding-left: 17px;
                    width: auto;
                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        display: block;
                        width: 1px;
                        height: 20px;
                        background: #ddd;
                        transform: translateY(-50%);
                    }
                }
                >img {
                    height: 100%;
                    vertical-align: top;
                }
            }
            .lg-logo{
                height: 30px;
            }
            .bi-logo2 {
                width: auto;
                height: 20px;
                background: none;
                &.symbol {
                    height: 30px;
                }
            }
            @include mobile {
                margin-left: 26px;
            }
        }
    }
    
    .nav-category-container {
        display: none;
        @include pc {
            > ul {
                @include clearfix;
                padding-left: 5px;
                > li {
                    display: inline-block;
                }
            }
        }
    }
    .nav-category-layer {
        display: none;
    
        @include pc {
            .category-title {
                margin-bottom: 32px;
                font-weight: 700;
                @include font-large-1;
            }
    
            
        }
    
        .category{
            > li {
                margin-bottom:30px;
                &:last-child {
                    margin-bottom: 0;
                }
    
                .category-item {
                    display: inline-block;
                    font-weight:700;
                    font-size:14px;
                    line-height:20px;
                    color:#333;
                    &:hover,
                    &:focus,
                    &.active{
                        color:$color-primary;
                    }
                    +.sub-link{
                        margin-top:20px;
                    }
                    +.sub-category{
                        margin-top:10px;
                    }
                }
                a.category-item {
                    &[target="_blank"] {
                        padding-right:20px;
                        background-image: url("/lg5-common/images/header/btn-outlink-10.svg");
                        background-repeat:no-repeat;
                        background-position:right 6px;
                    }
                }
                .sub-link{
                    margin-bottom:20px;
                    >li{
                        margin-bottom:9px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        >a{
                            display:block;
                            .txt{
                                margin-right:5px;
                                font-weight:500;
                                font-size:12px;
                                color:#666;
                                line-height:17px;
                                white-space:initial;
                            }
                            .badge{
                                display:inline-block;
                                position:relative;
                                top:3px;
                                max-width:100%;
                                padding:3px 4px;
                                border-radius:10px;
                                overflow:hidden;
                                >em{
                                    display:inline-block;
                                    max-width:100%;
                                    font-size:10px;
                                    line-height:1;
                                    overflow:hidden;
                                    zoom:.7;
                                }
                                &.new{
                                    border:1px solid #008182;
                                    >em{
                                        color:#008182;
                                    }
                                }
                                &.hot{
                                    border:1px solid $color-primary;
                                    >em{
                                        color:$color-primary;
                                    }
                                }
                            }
                        }
                    }
                }
                .sub-category {
                    >li {
                        margin-bottom: 8px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        a {
                            font-size:14px;
                            line-height:21px;
                            color: #666;
                            white-space:normal;
                            &:hover, &:focus {
                                @include pc {
                                    color:$color-primary;
                                }
                            }
                            &[target='_blank'] {
                                background: url('/lg5-common/images/icons/btn-outlink-16.svg') no-repeat right top 5px;
                                @include mobile {
                                    background-position: right 28px top 8px;
                                }
                            }
                        }
                    }
                }
            }
            & + .category-related{
                margin-top: 48px;
            }
        }
    
        .category-related {
            margin-bottom: 32px;
            .link-item {
                display: inline-block;
                padding-right: 20px;
                margin-bottom: 4px;
                background: url('/lg5-common/images/icons/btn-arr-16-black-sm.svg') no-repeat right center;
                @include font-small;
                text-decoration: underline;
            }
        }
    
        .category-etc {
            position: relative;
            .category-hashtag{
                li{
                    margin-bottom:5px;
                    &:last-of-type{
                        margin-bottom:0;
                    }
                    .tag-item {
                        display: inline-block;
                        padding:9px 16px;
                        border:1px solid #ddd;
                        border-radius:17px;
                        font-weight: 500;
                        font-size:14px;
                        color:#222;   
                    }
                }
                +.category-banner{
                    margin-top:30px;
                }
                +.bg-banner{
                    margin-top:30px;
                }
            }
            .category-banner{
                position:relative;
                overflow:hidden;
                box-sizing: border-box;
                &.solo{
                    padding-bottom: 0;
                    .custom-indi-wrap{
                        display:none;
                    }
                }
                .custom-indi-wrap {
                    position: absolute;
                    top:164px;
                    left:0;
                    right:0;
                    @include tablet-main{
                        top:105px;
                    }
                    .slide-controls {
                        position:relative;
                        max-width:none;
                        margin:0 auto;
                        .controls{
                            position:absolute;
                            top:0;
                            left:50%;
                            width:109px;
                            height:30px;
                            border-radius:15px;
                            background:rgba(0, 0, 0, 0.5);
                            text-align: center;
                            transform:translateX(-50%);
                            @include tablet-main{
                                width:84px;
                                height:22px;
                            }
                        }
                        .btn-arrow {
                            position:absolute;
                            top:0;
                            padding:0;
                            width:30px;
                            height:30px;
                            transform:none;
                            @include tablet-main{
                                width:20px;
                                height:20px;
                            }
                            &:before{
                                content:'';
                                position:absolute;
                                top:50%;
                                left:50%;
                                width:16px;
                                height:16px;
                                box-shadow:none;
                                transform:translate(-50%, -50%);
                            }
                            &.ico-prev {
                                left:0;
                                &:before{
                                    background-image: url(/lg5-common/images/icons/btn-arr-24x24-lt.svg);
                                }
                            }
                            &.ico-next {
                                right:0;
                                &:before{
                                    background-image: url(/lg5-common/images/icons/btn-arr-24x24-rt.svg);
                                }
                            }
                        }
                        .slide-page{
                            padding:0 4px;
                            color:#fff;
                            font-size:16px;
                            line-height:30px;
                            font-weight:500;
                            @include tablet-main{
                                font-size:12px;
                                line-height:22px;
                            }
                            .count{
                                color:rgba(255,255,255,0.7);
                                &:before{
                                    content:'/';
                                    margin:0 8px;
                                    color:#fff;
                                    @include mobile {
                                        margin:0 4px;
                                    }
                                }
                            }
                        }
                    }
                    .indi-control {
                        display: block !important;
                        position: absolute;
                        bottom:0;
                        right:117px;
                        margin-right:0;
                        width:36px;
                        height:36px;
                        @include tablet {
                            right: 72px;
                        }
                        @include mobile {
                            width: 20px;
                            height: 20px;
                            right:20px;
                        }
                        &::before{
                            content: "";
                            display: block;
                            width:36px;
                            height:36px;                        
                            @include mobile {
                                width: 20px;
                                height: 20px;
                            }
                        }
                        &.play{
                            &::before{
                                background: url(/lg5-common/images/icons/btn-carousel-46-play.svg) no-repeat 0 0;
                                background-size: 100%;
                                @include mobile {
                                    background-size: 20px;
                                }
                            }
                        }
                        &.stop{
                            &::before{
                                background: url(/lg5-common/images/icons/btn-carousel-46-pause.svg) no-repeat 0 0;
                                background-size: 100%;
                                @include mobile {
                                    background-size: 20px;
                                }
                            }
                        }
                    }
                }
                img {
                    max-width: 100%;
                    border-radius: 8px;
                }
                .banner-box{
                    display:block;
                    text-align:center;
                    .banner-text {
                        margin-top:20px;
                        white-space:normal;
                        .sub-text{
                            display:block;
                            height:20px;
                            font-size:14px;
                            line-height:20px;
                            color:#333;
                            overflow:hidden;
                        }
                        .text{
                            display:block;
                            margin-top:4px;
                            height:46px;
                            font-weight:500;
                            font-size:16px;
                            color:#333;
                            line-height:23px;
                            overflow:hidden;
                        }
                    }
                }
                +.bg-banner{
                    margin-top:30px;
                }
            }
            .bg-banner{
                .banner-link{
                    display:flex;
                    align-items:center;
                    padding:5px 20px 5px 26px;
                    height:72px;
                    background:#f4f4f4;
                    border-radius:8px;
                    .tit{
                        flex:1;
                        width:calc(100% - 66px);
                        font-size:16px;
                        font-weight:500;
                        color:#333;
                        white-space:normal;
                        line-height:23px;
                    }
                    .thumb{
                        width:66px;
                        height:62px;
                        >img{
                            max-width:100%;
                        }
                    }
                }
            }
        }
    
        .category-image-box {
            .category {
                > li {
                    margin-bottom: 0;
                    .category-item {
                        margin-bottom: 16px;
                    }
                }
                &.brand-category {
                    padding: 22px 0 0 20px;
                }
            }
            .category-image {
                border-radius: 8px;
                img {
                    width: 100%;
                    border-radius: 8px;
                }
            }
            .category-content {
                a {
                    display: inline-block;
                    margin-top: 8px;
                    font-size: 14px;
                    line-height: 20px;
                    color: #111;
                }
            }
        }
    }

    @include pc{
        max-width:1720px;
        margin: 0 auto;
        padding:0 40px;
        .inner{
            position:relative;
            margin:0 auto;
        }
        .header-top{
            padding:18px 0 22px;
        }
        .header-bottom{
            .nav-wrap{
                .logo{
                    display:none;
                    a{
                        display:block;
                        width:120px;
                        height:32px;
                        background-image: url("/lg5-common/images/header/lg_logo-pc.svg");
                    }
                }
            }
            .btn-emp, .btn-edm{
                // BTOCSITE-48265 [전시] B2B OBS 구축 .btn-edm
                display:none;
            }
        }
        .nav-wrap {
            padding-left: 0;
            display:flex;
            overflow: hidden;
            font-size: 0;
            white-space: nowrap;
        }
        .btn-nav-arrow {
            display: none;
            position: absolute;
            top:67px;
            z-index: 5;
            width:49px;
            height:32px;
            &:after {
                position: absolute;
                top: 4px;
                width:24px;
                height:24px;
                border-radius: 16px;
                background: url('/lg5-common/images/icons/btn-arr-32x32.svg') no-repeat 0 0;
                background-size:100% 100%;
                border:1px solid #ddd;
                content: '';
            }
            &.prev {
                left:10px;
                background-image:linear-gradient(to right, rgba(255, 255, 255, 0.94) 60%, rgba(255, 255, 255, 0.20) 90%);
                &:after {
                    left:0;
                    transform: rotateY(180deg);
                }
            }
            &.next {
                right:352px;
                background-image:linear-gradient(to left, rgba(255, 255, 255, 0.94) 60%, rgba(255, 255, 255, 0.20) 90%);
                &:after {
                    right:0;
                }
            }
            &:disabled, &.disabled, &[aria-disabled=true] {
                display: none;
            }
        }
    
        .utility-top {
            right: 51px;
        }
        .mo-nav-inner{
            display:none;
        }
        .pc-nav-inner{
            width:calc(100% - 316px);
            overflow-x:hidden;
            .nav{
                display:flex !important;
                flex-wrap: nowrap;
                > li {
                    display: inline-block;
                    padding-left:32px;
                    vertical-align: top;
                    /* BTOCSITE-1937 스프레드 메뉴 수정 */
                    &:first-child {
                        padding-left:0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                    &.icon-update {
                        .nav-item {
                            &:before {
                                position: absolute;
                                top: 5px;
                                right: -5px;
                                width: 4px;
                                height: 4px;
                                border-radius: 2px;
                                background-color: #da0f47;
                                content: '';
                            }
                        }
                    }
                    &.event-link {
                        display: none;
                    }
                }
                .nav-category {
                    &.active {
                        margin-left: 8px;
                        padding: 0 16px;
                        border-radius: 18px;
                        background-color: $color-bg;
                    }
                    &:hover,
                    &:focus,
                    &.active {
                        .nav-item {
                            &:after {
                                right: 21px;
                            }
                        }
                    }
                }
                .nav-item{
                    position: relative;
                    display: block;
                    padding:0 0 13px;
                    font-weight: 500;
                    font-size: $font-medium;
                    line-height:24px;
                    vertical-align: top;
                    &:hover,
                    &:focus,
                    &.active {
                        font-weight:500;
                        &:after {
                            content:"";
                            position: absolute;
                            bottom:0;
                            left:0;
                            width:100%;
                            height:4px;
                            background: #000;
                        }
                        .icon-nav {
                            transform: rotate(135deg);
                        }
                    }
        
                    &.active {
                        display: inline-block;
                        & + .nav-category-container,
                        & + .nav-category-layer {
                            display: inline-block;
                        }
                    }
                    &.nav-link{
                        padding-right: 18px;
                        background: url("/lg5-common/images/EDM/edm_header/btn-outlink-16.svg") no-repeat right top 1px;
                    }
                }
                .nav-category-layer{
                    .nav-category-inner{
                        display:flex;
                        position: absolute;
                        top:107px;
                        left: 0;
                        right: 0;
                        z-index: 103;
                        border-top:1px solid #ddd;
                        background-color: #fff;
                        .inner{
                            display:flex;
                            flex:auto;
                            max-width:1736px;
                            width:100%;
                            margin:0 auto;
                            padding:0 48px;
                            @include tablet-main{
                                padding:0 20px;
                            }
                        }
                        .super-category-nav{
                            background:#f9f9f9;
                            width:242px;
                            display:flex;
                            flex-direction:column;
                            padding:33px 0 40px 0;
                            @include tablet-main{
                                padding:16px 0 20px 0;
                                width:200px;
                            }
                            height:100%;
                            .s-category{
                                >li{
                                    margin-top:16px;
                                    &:first-of-type{
                                        margin-top:0;
                                    }
                                    a{
                                        font-weight:700;
                                        font-size:16px;
                                        color:#333;
                                        line-height:23px;
                                        white-space:normal;
                                        @include tablet-main{
                                            font-size:14px;
                                        }
                                        &:hover,
                                        &.active{
                                            position:relative;
                                            color:$color-primary;
                                            &:after{
                                                content:'';
                                                position:absolute;
                                                bottom:6px;
                                                right:-20px;
                                                width:0;
                                                height:0;
                                                border-bottom:4px solid transparent;
                                                border-top:4px solid transparent;
                                                border-left:6px solid $color-primary;
                                                border-right:6px solid transparent;
                                            }
                                        }
                                    }
                                }
                            }                            
                            .s-brand{
                                margin-top:40px;
                                >li{
                                    margin-top:20px;
                                    &:first-of-type{
                                        margin-top:0;
                                    }
                                    a{
                                        font-weight:400;
                                        font-size:14px;
                                        color:#333;
                                        line-height:20px;
                                        &:hover,
                                        &:focus{
                                            text-decoration:underline;
                                        }
                                        &.active{
                                            color:$color-primary;
                                        }
                                    }
                                }
                            }
                            .super-category-list{
                                +.s-category{
                                    margin-top:30px;
                                }
                            }
                            .tit-home{
                                font-size:16px;
                                font-weight:700;
                                line-height:23px;
                            }
                            .sub-link{
                                margin-top:30px;
                                >li{
                                    margin-bottom:10px;
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                    >a{
                                        .txt{
                                            margin-right:5px;
                                            font-weight:500;
                                            font-size:14px;
                                            color:#333;
                                            line-height:20px;
                                            white-space:initial;
                                        }
                                        .badge{
                                            display:inline-block;
                                            position:relative;
                                            top:2px;
                                            max-width:100%;
                                            padding:3px 4px;
                                            border-radius:10px;
                                            overflow:hidden;
                                            >em{
                                                display:inline-block;
                                                max-width:100%;
                                                font-size:10px;
                                                line-height:1;
                                                overflow:hidden;
                                                zoom:.7;
                                            }
                                            &.new{
                                                border:1px solid #008182;
                                                >em{
                                                    color:#008182;
                                                }
                                            }
                                            &.hot{
                                                border:1px solid $color-primary;
                                                >em{
                                                    color:$color-primary;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &:before{
                                content:'';
                                position:absolute;
                                top:0;
                                right:calc(100% - 48px);
                                bottom:0;
                                width:100%;
                                background:#f9f9f9;
                                @include tablet-main{
                                    right:calc(100% - 20px);
                                }
                            }
                        }
                        .nav-category-wrap{
                            flex:auto;
                            width:calc(100% - 242px);
                            @include tablet-main{
                                width:calc(100% - 190px);
                            }
                            .category-box{
                                position: relative;
                                display:flex;
                                height:100%;
                                .column{
                                    flex:1;  
                                    min-height:1px;
                                    padding:33px 30px 40px;
                                    border-left:1px solid #eee;
                                    @include tablet-main{
                                        padding:16px 20px;
                                        max-width:none;
                                    }
                                    &:first-of-type{
                                        border-left:1px solid transparent;
                                    }
                                    &.empty{
                                        padding:0;
                                        border-left:1px solid transparent;
                                        +.category-etc{
                                            border-left:1px solid transparent;
                                        }
                                    }
                                }
                            }
                            &.super-category-content{
                                .category-box{
                                    .column{
                                        max-width:272px;
                                        @include tablet-main{
                                            max-width:none;
                                        }
                                        &.category-etc{
                                            padding:33px 0 40px 30px;
                                            max-width:307px;
                                            min-width:307px;
                                            @include tablet-main{
                                                display:none;
                                            }
                                        }
                                    }
                                }
                            }
                            &:not(.super-category-content){
                                .category-box{
                                    .column{
                                        max-width:363px;
                                        @include tablet-main{
                                            max-width:none;
                                        }
                                        &.category-etc{
                                            padding:33px 0 40px 26px;
                                            max-width:303px;
                                            min-width:303px;
                                            @include tablet-main{
                                                display:none;
                                            }
                                        }
                                    }
                                }
                                .category{
                                    >li{
                                        margin-bottom:40px;
                                        @include tablet-main{
                                            margin-bottom:32px;
                                            &:last-of-type{
                                                margin-bottom:0;
                                            }
                                        }
                                        .category-item{
                                            font-size:16px;
                                            font-weight:500;
                                            color:#000;
                                            line-height:23px;
                                            &:hover,
                                            &:focus,
                                            &.active{
                                                color:$color-primary;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .utility-top {
            float:right;
            display:flex;
            padding:4px 0;
            >div{
                position:relative;
                margin-left:24px;
                padding-left:24px;
                &:before {
                    position:absolute;
                    top:50%;
                    left:0;
                    width: 1px;
                    height: 11px;
                    background-color: #ddd;
                    transform:translateY(-50%);
                    content: '';
                }
                // &:first-of-type{
                //     margin-left:0;
                //     padding-left:0;
                //     &::before{
                //         content:none;
                //     }
                // }
            }
            // 회사소개 탑메뉴
            .about-company{
                position: relative;
                > a {
                    font-size: 14px;
                    line-height: 20px;
                }
                // 회사소개 탑메뉴 추가 START
                .about-company-layer {
                    display: none;
                    position: absolute;
                    top: 20px;
                    left: -6px;
                    max-width: 125px;
                    padding-top: 12px;
                    z-index: 104;
                    > ul {
                        padding: 4px 0;
                        border-radius: 4px;
                        background-color: #fff;
                        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
                        li {
                            > a {
                                display: block;
                                padding: 8px 24px;
                                font-size: 14px;
                                line-height:24px;
                                text-align: center;
                                &:hover {
                                    background-color:#f4f4f4
                                }
                            }
                            > a.out_link {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding-right: 20px;
                                i.go_out {
                                    display: block;
                                    width: 16px;
                                    height: 16px;
                                    margin-left: 4px;
                                    background: url(/lg5-common/images/icons/btn-outlink-16.svg) no-repeat 50%;
                                    background-size: 14px 14px
                                }
                            }
                        }
                    }
                }
                // 회사소개 탑메뉴 end
            }
            // Out Link
            .nav-outer-link {
                margin-right: 0;
                // padding-left: 0;
                font-size: 14px;
                line-height: 20px;
                top: auto;
                right: auto;
                &::before {
                    // display: none;
                    margin-left: 0;
                }
                .business{
                    position: relative;
                    padding-right: 18px;
                    font-size:14px;
                    line-height:20px;
                    background: url('/lg5-common/images/header/btn-outlink-16.svg') no-repeat right 2px;
                }
            }
            .shortcut{
                display:none;
            }
        }
        .utility{
            // position:absolute;
            // top:48px;
            // right:0;
            float: right;
            padding-right: 0;
            .dropdown-wrap {
                float:left;
                .dropdowns {
                    display:inline-block;
                    position:relative;
                    margin-right:32px;
                    vertical-align:top;
                    &.open{
                        .slt-btn{
                            border-radius:6px 6px 0 0;
                        }
                    }
                    .slt-btn {
                        position:relative;
                        display:flex;
                        align-items:center;
                        padding:0 42px 0 12px;
                        width:156px;
                        height:30px;
                        background:#f6f6f6;
                        border-radius:6px;
                        &:before{
                            content:'';
                            position:absolute;
                            top:50%;
                            right:28px;
                            width:1px;
                            height:15px;
                            background:#ddd;
                            transform:translateY(-50%);

                        }
                        &:after{
                            content:'';
                            position:absolute;
                            top:9px;
                            right:8px;
                            width:12px;
                            height:12px;
                            background:url("/lg5-common/images/header/btn-down-12-black.svg") center no-repeat;
                            background-size:100% 100%;
                            transition: all .3s;
                        }
                    }
                    .lists {
                        display:none;
                        position:absolute;
                        top:30px;
                        left:0;
                        width:100%;
                        z-index:104;
                        border-radius:0 0 8px 8px;
                        background:#fff;
                        overflow:hidden;
                        li {
                            a {
                                display:block;
                                padding:0 12px;
                                line-height:30px;
                                background:#f6f6f6;
                            }
                        }
                    }
                    &.open {
                        .slt-btn {
                            &::after {
                                transform: rotate(180deg);
                            }
                        }
                        .lists {
                            display:block;
                        }
                    }
                }
            }
            .shortcut{
                display:flex;
                padding:3px 0;
                > li {
                    position: relative;
                    width: 24px;
                    height: 24px;
                    background: no-repeat center center;
                    background-size: 24px 24px;
                    &:not(:first-child) {
                        margin-left: 16px;
                    }
                    a {
                        display: block;
                        height: 100%;
                    }
                    &.search {
                        background-image: url("/lg5-common/images/icons/icon_search_new.svg");   // BTOCSITE-85601 GNB 공통영역 아이콘 통일화  // BTOCSITE-44601 [전시] 아이콘2.0 변경 건
                    }
                    &.home {
                        display: none;
                        background-image: url("/lg5-common/images/icons/icon_home_new.svg"); // BTOCSITE-85601 GNB 공통영역 아이콘 통일화 
                    }
                    &.share {
                        display: none;
                        background-image: url("/lg5-common/images/icons/icon_share_new.svg"); // BTOCSITE-85601 GNB 공통영역 아이콘 통일화 
                    }
                    &.mypage {
                        display: none;
                        background-image: url("/lg5-common/images/icons/icon_my_new.svg");  //  BTOCSITE-85601 GNB 공통영역 아이콘 통일화  // BTOCSITE-44601 [전시] 아이콘2.0 변경 건
                        &.after-login {
                            position: relative;
                            &:after {
                                position: absolute;
                                top: 0;
                                right: 2px;
                                width: 4px;
                                height: 4px;
                                border-radius: 2px;
                                background-color: $color-primary;
                                content: '';
                            }
                            .mypage-layer {
                                .mypage-layer-inner {
                                    min-width: 157px;
                                }
                            }
                        }
                        .mypage-layer {
                            display: none;
                            position: absolute;
                            top: 24px;
                            left: 50%;
                            z-index: 104;
                            transform: translateX(-50%);
                            padding-top: 14px;
                            .mypage-layer-inner {
                                min-width: 138px;
                                padding: 4px 0;
                                border-radius: 8px;
                                background-color: #fff;
                                box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
                                text-align: center;
                                a {
                                    display: block;
                                    padding: 8px 0;
                                    @include font-small;
                                    &:hover,
                                    &:focus {
                                        border-radius: 4px;
                                        background-color: $color-bg;
                                    }
                                }
                                .mypage-shortcut {
                                    border-top: 1px solid $color-border;
                                }
                            }
                        }
                    }
                    &.cart {
                        background-image: url("/lg5-common/images/icons/icon_cart_new.svg"); // BTOCSITE-85601 GNB 공통영역 아이콘 통일화  // BTOCSITE-44601 [전시] 아이콘2.0 변경 건
                    }
                    &.notice {
                        background-image: url("/lg5-common/images/icons/icon-notice-20.svg");
                    }
                    .count {
                        position: absolute;
                        top: -5px;
                        left: 10px;
                        display: block;
                        min-width: 14px;
                        height: 14px;
                        padding: 2px 4px;
                        border-radius: 8px;
                        background-color: $color-primary;
                        font-weight: 700;
                        font-size: 10px;
                        line-height: 10px;
                        color: $color-white;
                        text-align: center;
                    }
                }
            }
        }
    }
    
    /* 모바일 햄버거 메뉴 */
    @include mobile {
        max-width: 100%;
        padding: 0 16px;
        .header-top {
            padding: 10px 0 11px;
            .logo {            
                float: none;
                padding-top: 0;
                padding-left: 0;
                .lg-logo{
                    height: 24px;
                }
                a:not(:first-child) {
                    margin-left: 8px;
                    padding-left: 9px;
                    background: none;
                    &::before {
                        height: 14px;
                    }
                }
                .bi-logo2 {
                    height: 16px;
                    &.symbol {
                        height: 20px;
                    }
                }               
            }            
            .utility{
                float: none;
                padding-right: 0;
                position: absolute;
                top: 6px;
                right: 11px;
                z-index: 1;
                @media screen and (min-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
                    right:51px;
                }
                .shortcut {
                    font-size: 0;
                    > li {
                        position: relative;
                        display: inline-block;
                        width: 34px;
                        height: 34px;
                        background: no-repeat center center;
                        background-size: 24px 24px;
                        &:not(:first-child) {
                            margin-left: 6px;
                        }
                        a {
                            display: block;
                            height: 100%;
                            padding: 5px;
                        }
                        &.search {
                            background-image: url("/lg5-common/images/icons/icon_search_new.svg");   //  BTOCSITE-85601 GNB 공통영역 아이콘 통일화  // BTOCSITE-44601 [전시] 아이콘2.0 변경 건
                        }
                        &.home {
                            display: none;
                            background-image: url("/lg5-common/images/icons/icon_home_new.svg"); //  BTOCSITE-85601 GNB 공통영역 아이콘 통일화  
                        }
                        &.share {
                            display: none;
                            background-image: url("/lg5-common/images/icons/icon_share_new.svg"); // BTOCSITE-85601 GNB 공통영역 아이콘 통일화 
                        }
                        &.mypage {
                            display: none;
                            background-image: url("/lg5-common/images/icons/icon_my_new.svg");  //  BTOCSITE-85601 GNB 공통영역 아이콘 통일화  // BTOCSITE-44601 [전시] 아이콘2.0 변경 건
                            &.after-login {
                                position: relative;
                                &:after {
                                    position: absolute;
                                    top: 0;
                                    right: 2px;
                                    width: 4px;
                                    height: 4px;
                                    border-radius: 2px;
                                    background-color: $color-primary;
                                    content: '';
                                }
                                .mypage-layer {
                                    .mypage-layer-inner {
                                        min-width: 157px;
                                    }
                                }
                            }
                            .mypage-layer {
                                display: none;
                                position: absolute;
                                top: 24px;
                                left: 50%;
                                z-index: 104;
                                transform: translateX(-50%);
                                padding-top: 14px;
                                .mypage-layer-inner {
                                    min-width: 138px;
                                    padding: 4px 0;
                                    border-radius: 8px;
                                    background-color: #fff;
                                    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
                                    text-align: center;
                                    a {
                                        display: block;
                                        padding: 8px 0;
                                        @include font-small;
                                        &:hover,
                                        &:focus {
                                            border-radius: 4px;
                                            background-color: $color-bg;
                                        }
                                    }
                                    .mypage-shortcut {
                                        border-top: 1px solid $color-border;
                                    }
                                }
                            }
                        }
                        &.cart {
                            background-image: url("/lg5-common/images/icons/icon_cart_new.svg"); // BTOCSITE-85601 GNB 공통영역 아이콘 통일화  // BTOCSITE-44601 [전시] 아이콘2.0 변경 건
                        }
                        &.notice {
                            background-image: url("/lg5-common/images/icons/icon-notice-20.svg");
                        }
                        .count {
                            position: absolute;
                            top: 1px;
                            left: 15px;
                            display: block;
                            min-width: 14px;
                            height: 14px;
                            padding: 2px 4px;
                            border-radius: 8px;
                            background-color: $color-primary;
                            font-weight: 700;
                            font-size: 10px;
                            line-height: 10px;
                            color: $color-white;
                            text-align: center;
                        }
                    }
                }
                .about-company, .nav-outer-link{
                    display:none;
                }
            }
        }
        .nav-wrap{
            margin-top: 96px;
            padding-left: 0;
            isolation: isolate;
        }        
        .mo-nav-inner{
            .emp-link{
                display:none;
                padding:0 16px 23px;
                .txt{
                    position:relative;
                    display:flex;
                    align-items:center;
                    padding:0 55px 0 20px;
                    height:35px;
                    background:rgba(255, 255, 255, .1);
                    border-radius:8px;
                    font-size:14px;
                    color:#fff;
                    &:after{
                        content:'';
                        position:absolute;
                        top:50%;
                        right:14px;
                        width:20px;
                        height:16px;
                        background:url('/lg5-common/images/header/btn-arrow-right-emp-m.svg');
                        background-size:100% 100%;
                        transform:translateY(-50%);
                    }
                }
            }
            .main-menu{
                padding:5px 16px 12px;
                overflow-x:auto;
                .menu-list{
                    display:flex;
                    >li{
                        >a{
                            display:flex;
                            flex-direction:column;
                            align-items:center;
                            font-size:11px;
                            line-height:18px;
                            text-align:center;
                            color:#fff;
                            .thumb{
                                margin-bottom:8px;
                                width:58px;
                                height:58px;
                                border-radius:100%;
                                img{
                                    max-width:100%;
                                }
                            }
                        }
                    }
                    &:not(.spacebetween){
                        >li{
                            padding-right:31px;
                            &:last-of-type{
                                padding-right:16px;
                            }
                        }
                    }
                    &.spacebetween{
                        justify-content:space-between;
                    }
                }
            }
            .mo-nav-menu{
                position:relative;
                .mo-nav-menu-inner{
                    display:flex;
                    padding:0 16px;
                    border-top:1px solid #eee;
                    border-bottom:1px solid #eee;
                    background:#fff;
                    justify-content:space-between;
                    overflow-x:auto;
                    >li{
                        margin-left:6px;
                        &:first-of-type{
                            margin-left:0;
                        }
                        .nav-item{
                            position:relative;
                            display:block;
                            padding:20px 0 13px;
                            font-size:15px;
                            white-space:nowrap;
                            &.active{
                                color:$color-primary;
                                font-weight:700;
                                &:after{
                                    content:'';
                                    position:absolute;
                                    left:0;
                                    bottom:0;
                                    width:100%;
                                    height:2px;
                                    background:$color-primary;
                                }
                            }
                        }
                    }
                }
            }
            .mo-nav-category-layer{
                .nav-category-inner{
                    .inner{
                        display:flex;
                        background:#fff;
                        .super-category-nav{
                            position:relative;
                            // padding-top: 8px;
                            width:136px;
                            min-height: 480px;                     
                            // border-right:1px solid #d9d9d9;
                            background:#f6f6f6;
                            &:after{
                                content:'';
                                position:absolute;
                                bottom:0;
                                left:0;
                                width:89px;
                                border-bottom:1px solid #f6f6f6;
                            }
                            .tit-home{
                                display:flex;
                                align-items:center;
                                height: 48px;
                                padding:0 16px;
                                font-size:14px;
                                line-height:20px;
                                font-weight: 700;
                                // border-bottom:1px solid #e5e5e5;
                                background:#f5f5f5;
                                color:#333;
                            }
                            .s-category, .s-brand, .tit-home{
                                >li{
                                    a{
                                        position:relative;
                                        display:flex;
                                        align-items:center;
                                        margin-top:-1px;
                                        height:48px;
                                        padding:0 16px;
                                        font-size:14px;
                                        line-height:20px;
                                        font-weight: 700;
                                        // border-top:1px solid #e5e5e5;
                                        // border-bottom:1px solid #e5e5e5;
                                        color:#333;
                                        &.active{
                                            // border-top:1px solid #d9d9d9;
                                            // border-bottom:1px solid #d9d9d9;
                                            background:#fff;
                                            color:$color-primary;
                                            font-weight:700;
                                            &:after{
                                                content:'';
                                                position:absolute;
                                                top:0;
                                                bottom:0;
                                                right:-1px;
                                                border-right:1px solid #fff;
                                            }
                                        }
                                    }
                                }
                            }
                            .s-category{
                                >li{
                                    &:first-of-type{
                                        a{
                                            margin-top:0;
                                        }
                                    }
                                    a{
                                        background:#f6f6f6;
                                    }
                                    &:first-of-type{
                                        a{
                                            border-top:1px solid transparent;
                                        }
                                    }
                                }
                            }
                            .s-brand{
                                >li{
                                    a{
                                        background:#fdfdfd;
                                    }
                                }
                            }
                        }
                        .nav-category-wrap{
                            padding:0 16px 40px;
                            width:calc(100% - 136px);
                            .category-banner{
                                position:relative;
                                .banner-box{
                                    position:relative;
                                    display:block;
                                    padding:20px 80px 20px 14px;
                                    width:100%;
                                    height:90px;
                                    border-radius:4px;
                                    .thumb{
                                        position:absolute;
                                        top:0;
                                        right:0;
                                        width:78px;
                                        height:90px;
                                        >img{
                                            max-height:100%;
                                        }
                                    }
                                    .banner-text{
                                        .text{
                                            font-size:13px;
                                            font-weight:500;
                                            line-height:18px;
                                            letter-spacing:-0.26px;
                                        }
                                    }
                                }
                                .custom-indi-wrap {
                                    //display:none;
                                    position: absolute;
                                    bottom:0;
                                    left:0;
                                    .slide-controls {
                                        position:relative;
                                        max-width:none;
                                        margin:0 auto;
                                        .controls{
                                            position:absolute;
                                            bottom:11px;
                                            left:14px;
                                            padding:0 5px;
                                            min-width:38px;
                                            height:16px;
                                            border-radius:8px;
                                            background:rgba(0, 0, 0, 0.3);
                                            text-align: center;
                                            line-height:10px;
                                        }
                                        .slide-page{
                                            padding:0 4px;
                                            color:#fff;
                                            font-size:10px;
                                            line-height:14px;
                                            font-weight: normal;
                                            .count{
                                                color:#cbcbcb;
                                                &:before{
                                                    content:'/';
                                                    position:relative;
                                                    top:-1px;
                                                    margin:0 1px;
                                                    color:#fff;
                                                    font-size:9px;
                                                }
                                            }
                                        }
                                    }
                                }
                                +.category-box{
                                    margin-top:28px;
                                }
                            }
                        }
                        .category-box{
                            .category{
                                >li{
                                    border-bottom: 1px solid #EBEBEB;
                                    &:last-of-type{
                                        margin-bottom:0;
                                        border-bottom: none;
                                    }
                                    .category-item{
                                        position:relative;
                                        padding-left: 8px;
                                        display:flex;
                                        align-items:center;
                                        height:48px;
                                        font-size:15px;
                                        font-weight:400;
                                        &:after{
                                            content:'';
                                            position:absolute;
                                            top:50%;
                                            right:8px;
                                            width:16px;
                                            height:16px;
                                            background:url('/lg5-common/images/icons/icon-arr-40-bk.svg') no-repeat;
                                            background-size:100% 100%;
                                            transform:translateY(-50%);
                                            transition: transform .3s;
                                        }
                                        &.none-toggle{
                                            &:after{
                                                content:none;
                                            }
                                            &[target="_blank"]{
                                                &:after{
                                                    content:'';
                                                    width:10px;
                                                    height:10px;
                                                    background:url('/lg5-common/images/header/btn-outlink-10.svg') no-repeat right center;
                                                    background-size:100% 100%;
                                                }
                                            }
                                        }
                                    }
                                    &.on{
                                        .category-item{
                                            font-weight:700;
                                            border-bottom: 1px solid #EBEBEB;
                                        }
                                        .category-item:not(.none-toggle){
                                            &:after{
                                                background:url("/lg5-common/images/icons/icon-arr-40-bk.svg") no-repeat center/100%;
                                                transform: translateY(-50%) rotate(180deg);
                                            }
                                        }
                                    } 
                                    .sub-link{
                                        >li{
                                            display:table;
                                            >a{
                                                padding-left: 24px;
                                                display:table-cell;
                                                vertical-align:middle;
                                                height:36px;
                                                align-items:center;
                                                .tit-home{
                                                    font-size:13px;
                                                    color:#333;
                                                    line-height:20px;
                                                }
                                                .txt{
                                                    margin-right:5px;
                                                    font-size:13px;
                                                    color:#333;
                                                    line-height:20px;
                                                    white-space:initial;
                                                }
                                                .badge{
                                                    display:inline-block;
                                                    position:relative;
                                                    top:2px;
                                                    max-width:100%;
                                                    height:15px;
                                                    padding:0 5px;
                                                    border-radius:10px;
                                                    overflow:hidden;
                                                    line-height:7px;
                                                    >em{
                                                        display:inline-block;
                                                        max-width:100%;
                                                        font-size:6.5px;
                                                        line-height: 13px;
                                                        overflow:hidden;
                                                    }
                                                    &.new{
                                                        border:1px solid #008182;
                                                        >em{
                                                            color:#008182;
                                                        }
                                                    }
                                                    &.hot{
                                                        border:1px solid $color-primary;
                                                        >em{
                                                            color:$color-primary;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .category-list{
                                        display:none;
                                        padding:8px 0 8px 8px;
                                        .sub-category{
                                            >li{
                                                >a{
                                                    padding-left: 24px;
                                                    display:flex;
                                                    height:36px;
                                                    align-items:center;
                                                    font-size:13px;
                                                }
                                            }
                                        }
                                    }
                                    &.on{
                                        .category-item{
                                            font-weight:700;
                                        }
                                    }
                                }
                            }
                            .category-hashtag{
                                margin-top:42px;
                                li{
                                    margin-bottom:7px;
                                    &:last-of-type{
                                        margin-bottom:0;
                                    }
                                    .tag-item {
                                        display: inline-block;
                                        padding:5px 14px;
                                        border:1px solid #ddd;
                                        border-radius:17px;
                                        font-weight:400;
                                        font-size:13px;
                                        color:#666;
                                        line-height:18px;
                                    }
                                }
                            }
                        }
                        >.category-box{
                            margin-top:0;
                            padding:26px 26px 38px;
                            width:100%;
                        }
                    }
                }
            }
            .mo-link-menu{
                padding:16px 16px 64px;
                border-top:1px solid #EBE6E6;
                background:#fff;
                .link-menu-inner{
                    margin-top:40px;
                    &:first-of-type{
                        margin-top:0;
                    }
                    .nav-item{
                        font-size:14px;
                        height: 40px;
                        line-height: 40px;
                        color: #474747;
                    }
                    .link-menu{
                        &__brand{
                            display:flex;
                            margin:14px -16px 0;
                            overflow-x:auto;
                            >li{
                                &:first-of-type{
                                    >a{
                                        margin-left:16px;
                                    }
                                }
                                &:last-of-type{
                                    >a{
                                        margin-right:16px;
                                    }
                                }
                                >a{
                                    display:flex;
                                    flex-direction:column;
                                    align-items:center;
                                    margin-right:4px;
                                    padding:0 15px;
                                    border-radius:19px;
                                    border:1px solid #000;
                                    .thumb{
                                        height:36px;
                                        img{
                                            max-height:100%;
                                        }
                                    }
                                }
                            }
                        }
                        &__txt{
                            position:relative;
                            display:flex;
                            flex-wrap:wrap;
                            margin:4px 0 0;
                            // &:before{
                            //     content:'';
                            //     position:absolute; 
                            //     top:0;
                            //     left:16px;
                            //     width:calc(100% - 32px);
                            //     border-top:1px solid #ddd;
                            // }
                            &:first-letter{
                                color:red;
                            }
                            >li{
                                margin-top:6px;
                                padding:0 16px;
                                width:50%;
                                &:nth-of-type(odd){
                                    padding:0 32px 0 0;
                                }
                                &:nth-of-type(even){
                                    padding:0 16px 0 32px;
                                }
                                // &:nth-of-type(1), &:nth-of-type(2){
                                //     margin-top:12px;
                                // }
                                &.btm-before-login:not([style*="display:none"]):not([style*="display: none"]){
                                    ~li:nth-of-type(odd){
                                        padding:0 32px 0 16px;
                                    }
                                    ~li:nth-of-type(even){
                                        padding:0 16px 0 32px;
                                    }
                                }
                                &.btm-after-login:not([style*="display:none"]):not([style*="display: none"]){
                                    ~li:nth-of-type(odd){
                                        padding:0 32px 0 16px;
                                    }
                                    ~li:nth-of-type(even){
                                        padding:0 32px 0 16px;
                                    }
                                }
                                >a{
                                    font-size:13px;
                                    line-height:24px;
                                    font-weight: 700;
                                    color:#333;
                                    &[target="_blank"] {
                                        padding-right:12px;
                                        background-image: url("/lg5-common/images/header/btn-outlink-10.svg");
                                        background-repeat:no-repeat;
                                        background-position:right 6px;
                                        background-size:7px 7px;
                                    }
                                    &.toggle{
                                        position:relative;
                                        display:block;
                                        width:100%;
                                        &:after{
                                            content:'';
                                            position:absolute;
                                            top:50%;
                                            right:0;
                                            width:12px;
                                            height:12px;
                                            background:url('/lg5-common/images/header/btn-plus-12.svg');
                                            background-size:100% 100%;
                                            transform:translateY(-50%);
                                        }
                                        &+.sub-category{
                                            display:none;
                                            position:relative;
                                            margin:7px 0 0 calc(-100% - 64px);
                                            width:calc(200% + 64px);
                                            background:#f6f6f6;
                                            &:before{
                                                content:'';
                                                position:absolute;
                                                top:0;
                                                right:0;
                                                width:calc(50% - 16px);
                                                border-top:1px solid #333;
        
                                            }
                                            >li{
                                                display:inline-block;
                                                padding:20px 0 20px 20px;
                                                width:calc(50% - 24px);
                                                &:nth-of-type(odd){
                                                    margin-right:22px;
                                                }
                                                &:nth-of-type(even){
                                                    margin-left:22px;
                                                }
                                                >a{
                                                    color:#666;
                                                    font-size:14px;
                                                    line-height:19px;
                                                }
                                            }
                                        }
                                        &.on{
                                            &:after{
                                                background:url('/lg5-common/images/header/btn-minus-12.svg');
                                            }
                                            &+.sub-category{
                                                display:block;
                                            }
                                        } 
                                    }
                                }
                            }
                        }
                    }
                }
                .nav-logout{
                    display:none;
                    margin-top:32px;
                    &.btm-after-login{
                        display:block;
                    }
                    .btn-logout{
                        display:flex;
                        // justify-content:center;
                        align-items:center;
                        font-size:14px;
                        line-height:20px;
                        color:#333;
                        text-decoration:underline;
                        text-decoration-color:#ddd;
                        text-underline-offset:4px;
                    }
                }
            } 
        }
        .sticky_shadow {
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14%);
        }    
        .mobile-nav-close {
            display:none;
            width:32px;
            height:32px;
            padding:4px;
            background: url('/lg5-common/images/header/btn-close-48-white.svg') no-repeat center center;
            background-size:24px 24px;
        }
        .mobile-nav-button.active {
            @include mobile{
                display:block;
            }
            top:12px;
            right:16px;
            .mobile-nav-close {
                display:block;
                width:24px;
                height:24px;
                background: url('/lg5-common/images/EDM/edm_header/icon_Close_48.svg') no-repeat center center;
                background-size:24px 24px;
            }
        }    
        .mobile-nav-button.done {
            .mobile-nav-close {
                display: block;
            }
        }    
        .mobile-nav-button.active + .header-bottom {
            left: 0 !important;
            right: auto  !important;
        }
        .mobile-nav-button + .header-bottom.header-new {
            width: 100%;
            padding-top: 0;
            left: -100%;
            right: auto;
            transition: left .5s;
            &.no-transition{
                transition: none;
            }
            .login-mobile {
                z-index: 1;
                position: fixed;
                width: 100%;
                height: 96px;
                background: #fff;
                border-bottom: 1px solid #e6e6e6;
                &:before {
                    display: none;
                }
                
                .login-info-wrap {
                    padding:11px 36px 11px 0;
                }
                .emd-link-wrap {
                    width: 100%;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0;
                    font-size: 14px;
                    line-height: 20px;
                    .indi-link {
                        padding-right: 18px;
                        font-size: 13px;
                        line-height: 19px;
                        font-weight: 700;
                        background: url("/lg5-common/images/EDM/edm_header/icon_arrow_right_16.svg") no-repeat right center / 16px auto;
                    }
                }
            }
            .super-category .nav-category-layer .category {
                display: none;
            }
            .nav-category-layer .category  li .sub-category {
                display: none;
            }
    
            .super-category .category-item {
                padding: 12px !important;
                color: #000;
                line-height: 19px;
            }
            .nav-category-container {
                display: block;
    
                li.on .super-category-item {
                    position: relative;
                    font-weight: 700;
    
                    &:before {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        display: block;
                        content: "";
                        width: calc(100% - 4px);
                        height: 1px;
                        background: #000;
                    }
    
                    &:after {
                        background-image: url("/lg5-common/images/icons/btn-minus-21.svg");
                    }
                }
            }
            .nav-category-layer {
                display: block;
                width: 100%;
                .nav-category-wrap {
                    padding-bottom: 0;
                }
            }
    
            .super-category-list .nav-category-layer .category {
                background: #f6f6f6;
                &:after {
                    display: block;
                    content: "";
                    clear: both;
                }
                li {
                    float: left;
                    width: 50%;
                }
            }
            .nav-category-layer .category > li .sub-category {
                display: none;
                width: 200%;
                padding: 0;
    
                &:after {
                    content: "";
                    display: block;
                    clear: both;
                }
    
                li {
                    float: left;
                    width: 50%;
                    a{
    
                        padding: 12px;
                        color: #000;
                        line-height: 19px;
    
                        &[target="_blank"] {
                            background: none;
    
                            &:after {
                                content: '';
                                display: inline-block;
                                width: 16px;
                                height: 16px;
                                margin-left: 4px;
                                background: url("/lg5-common/images/icons/btn-outlink-16.svg") center no-repeat;
                                background-size: 100%;
                                vertical-align: -3px;
                            }
                        }
                    }
                }
            }
        }
        .nav-arrow-wrap{
            display:none !important;
        }
    }    
}

@include mobile {
    .header-wrap:not(.type-new) { // b2c에서 사업자몰 피씨 온라인 링크 구분 
        .nav-outer-link .links li {
            &.pc-only {
                display: none;
            }
            &.mo-only {
                display: inline-block;
            }
        }
    }
    // S : BTOCSITE-48265 [전시] B2B OBS 구축
    .subRenewWrap {
        &--payment    {
            .header-wrap.type-new{
                .header-top {
                    .utility {
                        //display: none;
                        .shortcut {
                            > li {
                                &.search {
                                    display: none;
                                }
                                &.cart {
                                    display: none;
                                }
                                &.home {
                                    display: none;
                                }
                            }
                        }
                    }                    
                }
            }
        }
        &--cartOrder {
            .header-wrap.type-new{
                .header-top {
                    .utility {
                        //display: none;
                        .shortcut {
                            > li {
                                &.search {
                                    display: none;
                                }
                                &.cart {
                                    display: none;
                                }
                                &.home {
                                    display: inline-block;
                                }
                            }
                        }
                    }                    
                }
            }
        }
        &.is-search {
            .header-wrap.type-new {
                .header-top,
                .mobile-nav-wrap  { display: none; }
            }
        }
    }
    .subRenewPdpWrap {
        .is-pdp {
            .mobile-nav-button {
                display: block;
                position: absolute;
                top: 6px;
                left: 56px;
                width: 34px;
                height: 34px;
                padding: 5px;
                background: url('/lg5-common/images/EDM/ic_toolbar_b2b_menu_off_24.svg') no-repeat center center;
                background-size: 24px;
            
                span[class^=line-],
                .mobile-nav-close {
                    display: none;
                }
                @media screen and (min-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
                    @include mobile{
                        display:block;
                    }
                }
                @include mobile {
                    //210701 앱 알람아이콘 N 뱃지 추가
                    .count{
                        position: absolute;
                        top: 2px;
                        left: 18px;
                        display: block;
                        min-width: 14px;
                        height: 14px;
                        padding: 2px 2px;
                        border-radius: 8px;
                        background-color: $color-primary;
                        font-weight: 700;
                        font-size: 10px;
                        line-height: 10px;
                        color: $color-white;
                        text-align: center;
                    }
                    //210701 앱 알람아이콘 N 뱃지 추가
                }
            
                &.active {
                    position: fixed;
                    top:14px;
                    left: 56px;
                    z-index: 112;
                    width: auto;
                    height: auto;
                    padding: 0;
                    background: none;
                    .mobile-nav-close {
                        display: block;
                        width: 34px;
                        height: 34px;
                        padding: 5px;
                        background: url('/lg5-common/images/icons/btn-close-24-black.svg') no-repeat center center;
                    }
            
                    @include mobile {
                        //210701 앱 알람아이콘 N 뱃지 추가
                        .count {
                            display: none;
                        }
                        //210701 앱 알람아이콘 N 뱃지 추가
            
                    }
                }
            }
        }
        .header-wrap.type-new {
            .mobile-nav-button {
                display: none;
                &.active {
                    display: block;
                }
            }
            .header-top {
                .utility {
                    right: 16px;
                    .shortcut {
                        > li {
                            &.home {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
    .subRenewMainWrap,
	.subRenewPdpWrap,
	.subRenewWrap {
        .sub-renew-nav-wrap.is-depth.isDepth-new {
            .nav-item:after {
                top: 1px;
                width: 24px;
                height: 24px;
                background-size: 21px auto;
            }
        }
	}
    // E : BTOCSITE-48265 [전시] B2B OBS 구축
}

 // BTOCSITE-82293 [전시] 사업자몰(B2B) 구축 도입 신규 추가 
 @include mobile {
    .mo-nav-Tabs{
        padding:0 16px;
        border-bottom:1px solid #EBE6E6;
    }
    .Tab-list{
        display: flex;
        padding: 0;
        list-style-type: none;
        font-size: 0;
        li{
            flex: 1;
            display: inline-block;
           
            text-align: center;
            &[aria-selected="true"] {
              background-color: white;
              border-bottom: 1px solid white;
            }
            a{
                display: block;
                padding: 16px 0;
                background-color: transparent;
                font-size: 15px;
                line-height: 18px;
                font-weight: 400; 
                color:#666;
            }
            &.active a{
                color:#000;
                font-weight:700;
                border-bottom: 2px solid #222;
            }
        }
    }
   
 }